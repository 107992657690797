import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface Props {
  title: string
  disabled?: boolean
  type?: 'button' | 'submit'
  onClick?: () => void
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: 16,

    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

/**
 * Bouton de soumission de formulaire
 */
export const SubmitButton: React.FC<Props> = ({
  title,
  disabled,
  type = 'submit',
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Button
      className={classes.root}
      disabled={disabled}
      size="large"
      type={type}
      onClick={onClick}
      variant="contained"
      color="secondary"
    >
      {title}
    </Button>
  )
}
