import { Avatar, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { useAuth } from '../../../context/auth-context'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  lastConnectionContainer: {
    marginTop: theme.spacing(1),
  },
  lastConnection: {
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 1.25,
    color: theme.palette.info.dark,
  },
}))

enum RoleNames {
  patient = 'Patient',
  doctor = 'Médecin',
  admin = 'Administateur',
  hospitalAdmin = "Administrateur d'hôpital",
  hospitalDoctor = "Médecin d'hôpital",
  cityDoctor = 'Médecin de ville',
  inspector = 'Enquêteur',
}

/**
 * Appelé dans la Sidebar
 * Affiche l'avatar (initiales), le nom, et le rôle de l'utilisateur courant
 */
export const Profile = () => {
  const classes = useStyles()
  const { user } = useAuth()

  const lastConnection = user.data?.lastConnections
    ? user.data.lastConnections[user.data.lastConnections.length - 2] // On ne veut pas la date de la connexion actuelle (dernière), mais de la connexion précédente (avant-dernière)
    : undefined
  const lastConnectionString = lastConnection
    ? format(lastConnection, i18n.sidebar.lastConnection.dateFormat, {
        locale: fr,
      })
    : undefined

  return (
    <Box className={classes.root}>
      <Avatar alt="Person" className={classes.avatar}>
        {user.data?.initials}
      </Avatar>
      <Typography className={classes.name} variant="h4">
        {user.data?.fullName}
      </Typography>
      <Typography variant="body2">
        {user.data?.role && RoleNames[user.data.role]}
      </Typography>
      {lastConnectionString && (
        <Box className={classes.lastConnectionContainer}>
          <Typography className={classes.lastConnection}>
            {i18n.sidebar.lastConnection.lastConnection}
          </Typography>
          <Typography className={classes.lastConnection}>
            {lastConnectionString}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
