import {
  Box,
  Grid,
  GridSpacing,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { Field } from 'formik'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { FormikRadioGroup, InputField } from '../../ui/Inputfield'

interface Props {
  symptoms: Array<Symptom>
  spacing: GridSpacing
  handleChange: any
  handleBlur: any
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  legend: {
    fontSize: 14,
    color: theme.palette.info.main,
    paddingRight: theme.spacing(2),
  },
}))

export const PatientSymptoms: React.FC<Props> = ({
  symptoms,
  spacing,
  handleChange,
  handleBlur,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex">
      <Grid container spacing={spacing}>
        {symptoms.map((symptom) => (
          <React.Fragment key={symptom.id}>
            {symptom.input ? (
              <Grid item xs={12}>
                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.legend}>
                      {symptom.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name={`symptoms.${symptom.id}`}
                      label={
                        symptom.label === 'MRC'
                          ? i18n.patients.create.patient.score
                          : i18n.patients.create.patient.duration
                      }
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                      style={{ marginTop: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Field
                  name={`symptoms.${symptom.id}`}
                  options={symptom.options}
                  component={FormikRadioGroup}
                  legend={symptom.label}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  )
}
