import React from 'react'

interface ImgProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

// Dashboard
export const PatientTableIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/rain_triangle.svg" alt="" {...props} />
)
export const ScoreTableIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/form_triangle.svg" alt="" {...props} />
)
export const AppointmentTableIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/clock_triangle.svg" alt="" {...props} />
)
export const LastAppointmentTableIcon: React.FC<ImgProps> = (props) => (
  <img
    src="/images/common/dashboard/images/illustration_clock.svg"
    alt=""
    {...props}
  />
)

// Indicateur consultation
export const TeleconsultationIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/teleconsultation.svg" alt="" {...props} />
)
export const ConsultationIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/consultation.svg" alt="" {...props} />
)
export const DayHospitalIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/day_hospital.svg" alt="" {...props} />
)

// Dialog images
export const DialogPatientMonitoring: React.FC<ImgProps> = (props) => (
  <img src="/images/common/images/illustration_action.svg" alt="" {...props} />
)
export const DialogDashboard: React.FC<ImgProps> = (props) => (
  <img
    src="/images/common/images/illustration_rendez-vous.svg"
    alt=""
    {...props}
  />
)
export const DialogSendPdf: React.FC<ImgProps> = (props) => (
  <img src="/images/common/images/illustration_mail.svg" alt="" {...props} />
)

// Moral
export const MoodUnknownIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/mood_unknown.svg" alt="" {...props} />
)
export const SunIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/sun.svg" alt="" {...props} />
)
export const CloudIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/cloud.svg" alt="" {...props} />
)
export const RainIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/rain.svg" alt="" {...props} />
)
export const SunWhiteIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/sun_white.svg" alt="" {...props} />
)
export const CloudWhiteIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/cloud_white.svg" alt="" {...props} />
)
export const RainWhiteIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/rain_white.svg" alt="" {...props} />
)

// Bâtiments
export const HouseBlueIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/house_blue.svg" alt="" {...props} />
)
export const HospitalCircleIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/hospital_circle.svg" alt="" {...props} />
)
export const HospitalIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/hospital.svg" alt="" {...props} />
)

// Divers
export const ArrowRightIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/arrow_right.svg" alt="" {...props} />
)
export const ArrowRightGreyIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/arrow_right_grey.svg" alt="" {...props} />
)
export const CheckIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/check.svg" alt="" {...props} />
)
export const WarningIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/warning.svg" alt="" {...props} />
)
export const CrossIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/cross.svg" alt="" {...props} />
)
export const QuestionMarkIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/question_mark.svg" alt="" {...props} />
)
export const AddPersonIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/add_person.svg" alt="" {...props} />
)
export const PencilIcon: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/pencil.svg" alt="" {...props} />
)
