import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  indications: {
    fontSize: 20,
    color: theme.palette.secondary.light,
    fontWeight: 400,
    '& span': {
      background: theme.palette.secondary.light,
      padding: 6,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.white,
    },
  },
}))

export const HeroCounterElements = ({
  startString,
  counter,
  counterDesc,
  endString,
}: {
  startString: string
  counter: number
  counterDesc: string
  endString?: string
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Typography variant="h4" className={classes.indications}>
        {startString}{' '}
        <span>
          {counter} {counterDesc.toLocaleUpperCase()}
        </span>{' '}
        {endString}
      </Typography>
    </Box>
  )
}
