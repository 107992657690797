import palette from './palette'

const images = {
  logo: '/images/Chut/png/logo_co-vie-apres.png',
  path: 'images/Chut',
}

export const ChutSettings = {
  theme: {
    palette,
  },
  images,
}
