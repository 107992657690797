import { MUIDataTableTextLabels } from 'mui-datatables'

export const DataTableTextLabels: Partial<MUIDataTableTextLabels> = {
  body: {
    noMatch: 'Aucun résultat',
    toolTip: 'Trier',
  },
  pagination: {
    next: 'Page suivante',
    previous: 'Page précédente',
    rowsPerPage: 'Lignes par page :',
    displayRows: 'sur',
  },
  toolbar: {
    search: 'Rechercher',
    downloadCsv: 'Télécharger en CSV',
    print: 'Imprimer',
    viewColumns: 'Afficher les colonnes',
    filterTable: 'Filtrer',
  },
  filter: {
    all: 'Tous',
    title: 'FILTRES',
    reset: 'RESET',
  },
  viewColumns: {
    title: 'Afficher les colonnes',
    titleAria: 'Afficher/cacher les colonnes',
  },
  selectedRows: {
    text: 'lignes(s) sélectionnées',
    delete: 'Supprimer',
    deleteAria: 'Supprimer les lignes sélectionnées',
  },
}
