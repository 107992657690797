import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import { StepIconProps } from '@material-ui/core/StepIcon'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import clsx from 'clsx'
import React, { useState } from 'react'

interface Props {
  stepPosition: any
  [icon: string]: any
}

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: '47%',
    left: 'calc(-50% + -10px)',
    right: '65%',
    flexDirection: 'row',
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: '#A1A1A1',
    borderRadius: 1,
  },
})(StepConnector)

const useColorlibStepIconStyles = makeStyles({
  roots: {
    zIndex: 1,
    width: 10,
    height: 10,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inactive: {
    display: 'none',
  },
})

const ColorlibStepIcon = (props: any) => {
  const classes = useColorlibStepIconStyles()
  const { active }: StepIconProps = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <FiberManualRecordIcon style={{ color: '#FC195B', fontSize: 14 }} />,
    2: <FiberManualRecordIcon style={{ color: '#FDC424', fontSize: 14 }} />,
    3: <FiberManualRecordIcon style={{ color: '#65B339', fontSize: 14 }} />,
  }

  return (
    <div
      className={clsx(classes.roots, {
        [classes.inactive]: !active,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 'auto',
      padding: 0,
    },
    alternativeLabel: {
      padding: 0,
      background: 'unset',
      alignItems: 'center',
    },
    rotate: {
      transform: 'rotate(-90deg)',
    },
    stepLabel: {
      flexDirection: 'row!important' as any,
    },
    labelContainer: {
      '& $alternativeLabel': {
        marginTop: 0,
      },
    },
  }),
)

const getSteps = () => {
  return ['good', 'medium', 'bad']
}

export const CustomizedSteppers = (props: any) => {
  const classes = useStyles()
  const { stepPosition }: Props = props
  const [activeStep] = useState(stepPosition)
  const steps = getSteps()

  return (
    <div className={classes.rotate}>
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          className={classes.alternativeLabel}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                className={classes.stepLabel}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  )
}
