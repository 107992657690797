import MuiButton from './MuiButton'
import MuiFilledInput from './MuiFilledInput'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiFormLabel from './MuiFormLabel'
import MuiIconButton from './MuiIconButton'
import MuiLink from './MuiLink'
import MuiPaper from './MuiPaper'
import MuiStep from './MuiStep'
import MuiSvgIcon from './MuiSvgIcon'
import MuiTab from './MuiTab'
import MuiTableCell from './MuiTableCell'
import MuiTableHead from './MuiTableHead'
import MuiTypography from './MuiTypography'

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiFilledInput,
  MuiFormControlLabel,
  MuiFormLabel,
  MuiSvgIcon,
  MuiLink,
  MuiStep,
  MuiTab,
}
