import { Api } from './api'

export async function getAppointments() {
  const response = await Api.get<ApiAppointment[]>('/appointments')
  return response.data
}

export async function updateAppointment(
  appointmentId: string,
  updateAppointment: UpdateAppointment,
) {
  const response = await Api.put<ApiAppointment>(
    `/appointments/${appointmentId}`,
    updateAppointment,
  )
  return response.data
}

export async function getLastAppointments() {
  const response = await Api.get<ApiAppointment[]>('/appointments/incoming')
  return response.data
}
