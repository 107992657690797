import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'
import { KeyboardArrowRight } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import browserHistory from '../../../../router/history'
import { FormIcon } from '../../../ui/FormIcon'

interface Props {
  data: DisturbingFormPost
}

const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  tableLink: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 18,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  scoreLegend: {
    color: theme.palette.info.dark,
    fontSize: 16,
    marginLeft: theme.spacing(2),
  },
  svgYellow: {
    filter:
      'grayscale(100%) brightness(160%) sepia(40%) hue-rotate(5deg) saturate(1000%) contrast(0.9)',
  },
  svgGreen: {
    filter:
      'grayscale(10%) brightness(149%) sepia(108%) hue-rotate(58deg) saturate(930%) contrast(0.4);',
  },
  icon: {
    width: 42,
    height: 42,
  },
}))

export const ScoreTable: React.FC<Props> = ({ data }) => {
  const classes = useTableStyles()
  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }

  return (
    <>
      <TableCell component="th" style={{ padding: '0 0 0 18px' }}>
        <Box display="flex" alignItems="center">
          <FormIcon
            risk={data.riskLevel ?? null}
            iconName={data.icon}
            iconType="plain"
          />
          <Typography variant="h5" className={classes.scoreLegend}>
            {data.riskLabel}
          </Typography>
        </Box>
      </TableCell>
      <TableCell component="th" align="right" style={{ position: 'relative' }}>
        <Link
          onClick={() => handleOnEditClick(data.userId)}
          className={classes.tableLink}
        >
          <KeyboardArrowRight />
        </Link>
      </TableCell>
    </>
  )
}
