import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import Sidebar, { SIDEBAR_WIDTH } from './Sidebar/Sidebar'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    height: '100%',
  },
  shiftContent: {
    paddingLeft: SIDEBAR_WIDTH,
  },
  content: {
    height: '100%',
  },
}))

export const MainLayout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, classes.shiftContent)}>
      <Sidebar />
      <main className={classes.content}>{children}</main>
    </div>
  )
}
