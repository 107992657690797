import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { settings } from '../../../../theme/settings'

interface Props {
  steps: Array<string>
  activeStep: number
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  stepper: {
    paddingTop: 0,
  },
  stepLabel: {
    textTransform: 'uppercase',
    color: theme.palette.basicGrey,
  },
  active: {
    color: theme.palette.primary.light,
  },
  completed: {
    color: theme.palette.green,
  },
}))

/**
 * Stepper utilisé dans la modale d'envoi de fiche patient <OutOfReanimationPdfDialog />
 */
export const OutOfReanimationPdfStepper: React.FC<Props> = ({
  steps,
  activeStep,
}) => {
  const classes = useStyles()

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      className={classes.stepper}
    >
      {steps.map((step, index) => (
        <Step key={step}>
          <StepLabel StepIconComponent={ColoredStep}>
            <Typography
              className={clsx(classes.stepLabel, {
                [classes.active]: activeStep === index,
                [classes.completed]: activeStep > index,
              })}
            >
              {step}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const CustomConnector = withStyles({
  alternativeLabel: {
    transform: 'translateX(-4px)',
    top: 11,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  lineHorizontal: {
    borderColor: settings.theme.palette.basicGrey,
    borderWidth: 2,
  },
})(StepConnector)

const useColoredStepStyles = makeStyles<ChuTheme, StepIconProps>((theme) => ({
  root: {
    width: 24,
    height: 24,
    backgroundColor: theme.palette.white,
    border: (props) =>
      `5px solid ${
        props.active
          ? theme.palette.primary.light
          : props.completed
          ? theme.palette.green
          : theme.palette.basicGrey
      }`,
    borderRadius: '50%',
  },
}))
const ColoredStep: React.FC<StepIconProps> = (props) => {
  const classes = useColoredStepStyles(props)
  return <Box className={classes.root} />
}
