import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'
import { KeyboardArrowRight } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import browserHistory from '../../../../router/history'
import { CloudIcon, RainIcon, SunIcon } from '../../../assets/Icons'

interface Props {
  data: RiskyPatient
}

const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  tableLink: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 18,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export const PatientTable: React.FC<Props> = ({ data }) => {
  const classes = useTableStyles()
  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }
  return (
    <>
      <TableCell component="th" style={{ padding: '0 0 0 18px' }}>
        <StepIcon moodValue={data.lastMood} />
      </TableCell>
      <TableCell component="th" align="right" style={{ position: 'relative' }}>
        <Link
          onClick={() => handleOnEditClick(data.id)}
          className={classes.tableLink}
        >
          <KeyboardArrowRight />
        </Link>
      </TableCell>
    </>
  )
}

interface StepIconProps {
  moodValue: MoodValue
}

const StepIcon: React.FC<StepIconProps> = ({ moodValue }) => {
  switch (moodValue) {
    case 'good':
      return <SunIcon />
    case 'medium':
      return <CloudIcon />
    case 'bad':
      return <RainIcon />
    default:
      return <CloudIcon />
  }
}
