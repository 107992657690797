import { Box, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { requestForgottenPassword } from '../../../services/auth.service'
import { useAuthFormStyles } from '../../../theme/styles/authFormStyles'
import { AuthFormLayout } from '../../layouts/AuthFormLayout'
import { InputField } from '../../ui/Inputfield'
import { SubmitButton } from '../../ui/SubmitButton'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.auth.forgottenPassword.request.email.email)
    .required(i18n.auth.forgottenPassword.request.email.required),
})

/**
 * Appelé depuis ForgottenPassword lorsqu'on n'a pas de token
 * Formulaire de requête de modification de mot de passe (email)
 */
export const ForgottenPasswordRequest = () => {
  const history = useHistory()
  const classes = useAuthFormStyles()

  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const goBack = () => {
    history.push('/')
  }
  const onSubmit = async (values: ForgottenPasswordRequestFormValues) => {
    try {
      await requestForgottenPassword(values)
      setShowSuccess(true)
    } catch (error) {
      setShowError(true)
    }
  }

  const alertMessages: Array<AlertMessage> = [
    {
      showAlert: showError,
      severity: 'error',
      title: i18n.auth.forgottenPassword.request.errors.title,
      message: i18n.auth.forgottenPassword.request.errors.message,
      onClose: () => setShowError(false),
    },
    {
      showAlert: showSuccess,
      severity: 'success',
      title: i18n.auth.forgottenPassword.request.success.title,
      message: i18n.auth.forgottenPassword.request.success.message,
      onClose: () => setShowSuccess(false),
    },
  ]

  return (
    <AuthFormLayout
      title={i18n.auth.forgottenPassword.title}
      alertMessages={alertMessages}
    >
      <Formik<ForgottenPasswordRequestFormValues>
        initialValues={{
          email: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              name="email"
              label={i18n.auth.forgottenPassword.request.email.label}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              component={InputField}
            />

            <Typography component="p" className={classes.helperText}>
              {i18n.auth.signin.forgottenPassword.message}
            </Typography>

            <Typography
              component="p"
              className={classes.textLink}
              onClick={goBack}
            >
              {i18n.auth.signin.forgottenPassword.connexion}
            </Typography>

            <Box className={classes.buttonsContainer}>
              <SubmitButton
                title={i18n.auth.signin.forgottenPassword.button}
                disabled={!dirty || !!errors.email || isSubmitting}
              />
            </Box>
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
