import { format } from 'date-fns'
import { toPagingParams } from '../utils/toPagingParams'
import { Api } from './api'

export async function getForms(pagination?: Pagination) {
  const paging = toPagingParams(pagination, '?')
  const response = await Api.get<ApiForm[]>(`/forms${paging}`)
  return response.data
}

export async function getFormPosts(state?: FormPostState) {
  const response = await Api.get<Array<ApiFormPost>>(
    `/form-posts?state=${state}`,
  )
  return response.data
}

export async function getDisturbingFormPosts() {
  const response = await Api.get<ApiDisturbingFormPost[]>(
    `/form-posts/disturbing`,
  )
  return response.data
}

export async function downloadFormPostsCsv() {
  const fileName = `questionnaires-${format(new Date(), 'yyyy-MM-dd')}.csv`
  const response = await Api.get('/form-posts?state=completed', {
    data: {},
    headers: {
      'Content-Type': 'text/csv',
    },
  })

  // Snippet from https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    // IE variant
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data], { type: 'text/csv' }),
      fileName,
    )
  } else {
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'text/csv' }),
    )
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
  }
}

export async function updateFormPostProcessedStatus(
  formPostId: string,
  newProcessed: boolean,
) {
  const response = await Api.put<Array<ApiFormPost>>(
    `/form-posts/${formPostId}/processed`,
    {
      processed: newProcessed,
    },
  )
  return response.data
}
