import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { Footer } from './Footer'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    background: theme.palette.linearBackground,
    opacity: 1,
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))

/**
 * Layout basique, surtout utilisé pour les écrans hors connexion
 */
export const MinimalLayout: React.FC = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {children}
        <Footer />
      </main>
    </div>
  )
}
