import { Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import browserHistory from '../../../router/history'
import { getFractionalScore } from '../../../utils/getFractionalScore'
import { orderResultsByRisk } from '../../../utils/orderResultsByRisk'
import { ArrowRightGreyIcon, CheckIcon, WarningIcon } from '../../assets/Icons'
import { FormIcon } from '../../ui/FormIcon'

enum RiskLevel {
  high = 'high',
  medium = 'medium',
  low = 'low',
}

interface Props {
  form: Form | undefined
  activatedAt?: Date
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  noLastFormText: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.yellow,
  },
  userNotActivatedText: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.riskLevel.high.main,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  nameContainer: {
    width: 310,
    paddingLeft: 16,
  },
  name: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: theme.palette.info.dark,
  },

  formResultsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 360,
    height: '100%',
    minHeight: 60,
    padding: '8px 20px 8px 20px',
  },

  containerRiskHigh: { backgroundColor: theme.palette.riskLevel.high.light },
  containerRiskMedium: {
    backgroundColor: theme.palette.riskLevel.medium.light,
  },
  containerRiskLow: { backgroundColor: theme.palette.riskLevel.low.light },
  containerRiskUnknown: { backgroundColor: theme.palette.info.light },

  iconContainer: {
    position: 'relative',
    padding: 4,
    marginRight: 16,
    border: '3px solid grey',
    borderRadius: '50%',
  },
  iconContainerHigh: { borderColor: theme.palette.riskLevel.high.main },
  iconContainerMedium: { borderColor: theme.palette.riskLevel.medium.main },
  iconContainerLow: { borderColor: theme.palette.riskLevel.low.main },
  iconDetail: {
    position: 'absolute',
    top: -6,
    right: -6,
    width: 20,
    height: 20,
  },

  scoresContainer: {
    flexGrow: 1,
  },
  score: {
    fontSize: 14,
    color: theme.palette.info.dark,
  },

  chevronContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
}))

/**
 * Utilisé dans <PatientsFollowed /> pour montrer le score du dernier questionnaire rempli par un patient
 * Peut aussi afficher le fait que le compte patient n'est pas actif, ou qu'aucun questionnaire n'a été rempli
 */
export const LastFormDetails: React.FC<Props> = ({ form, activatedAt }) => {
  const classes = useStyles()

  // Cas d'un patient qui ne s'est jamais connecté (seulement si !form, car il est possible qu'un enquêteur ait rempli un questionnaire)
  if (!form && !activatedAt) {
    return (
      <Box className={classes.nameContainer}>
        <Typography className={classes.userNotActivatedText}>
          {i18n.patients.table.content.userNotActivated}
        </Typography>
      </Box>
    )
  }

  // Cas d'un patient qui s'est déjà connecté au moins 1 fois, mais qui n'a répondu à aucun questionnaire
  if (!form) {
    return (
      <Box className={classes.nameContainer}>
        <Typography className={classes.noLastFormText}>
          {i18n.patients.table.content.noLastForm}
        </Typography>
      </Box>
    )
  }

  // Cas d'un patient ayant déjà répondu à au moins 1 formulaire
  const resultsOrderedByRisk = orderResultsByRisk(form.results)
  const formRisk = resultsOrderedByRisk[0]?.score?.risk

  return (
    <Box className={classes.root}>
      {/* NOM DU FORMULAIRE */}
      <Box className={classes.nameContainer}>
        <Typography className={classes.name}>{form.technicalName}</Typography>
      </Box>

      <Box
        className={clsx(classes.formResultsContainer, {
          [classes.containerRiskHigh]: formRisk === RiskLevel.high,
          [classes.containerRiskMedium]: formRisk === RiskLevel.medium,
          [classes.containerRiskLow]: formRisk === RiskLevel.low,
          [classes.containerRiskUnknown]: formRisk === undefined,
        })}
      >
        {/* ICONE */}
        {form.icon && (
          <Box
            className={clsx(classes.iconContainer, {
              [classes.iconContainerHigh]: formRisk === RiskLevel.high,
              [classes.iconContainerMedium]: formRisk === RiskLevel.medium,
              [classes.iconContainerLow]: formRisk === RiskLevel.low,
            })}
          >
            <FormIcon
              risk={formRisk ? formRisk : null}
              iconName={form.icon}
              iconType="rounded"
              width={35}
              height={35}
            />
            {formRisk === RiskLevel.high && (
              <WarningIcon className={classes.iconDetail} />
            )}
            {formRisk === RiskLevel.low && (
              <CheckIcon className={classes.iconDetail} />
            )}
          </Box>
        )}

        {/* SCORES */}
        <Box className={classes.scoresContainer}>
          {form.results.map((result) => (
            <Typography key={result.name} className={classes.score}>
              {`${getFractionalScore(result.score)} : ${result.score?.label}`}
            </Typography>
          ))}
        </Box>

        {/* LIEN VERS LES RESULTATS */}
        <Box className={classes.chevronContainer}>
          <Link
            component="button"
            onClick={() => browserHistory.push(`/forms/${form.id}`)}
          >
            <ArrowRightGreyIcon
              alt={i18n.links.modification}
              style={{ marginLeft: 9 }}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  )
}
