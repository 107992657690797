import { settings } from '../settings'
import typography from '../typography'

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${settings.theme.palette.divider}`,
  },
  head: {
    color: '#a1a1a1',
    lineHeight: 1.5,
  },
}
