import {
  Box,
  Button,
  DialogActions,
  Grid,
  GridSpacing,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import {
  addDoctorNoteAction,
  loadPatientHistoryAction,
} from '../../../../context/actions/history'
import { useAppContext } from '../../../../context/app-context'
import { InputField, PickerDate, TextAreaField } from '../../../ui/Inputfield'
const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 25px',
  },
}))

const schema = Yup.object().shape({
  title: Yup.string()
    .max(50, i18n.patients.actionDialog.titleMaxCharacters)
    .required(i18n.patients.actionDialog.titleRequired),
  description: Yup.string().max(
    250,
    i18n.patients.actionDialog.descriptionMaxCharacters,
  ),
  date: Yup.date().required(i18n.patients.actionDialog.eventDateRequired),
})

export const EventActionForm: React.FC<{
  onClose: () => void
  patient: Patient
}> = ({ onClose, patient }) => {
  const classes = useStyles()
  const [spacing] = useState<GridSpacing>(2)
  const { dispatch } = useAppContext()
  const { id } = useParams<{ id: string }>()

  return (
    <Formik
      initialValues={{
        title: '',
        description: '',
        date: new Date(),
      }}
      validationSchema={schema}
      onSubmit={async (values, actions) => {
        try {
          await dispatch(
            addDoctorNoteAction({
              patient: patient.id,
              title: values.title,
              description: values.description,
              date: values.date.toISOString(),
            }),
          )
        } finally {
          actions.setSubmitting(false)
          dispatch(loadPatientHistoryAction(id))
          onClose()
        }
      }}
    >
      {({
        values,
        errors,
        dirty,
        isSubmitting,
        handleBlur,
        handleSubmit,
        handleChange,
      }) => (
        <form className={classes.root} onSubmit={handleSubmit}>
          <Box display="flex">
            <Grid container spacing={spacing}>
              <Grid item xs={6}>
                <Field
                  name="title"
                  label={i18n.patients.actionDialog.labelTitle}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  component={InputField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="date"
                  label={i18n.patients.actionDialog.labelDate}
                  type="date"
                  onBlur={handleBlur}
                  component={PickerDate}
                  value={values.date}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  label={i18n.patients.actionDialog.labelDescription}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  component={TextAreaField}
                />
              </Grid>
            </Grid>
          </Box>
          <DialogActions className={classes.actionBar}>
            <Button
              onClick={onClose}
              color="primary"
              variant="contained"
              size="large"
            >
              {i18n.button.return}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              size="large"
              type="submit"
              disabled={
                !dirty ||
                !!errors.title ||
                !!errors.description ||
                !!errors.date ||
                isSubmitting
              }
            >
              {i18n.button.validate}
            </Button>
          </DialogActions>
        </form>
      )}
    </Formik>
  )
}
