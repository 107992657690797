import { Box, CircularProgress, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import {
  loadInspectorsAction,
  resetPatientsStateAction,
  updateInspectorAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import { HeroBanner } from '../../ui/HeroBanner'
import { InputField } from '../../ui/Inputfield'
import { LoadingPane } from '../../ui/LoadingPane'
import { MessageAlert } from '../../ui/MessageAlert'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.inspectors.create.email.valid)
    .required(i18n.inspectors.create.email.required),
  firstName: Yup.string().required(i18n.inspectors.create.firstName.required),
  lastName: Yup.string().required(i18n.inspectors.create.lastName.required),
})

/**
 * Formulaire de modification d'enquêteur
 */
export const UpdateInspector = () => {
  const strings = i18n.inspectors.create
  const classes = useUserFormStyles()
  const { id } = useParams()
  const { dispatch, inspectors } = useAppContext()
  const [showError, setShowError] = useState(false)
  const [inspectorToUpdate, setInspectorToUpdate] = useState<Inspector | null>(
    null,
  )

  // Récupère la liste des enquêteurs
  useEffect(() => {
    if (!inspectors.data) {
      dispatch(loadInspectorsAction())
    }

    return () => {
      dispatch(resetPatientsStateAction())
    }
  }, [dispatch, inspectors.data])

  // Récupère l'enquêteur à modifier
  useEffect(() => {
    if (inspectors.data) {
      const inspector = inspectors.data.find((item) => item.id === id)
      if (inspector) {
        setInspectorToUpdate(inspector)
      }
    }
  }, [id, inspectors.data])

  // Affiche un écran de chargement tant que l'enquêteur à modifier n'est pas chargé
  if (!inspectorToUpdate) {
    return <LoadingPane />
  }

  const onSubmit = async (values: UpdateInspectorFormValues) => {
    try {
      const inspector: InspectorToUpdate = {
        id,
        ...values,
      }
      await dispatch(updateInspectorAction(inspector))
      await dispatch(loadInspectorsAction())
      browserHistory.push('/admin/inspectors')
    } catch (error) {
      setShowError(true)
    }
  }

  const inspectorFullName = `${inspectorToUpdate.lastName.toUpperCase()}  ${
    inspectorToUpdate.firstName
  }`

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={
          i18n.inspectorsBanner.updateInspectorTitle + ' ' + inspectorFullName
        }
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.inspectorsBanner.goBack}
      />

      {showError && (
        <MessageAlert
          message={i18n.inspectors.update.errors.message}
          title={i18n.inspectors.update.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      <Formik<UpdateInspectorFormValues>
        initialValues={{
          email: inspectorToUpdate.email,
          firstName: inspectorToUpdate.firstName,
          lastName: inspectorToUpdate.lastName,
          role: 'inspector',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const submitDisabled =
            !dirty ||
            !!errors.email ||
            !!errors.firstName ||
            !!errors.lastName ||
            isSubmitting

          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Box className={classes.mainContainer}>
                {/* IDENTITY */}
                <Box className={classes.formSection}>
                  <UserFormSectionTitle title={strings.inspector.identity} />
                  <Grid container spacing={USER_FORM_SPACING}>
                    <Grid item xs={6}>
                      <Field
                        name="lastName"
                        label={strings.lastName.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="firstName"
                        label={strings.firstName.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* INFORMATION */}
                <Box
                  className={clsx(classes.formSection, classes.formSectionDark)}
                >
                  <UserFormSectionTitle title={strings.inspector.information} />
                  <Grid container spacing={USER_FORM_SPACING}>
                    <Grid item xs={10}>
                      <Field
                        name="email"
                        label={strings.email.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* BUTTONS */}
                <Box className={classes.buttonsContainer}>
                  <PreviousButton
                    title={i18n.auth.forgottenPassword.reset.return}
                    onClick={() => browserHistory.goBack()}
                    marginRight
                  />

                  <SubmitButton
                    title={strings.buttonUpdate}
                    disabled={submitDisabled}
                  />

                  {inspectors.state === 'loading' && (
                    <CircularProgress
                      size={24}
                      className={classes.loadingIcon}
                    />
                  )}
                </Box>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Grid>
  )
}
