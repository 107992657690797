import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import sanitize from 'sanitize-html'
import { ChuTheme } from '../../@types/theme'
import { i18n } from '../../assets/i18n'

export enum QuestionnaireId {
  CheckUp = '5e92ed6bf39a2357467073ab',
  AtTheMoment = '5e92ed6bf39a2357467073ad',
  MyFeelings = '5e92ed6bf39a2357467073ae',
  MyMoral = '5e92ed6bf39a2357467073af',
  EpworthLadder = '5f201208eb21ac72d92953ca',
  PichotLadder = '5f201208eb21ac72d92953c9',
  MySleep = '5ea29685e6333d0006f4fd88',
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    '& div:nth-of-type(1)': {
      backgroundColor: theme.palette.white,
      padding: theme.spacing(1),
    },
    '& div:nth-of-type(2)': {
      backgroundColor: theme.palette.accentBackgroundColor,
      padding: theme.spacing(1),
    },
    '& h3': {
      color: theme.palette.secondary.main,
      fontSize: 12,
      textAlign: 'center',
      textTransform: 'uppercase',
      marginBottom: theme.spacing(1),
    },
    '& p': {
      color: theme.palette.info.dark,
      fontSize: 12,
      marginBottom: 2,
    },
  },
}))

interface Props {
  questionnaire: string
}

export const QuestionnaireScoreTooltipContent: React.FC<Props> = ({
  questionnaire,
}) => {
  const classes = useStyles()

  let htmlContent = i18n.questionnaireScoreTooltip.Default
  switch (questionnaire) {
    case QuestionnaireId.CheckUp:
      htmlContent = i18n.questionnaireScoreTooltip.CheckUp
      break
    case QuestionnaireId.AtTheMoment:
      htmlContent = i18n.questionnaireScoreTooltip.AtTheMoment
      break
    case QuestionnaireId.MyFeelings:
      htmlContent = i18n.questionnaireScoreTooltip.MyFeelings
      break
    case QuestionnaireId.MyMoral:
      htmlContent = i18n.questionnaireScoreTooltip.MyMoral
      break
    case QuestionnaireId.EpworthLadder:
      htmlContent = i18n.questionnaireScoreTooltip.EpworthLadder
      break
    case QuestionnaireId.PichotLadder:
      htmlContent = i18n.questionnaireScoreTooltip.PichotLadder
      break
    case QuestionnaireId.MySleep:
      htmlContent = i18n.questionnaireScoreTooltip.MySleep
  }

  return (
    <Box borderRadius={8} overflow="hidden">
      <div
        className={classes.root}
        dangerouslySetInnerHTML={{
          __html: sanitize(htmlContent),
        }}
      />
    </Box>
  )
}
