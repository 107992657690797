import { makeStyles } from '@material-ui/core'
import { ChuTheme } from '../../@types/theme'

export const useUserTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
    position: 'relative',
  },
  titleContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    height: 36,
  },
  customCellRender: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  customCellLastName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  editIcon: {
    cursor: 'pointer',
    border: `1px dashed ${theme.palette.common.black}`,
    borderRadius: '50%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      border: `1px solid ${theme.palette.common.black}`,
      backgroundColor: theme.palette.background.paper,
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))
