import {
  getHospitalDepartmentsByHospital,
  getHospitals,
} from '../../services/hospitals.service'
import { fromApiHospital, fromApiHospitalDepartment } from '../../utils'
import { createAction } from '../app-context'

export const loadHospitalsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => (draft.hospitals.state = 'loading'))
      const hospitals = await getHospitals()
      await produceState((draft) => {
        draft.hospitals.data = hospitals.map(fromApiHospital)
        draft.hospitals.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => (draft.hospitals.state = 'error'))
    }
  },
)

export const loadHospitalDepartmentsByHospitalAction = createAction(
  (hospitalId: string) => async ({ produceState }) => {
    try {
      await produceState(
        (draft) => (draft.hospitalDepartments.state = 'loading'),
      )
      const hospitalDepartments = await getHospitalDepartmentsByHospital(
        hospitalId,
      )
      await produceState((draft) => {
        // Comme les hospitalDepartments sont chargés par morceaux (par hôpital), ajoute un nouveau morceau tout en évitant les duplications
        const newHospitalDepartments = hospitalDepartments
          .map(fromApiHospitalDepartment)
          .filter(
            (newHospitalDepartment) =>
              !draft.hospitalDepartments.data?.some(
                (item) => item.id === newHospitalDepartment.id,
              ),
          )

        draft.hospitalDepartments.data = draft.hospitalDepartments.data
          ? [...draft.hospitalDepartments.data, ...newHospitalDepartments]
          : newHospitalDepartments
        draft.hospitalDepartments.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => (draft.hospitalDepartments.state = 'error'))
    }
  },
)
