import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { orderResultsByRisk } from '../../../utils/orderResultsByRisk'
import { CheckIcon, WarningIcon } from '../../assets/Icons'
import { FormIcon } from '../../ui/FormIcon'

enum RiskLevel {
  high = 'high',
  medium = 'medium',
  low = 'low',
}

interface Props {
  form: FormResult[] | undefined
  formName: string
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  formResultsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 360,
    height: '100%',
    minHeight: 60,
    padding: '10px 20px 10px 20px',
  },
  iconContainer: {
    position: 'relative',
    padding: 4,
    marginRight: 16,
    border: '3px solid grey',
    borderRadius: '50%',
  },
  iconContainerHigh: { borderColor: theme.palette.riskLevel.high.main },
  iconContainerMedium: { borderColor: theme.palette.riskLevel.medium.main },
  iconContainerLow: { borderColor: theme.palette.riskLevel.low.main },
  iconDetail: {
    position: 'absolute',
    top: -6,
    right: -6,
    width: 20,
    height: 20,
  },

  formNameContainer: {
    flexGrow: 1,
  },
}))

/**
 * Appelé dans <FormResult />
 * Contenu d'une cellule indiquant le questionnaire rempli et le niveau de risque associé
 */
export const FormRisk: React.FC<Props> = ({ form, formName }) => {
  const classes = useStyles()

  if (!form) {
    return <></>
  }

  const resultsOrderedByRisk = orderResultsByRisk(form)
  const formRisk = resultsOrderedByRisk[0]?.score?.risk

  return (
    <Box className={classes.root}>
      <Box className={classes.formResultsContainer}>
        {/* ICONE */}
        {resultsOrderedByRisk[0].icon && (
          <Box
            className={clsx(classes.iconContainer, {
              [classes.iconContainerHigh]: formRisk === RiskLevel.high,
              [classes.iconContainerMedium]: formRisk === RiskLevel.medium,
              [classes.iconContainerLow]: formRisk === RiskLevel.low,
            })}
          >
            <FormIcon
              risk={formRisk ? formRisk : null}
              iconName={resultsOrderedByRisk[0].icon}
              iconType="rounded"
              width={35}
              height={35}
            />
            {formRisk === RiskLevel.high && (
              <WarningIcon className={classes.iconDetail} />
            )}
            {formRisk === RiskLevel.low && (
              <CheckIcon className={classes.iconDetail} />
            )}
          </Box>
        )}

        {/* FORM NAME */}
        <Box className={classes.formNameContainer}>
          <Typography>{formName}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
