import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface Props {
  title: string
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: 20,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

/**
 * Titre d'une section dans les formulaires de création / modification d'utilisateur
 */
export const UserFormSectionTitle: React.FC<Props> = ({ title }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>{title}</Typography>
    </Box>
  )
}
