import {
  createDoctorNote,
  getUserHistory,
} from '../../services/history.service'
import { fromApiHistoryEntry, toApiCreateDoctorNote } from '../../utils'
import { createAction } from '../app-context'

export const loadPatientHistoryAction = createAction(
  (id: string) => async ({ produceState }) => {
    try {
      await produceState((draft) => (draft.patientHistory.state = 'loading'))
      const patientHistory = await getUserHistory(id)
      await produceState((draft) => {
        draft.patientHistory.data = patientHistory.map(fromApiHistoryEntry)
        draft.patientHistory.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => (draft.patientHistory.state = 'error'))
    }
  },
)

export const resetPatientHistoryAction = createAction(
  () => async ({ produceState }) => {
    await produceState((draft) => {
      draft.patientHistory.state = 'idle'
      draft.patientHistory.data = undefined
    })
  },
)

export const addDoctorNoteAction = createAction(
  (createNote: CreateDoctorNote) => async () => {
    try {
      await createDoctorNote(toApiCreateDoctorNote(createNote))
    } catch (error) {
      console.error(error)
    }
  },
)
