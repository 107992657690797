import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { loadPatientMoodAction } from '../../../../context/actions/mood'
import { useAppContext } from '../../../../context/app-context'
import { settings } from '../../../../theme/settings'
import { dateToString } from '../../../../utils/dateUtils'
import {
  CloudWhiteIcon,
  QuestionMarkIcon,
  RainWhiteIcon,
  SunWhiteIcon,
} from '../../../assets/Icons'

const moodStyles = makeStyles<ChuTheme>((theme) => ({
  roots: {},
  contentBloc: {
    display: 'flex',
    alignItems: 'center',
    width: 90,
    height: 90,
    borderRadius: 4,
    justifyContent: 'center',
    flexDirection: 'column',
    color: theme.palette.white,
    marginLeft: 5,
    '& svg': { fontSize: '1.8vw' },
    boxShadow: '0 1px 3px #3F3F4426',
  },
  undefined: {
    background: theme.palette.linearUndefinedMoodBackground,
  },
  good: {
    background: theme.palette.linearSuccessBackground,
  },
  medium: {
    background: theme.palette.linearWarningBackground,
  },
  bad: {
    background: theme.palette.linearDangerBackground,
  },
}))

export const MoodIconIndicator = () => {
  const classes = moodStyles()
  const { dispatch, patientMoods } = useAppContext()
  const { id } = useParams<{ id: string }>()
  const moods = patientMoods?.data

  useEffect(() => {
    dispatch(loadPatientMoodAction(id))
  }, [dispatch, id])

  const lastMood = moods?.slice(-1)[0]

  return (
    <Box
      className={clsx(classes.contentBloc, {
        [classes.undefined]: !lastMood?.moodValue,
        [classes.good]: lastMood?.moodValue === 'good',
        [classes.medium]: lastMood?.moodValue === 'medium',
        [classes.bad]: lastMood?.moodValue === 'bad',
      })}
    >
      {lastMood ? (
        <>
          {lastMood?.moodValue === 'good' ? (
            <SunWhiteIcon alt="good" />
          ) : lastMood?.moodValue === 'medium' ? (
            <CloudWhiteIcon alt="medium" />
          ) : (
            <RainWhiteIcon alt="bad" />
          )}
          <span style={{ fontSize: 13, marginTop: 8 }}>
            {dateToString(new Date(lastMood.date))}
          </span>
        </>
      ) : (
        <>
          <QuestionMarkIcon alt={i18n.patientDetails.moods.notMoodValue} />
          <span style={{ color: settings.theme.palette.white }}>
            {i18n.patientDetails.moods.notMoodValue}
          </span>
        </>
      )}
    </Box>
  )
}
