import { Api } from './api'

export async function getDoctors() {
  const response = await Api.get<ApiDoctor[]>(`/doctors`)
  return response.data
}

export async function createDoctor(doctor: ApiDoctorToCreate) {
  const response = await Api.post<ApiDoctor>(`/users`, doctor)
  return response.data
}

export async function updateDoctor(doctor: ApiDoctorToUpdate) {
  const response = await Api.put<ApiDoctor>(`/users/${doctor._id}`, doctor)
  return response.data
}
