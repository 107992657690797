import { Api } from './api'

export async function getInspectors() {
  const response = await Api.get<ApiInspector[]>(`/inspectors`)
  return response.data
}

export async function createInspector(user: ApiInspectorToCreate) {
  const response = await Api.post<ApiInspector>(`/users`, user)
  return response.data
}

export async function updateInspector(user: ApiInspectorToUpdate) {
  const response = await Api.put<ApiInspector>(`/users/${user._id}`, user)
  return response.data
}
