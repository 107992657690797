import { Box, Fade, makeStyles } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { HtmlTooltip } from './HtmlTooltip'
import { QuestionnaireScoreTooltipContent } from './QuestionnaireScoreTooltipContent'

interface Props {
  questionnaireId: string
  children: React.ReactElement<any, any>
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    boxShadow: `0px 3px 6px ${theme.palette.shadow}`,
    borderRadius: '8px',
  },
}))

export const QuestionnaireScoreTooltip: React.FC<Props> = ({
  questionnaireId,
  children,
}) => {
  const classes = useStyles()

  return (
    <HtmlTooltip
      title={
        <Box className={classes.root}>
          <QuestionnaireScoreTooltipContent questionnaire={questionnaireId} />
        </Box>
      }
      placement="left"
      arrow
      interactive
      enterDelay={100}
      leaveDelay={100}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
    >
      {children}
    </HtmlTooltip>
  )
}
