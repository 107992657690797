import {
  Avatar,
  createStyles,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inline: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: theme.typography.fontWeightRegular,
    },
    result: {
      color: theme.palette.secondary.light,
    },
    item: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.light,
      fontSize: 18,
      width: 32,
      height: 32,
    },
    spaceDivider: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
)

/**
 * Appelé dans <FormResult />
 * Affiche les réponses à une question de type "single"
 */
export const QuestionSingleAnswer = ({
  counter,
  question,
}: {
  counter?: number
  question: SingleAnswer
}) => {
  const classes = useStyles()
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar className={classes.item}>
            {counter === 0 ? 1 : counter && counter + 1}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography
                variant="h5"
                className={classes.inline}
                color="textPrimary"
              >
                {question.question}
                <span className={classes.result}> {question.answer}</span>
              </Typography>
            </>
          }
        />
      </ListItem>
      <Divider component="li" className={classes.spaceDivider} />
    </>
  )
}
