import Box from '@material-ui/core/Box'
import React from 'react'
import { formatDate } from '../../../../utils/dateUtils'
import { CustomizedSteppers } from '../../../ui/CustomizedSteppers'

interface Props {
  moods?: Mood[]
  id?: string
}

export const MoodsIndicatorCard = ({ moods, id }: Props) => {
  return (
    <Box display="flex" justifyContent="space-around">
      {moods?.slice(-4).map((mood) => {
        return (
          <Box key={mood.id}>
            {mood.userId === id && (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box>
                  {mood.date && (
                    <>
                      <CustomizedSteppers
                        stepPosition={
                          mood.moodValue === 'bad'
                            ? 0
                            : mood.moodValue === 'medium'
                            ? 1
                            : 2
                        }
                      />

                      <Box
                        component="p"
                        pt={4}
                        style={{
                          fontSize: 14,
                          color: '#A1A1A1',
                          fontWeight: 700,
                        }}
                      >
                        {formatDate(mood.date)}
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )
      })}
    </Box>
  )
}
