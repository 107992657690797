import { Box, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { getAge } from '../../../../utils/dateUtils'
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber'
import { getFullName } from '../../../../utils/getFullName'

interface Props {
  patient: Patient
  userRole?: Role
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  patientName: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
  },
  patientMail: {
    fontSize: 18,
  },
  patientYearOld: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightRegular,
  },
  patientInformation: {
    '& h4': {
      fontSize: 20,
      color: theme.palette.primary.light,
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
}))

export const IdentityBloc: React.FC<Props> = ({ patient, userRole }) => {
  const classes = useStyles()

  return (
    <Box>
      <Typography variant="h1" className={classes.patientName}>
        {getFullName(patient.firstName, patient.lastName)}
      </Typography>
      <Typography variant="h2" className={classes.patientYearOld}>
        {getAge(patient.birthDate)}
      </Typography>
      <br />
      <Box className={classes.patientInformation}>
        <Typography variant="h4">
          {formatPhoneNumber(patient.phoneNumber)}
        </Typography>
        <Link
          href={`mailto:${patient.email}`}
          className={classes.patientMail}
          target="_blank"
        >
          {patient.email}
        </Link>
      </Box>
    </Box>
  )
}
