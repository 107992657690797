import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { AuthFormLayout } from '../../layouts/AuthFormLayout'
import { LegalNoticeSection } from './LegalNoticeSection'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    maxWidth: 600,
    maxHeight: '100vh',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  introduction: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  sectionContainer: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  content: {
    fontSize: 14,
  },
}))

export const LegalNotice = () => {
  const strings = i18n.legalNotice
  const classes = useStyles()

  return (
    <AuthFormLayout title={strings.title} hideImage>
      <Box className={classes.root}>
        <Typography className={classes.introduction}>
          {strings.presentation.introductionBeforeUrl} {process.env.PUBLIC_URL}{' '}
          {strings.presentation.introductionAfterUrl}
        </Typography>
        <LegalNoticeSection
          title={strings.presentation.ownerTitle}
          contents={[strings.presentation.ownerContent]}
        />
        <LegalNoticeSection
          title={strings.presentation.creatorTitle}
          contents={[strings.presentation.creatorContent]}
        />
        <LegalNoticeSection
          title={strings.presentation.publicationTitle}
          contents={[strings.presentation.publicationContent]}
        />
        <LegalNoticeSection
          title={strings.presentation.webmasterTitle}
          contents={[strings.presentation.webmasterContent]}
        />
        <LegalNoticeSection
          title={strings.presentation.hostingTitle}
          contents={[
            strings.presentation.hostingContentAdress,
            strings.presentation.hostingContentContact,
          ]}
        />
      </Box>
    </AuthFormLayout>
  )
}
