import Box from '@material-ui/core/Box/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import clsx from 'clsx'
import React, { useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { DataTableTextLabels } from '../../../../assets/i18n/material'
import browserHistory from '../../../../router/history'
import { getFullName } from '../../../../utils/getFullName'
import { TablePaginationActions } from './TablePaginationActions'

type DataEntry =
  | RiskyPatient
  | DisturbingFormPost
  | RowsAppointmentItem
  | RowsHistoryItem
  | RowsLastAppointmentItem

interface TableComponentProps {
  data: any
}
interface Props {
  header: React.ReactElement<any, any>
  background?: string
  data?: Array<DataEntry>
  rowPerPage: number
  className?: string
  isFullData?: boolean
  TableComponent: React.FC<TableComponentProps>
}

const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    padding: theme.spacing(2),
    boxShadow: `0px 1px 3px ${theme.palette.dashboarTableShadow}`,
    borderRadius: theme.spacing(1 / 2),
  },
  paper: {
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: theme.palette.white,
    '& tr th ': {
      color: theme.palette.primary.main,
      fontSize: 18,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
  },
  bold: { fontWeight: theme.typography.fontWeightMedium },
  tableFooter: {
    '& tr td': {
      border: 'unset',
    },
    '& tr td > div': {
      paddingTop: 12,
      display: 'flex',
      alignItems: 'baseline',
    },
    '& tr td div p': {
      color: theme.palette.white,
      fontSize: 12,
    },
  },
  tableHeader: {
    position: 'relative',
    height: 85,
  },
  tableHeaderIcon: {
    position: 'absolute',
    top: '-15px',
    left: '-15px',
  },
  tableHeaderIndicator: {
    width: 63,
    height: 63,
    color: theme.palette.white,
    background: theme.palette.secondary.main,
    borderRadius: '50%',
    fontSize: 32,
    fontWeight: theme.typography.fontWeightMedium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  tableHeaderTitle: {
    color: theme.palette.white,
    textTransform: 'uppercase',
  },
  tableSimpleHeader: {
    height: 70,
    '& svg, h3': {
      color: theme.palette.primary.light,
    },
  },
  media: {
    '& svg': {
      width: 42,
      height: 42,
      color: theme.palette.primary.light,
      marginRight: 15,
    },
  },
  tableLastHeader: {
    height: 270,
    marginBottom: 50,
    '& h3': {
      color: theme.palette.primary.light,
    },
  },
  mediaAbsolute: {
    margin: 'auto',
  },
}))

export const CustomPaginationActionsTable: React.FC<Props> = ({
  header,
  background,
  data,
  TableComponent,
  rowPerPage,
  className,
  isFullData,
}) => {
  const classes = useTableStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage] = useState(rowPerPage)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }
  const emptyRows = data
    ? rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    : 0
  const pageData =
    data && rowsPerPage > 0
      ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : data

  return (
    <TableContainer className={classes.root} style={{ background: background }}>
      {header}
      <Table className={classes.table} aria-label="custom pagination table">
        <TableBody className={classes.paper}>
          {pageData?.map((row, i) => (
            <TableRow key={i}>
              {isFullData && (
                <>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.bold}
                  >
                    {row.lastName}
                  </TableCell>
                  <TableCell component="th" align="center">
                    {row.firstName}
                  </TableCell>
                </>
              )}
              {<TableComponent data={row} />}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows, border: 'unset' }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {data && data.length > rowPerPage && (
          <TableFooter className={clsx(classes.tableFooter, className)}>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={10}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${DataTableTextLabels.pagination?.displayRows}  ${count} `
                }
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

export const TableHeaderBleuActions = ({
  media,
  counter,
  title,
}: {
  media: React.ReactElement<any, any>
  counter: RiskyPatient[] | undefined | any
  title: string
}) => {
  const classes = useTableStyles()
  return (
    <Box width={1} className={classes.tableHeader}>
      <Box className={classes.tableHeaderIcon}>{media}</Box>
      <Box display="flex" alignItems="center" ml={12} pt={1 / 2}>
        <Box className={classes.tableHeaderIndicator}>{counter?.length}</Box>
        <Typography variant="h3" className={classes.tableHeaderTitle}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export const TableHeaderHistoryActions = ({
  media,
  title,
}: {
  media: React.ReactElement<any, any>
  title: string
}) => {
  const classes = useTableStyles()
  return (
    <Box width={1} className={classes.tableSimpleHeader}>
      <Box display="flex" alignItems="center" ml={2} pt={1}>
        <Box className={classes.media}>{media}</Box>
        <Typography variant="h3" className={classes.tableHeaderTitle}>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export const TableHeaderLastAppointmentActions = ({
  media,
  patient,
  title,
}: {
  media: React.ReactElement<any, any>
  patient: any
  title: string
}) => {
  const classes = useTableStyles()
  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }
  return (
    <Box
      width={1}
      display="flex"
      justifyContent="center"
      flexDirection="column"
      className={classes.tableLastHeader}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Box className={classes.mediaAbsolute}>{media}</Box>
        {patient[0] ? (
          <>
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
                lineHeight: 1.4,
                textTransform: 'uppercase',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
                lineHeight: 1.4,
                marginBottom: 8,
              }}
            >
              Le {patient[0].appointmentDate} à {patient[0].appointmentHour}
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: 18, marginBottom: 8 }}
            >
              {getFullName(patient[0].firstName, patient[0].lastName)}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOnEditClick(patient[0].id)}
              className={classes.button}
              startIcon={<PeopleOutlineIcon />}
            >
              <span style={{ marginRight: 10 }}>
                {i18n.patientTable.patientLink}
              </span>
            </Button>
          </>
        ) : (
          <Typography style={{ marginTop: 32, fontSize: 24 }}>
            {i18n.dashboard.table.lastAppointment.noAppointments}
          </Typography>
        )}
      </Box>
      {patient[0] && (
        <Box display="flex" alignItems="flex-start" mt={3}>
          <Typography variant="body1" style={{ fontSize: 12 }}>
            {i18n.dashboard.table.lastAppointment.title.toUpperCase()}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
