import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import differenceInDays from 'date-fns/differenceInDays'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { dateToString } from '../../../../utils/dateUtils'
import { BlocIcons } from './BlocIcons'

const reanimationStyles = makeStyles<ChuTheme>((theme) => ({
  roots: {
    position: 'relative',
    '& h1, h4, p': {
      color: '#fff',
    },
  },
  contentBloc: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 222,
    background: theme.palette.linearBackground,
    marginTop: 170,
    marginLeft: 30,
    marginRight: 30,
    borderRadius: 8,
  },
  boxIcon: {
    position: 'absolute',
    top: '-85px',
    left: 'calc(50% + -70px)',
    right: 0,
  },
  date: {
    textAlign: 'center',
  },
}))

export const ReanimationBloc = ({ patient }: { patient: Patient }) => {
  const classes = reanimationStyles()

  const reanimationDay = differenceInDays(
    new Date(patient.outHospitalDate),
    new Date(patient.inHospitalDate),
  )

  return (
    <Grid container className={classes.roots}>
      <Box className={classes.contentBloc}>
        <Grid item xs={12} md={4} lg={5}>
          <Box position="relative">
            <BlocIcons
              imgSrc="/images/common/images/illustration_reanimation.svg"
              className={classes.boxIcon}
            />
          </Box>
          <Box className={classes.date} mt={12}>
            <Typography
              style={{
                lineHeight: 3,
              }}
            >
              {i18n.patientDetails.inHospitalDate.label} :{' '}
              {dateToString(new Date(patient.inHospitalDate))}
            </Typography>
            <Typography>
              {i18n.patientDetails.outHospitalDate.label} :{' '}
              {dateToString(new Date(patient.outHospitalDate))}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={7}>
          <Box display="flex" flexDirection="column">
            <Typography
              variant="h4"
              style={{
                textTransform: 'uppercase',
                lineHeight: 2,
                fontSize: '1.2vw',
              }}
            >
              {i18n.patientDetails.reanimationDuration.label}
            </Typography>
            <Typography variant="h1" style={{ textTransform: 'uppercase' }}>
              {reanimationDay} {i18n.patientDetails.reanimationDuration.days}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Grid>
  )
}
