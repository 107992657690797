import { Button, List, ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React, { forwardRef } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { useAuth } from '../../../context/auth-context'
import { isRoleAmong } from '../../../utils/isRoleAmong'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.basicGrey,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,

    '& $icon': {
      color: theme.palette.basicGrey,
    },
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.light,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.light,
    },
  },
}))

const CustomRouterLink = forwardRef<any, any>((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
))

interface Props {
  className?: string
  pages: Array<NavigationPage>
  title?: string
}

/**
 * Appelé dans la sidebar
 * Affiche une liste de liens de navigation (il peut y avoir plusieurs listes dans la sidebar)
 */
export const SidebarNav: React.FC<Props> = ({ pages, className, title }) => {
  const classes = useStyles()
  const { user } = useAuth()

  const displayPages = pages.filter((page) => {
    if (page.roles) {
      return isRoleAmong(user.data?.role, page.roles)
    }
    return true
  })

  return (
    <>
      {title && <Typography component="h5">{title}</Typography>}
      <List className={clsx(classes.root, className)}>
        {displayPages.map((page) => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
              onClick={page.onClick}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
      </List>
    </>
  )
}
