import {
  Box,
  Button,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  GridSpacing,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { add } from 'date-fns'
import { startOfDay } from 'date-fns/esm'
import { Field, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import {
  loadAppointmentsAction,
  loadLastAppointmentsAction,
} from '../../../context/actions/appointment'
import { useAppContext } from '../../../context/app-context'
import { AuthContext } from '../../../context/auth-context'
import { updateAppointment } from '../../../services/appointments.service'
import { getAge } from '../../../utils/dateUtils'
import {
  ConsultationIcon,
  DayHospitalIcon,
  TeleconsultationIcon,
} from '../../assets/Icons'
import { PickerDate, PickerTime } from '../../ui/Inputfield'
import { MessageAlert } from '../../ui/MessageAlert'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  actionBar: {
    padding: '40px 25px',
  },
  patientInformation: {
    background: theme.palette.accentBackgroundColor,
    color: theme.palette.info.dark,
    fontSize: 18,
    marginLeft: '-26px',
    '& h3': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  patientInformationText: {
    textAlign: 'left',
    '& h4': {
      color: theme.palette.info.dark,
      fontWeight: theme.typography.fontWeightRegular,
      paddingLeft: theme.spacing(4),
      lineHeight: '28px',
      '&:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  columnTitle: {
    color: theme.palette.basicGrey,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
  },
}))

const schema = Yup.object().shape({
  date: Yup.date().required(i18n.patients.actionDialog.eventDateRequired),
  time: Yup.date().required(i18n.patients.actionDialog.eventDateRequired),
  appointmentType: Yup.string().required(
    i18n.patients.actionDialog.titleRequired,
  ),
})

export const AppointmentActionForm: React.FC<{
  onClose: () => void
  data: any
}> = ({ onClose, data }) => {
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const { dispatch } = useAppContext()

  const [spacing] = useState<GridSpacing>(2)
  const [loading, setLoading] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  return (
    <>
      {showError && (
        <MessageAlert
          message={i18n.dashboard.table.appointment.dialog.errorMessage}
          title={i18n.dashboard.table.appointment.dialog.errorTitle}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}
      <Formik<{
        date: Date
        time: Date
        appointmentType: AppointmentType
      }>
        initialValues={{
          date: new Date(),
          time: new Date(),
          appointmentType: 'Téléconsultation',
        }}
        validationSchema={schema}
        onSubmit={async (values) => {
          if (!user.data?.id) {
            return
          }

          setShowError(false)
          setLoading(true)

          const appointmentDate = add(startOfDay(values.date), {
            hours: values.time.getHours(),
            minutes: values.time.getMinutes(),
          })

          try {
            const updateAppointmentValues: UpdateAppointment = {
              doctor: user.data.id,
              handledDate: new Date(),
              appointmentDate,
              type: values.appointmentType,
            }
            await updateAppointment(data.id, updateAppointmentValues)
            await dispatch(loadAppointmentsAction())
            await dispatch(loadLastAppointmentsAction())
            onClose()
          } catch (e) {
            console.error(e)
            // TODO: DISPLAY ERROR MESSAGE
            setShowError(true)
          }

          setLoading(false)
        }}
      >
        {({ values, errors, handleBlur, handleSubmit, setFieldValue }) => (
          <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <Box className={classes.patientInformation}>
                  <Box display="flex" justifyContent="center" pt={2} mb={2}>
                    <Typography variant="h3" className={classes.columnTitle}>
                      {i18n.dashboard.table.appointment.dialog.contact.toUpperCase()}
                    </Typography>
                  </Box>
                  <Box className={classes.patientInformationText} pt={3} pb={6}>
                    <Typography variant="h4">
                      {data.lastName.toUpperCase()}&nbsp;
                      {data.firstName}
                    </Typography>
                    <Typography variant="h4">
                      {getAge(data.birthDate)}
                    </Typography>
                    <br />
                    <Typography variant="h4">{data.phoneNumber}</Typography>
                    <Typography variant="h4">{data.email}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.patientForm} pl={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    mb={2}
                    style={{ paddingTop: 12 }}
                  >
                    <Typography variant="h3" className={classes.columnTitle}>
                      {i18n.dashboard.table.appointment.dialog.appointment.toUpperCase()}
                    </Typography>
                  </Box>
                  <Grid container spacing={spacing}>
                    <Grid item xs={6}>
                      <Field
                        name="date"
                        label={i18n.patients.actionDialog.labelDate}
                        type="date"
                        onBlur={handleBlur}
                        component={PickerDate}
                        value={values.date}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="time"
                        label={i18n.patients.actionDialog.labelDate}
                        type="date"
                        onBlur={handleBlur}
                        component={PickerTime}
                        value={values.time}
                      />
                    </Grid>
                    <Box
                      display="flex"
                      alignItems="center"
                      pl={2}
                      pt={1}
                      width={1}
                    >
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="appointmentType"
                          name="appointmentType"
                          value={values.appointmentType}
                          onChange={(e) =>
                            setFieldValue('appointmentType', e.target.value)
                          }
                        >
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              value="Téléconsultation"
                              control={<Radio color="primary" />}
                              label={<TeleconsultationIcon />}
                            />
                            <Typography>Téléconsultation</Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              value="Consultation"
                              control={<Radio color="primary" />}
                              label={<ConsultationIcon />}
                            />
                            <Typography>Consultation</Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              value="H. de jour"
                              control={<Radio color="primary" />}
                              label={<DayHospitalIcon />}
                            />
                            <Typography>Hospitalisation de jour</Typography>
                          </Box>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={1}
              >
                <DialogActions className={classes.actionBar}>
                  <Button
                    onClick={onClose}
                    color="primary"
                    variant="contained"
                    size="large"
                  >
                    {i18n.button.return}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={
                      loading ||
                      Boolean(errors.date) ||
                      Boolean(errors.time) ||
                      Boolean(errors.appointmentType)
                    }
                  >
                    {i18n.button.validateAppointment}
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}
