import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { format } from 'date-fns'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { i18n } from '../../../assets/i18n'
import { loadDoctorsAction } from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import { settings } from '../../../theme/settings'
import { useUserTableStyles } from '../../../theme/styles/userTableStyles'
import { DATA_TABLE_OPTIONS } from '../../../utils/dataTableOptions'
import { formatDoctorLines } from '../../../utils/dataTableUtils'
import { AddPersonIcon } from '../../assets/Icons'
import { HeroBanner } from '../../ui/HeroBanner'
import { HeroCounterElements } from '../../ui/HeroCounterElements'
import { LoadingPane } from '../../ui/LoadingPane'

/**
 * Liste des médecins avec leurs information
 * Permet d'accéder à la création et la modification de médecin
 */
export const Doctors = () => {
  const classes = useUserTableStyles()
  const { doctors, dispatch } = useAppContext()

  const [lines, setLines] = useState<Array<DoctorLine>>(
    doctors.data ? formatDoctorLines(doctors.data) : [],
  )

  useEffect(() => {
    if (!doctors.data) {
      dispatch(loadDoctorsAction())
    }
  }, [dispatch, doctors.data])

  useEffect(() => {
    setLines(doctors.data ? formatDoctorLines(doctors.data) : [])
  }, [doctors.data])

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastName',
      label: i18n.doctors.table.headers.lastName,
      options: {
        customBodyRender: (value: any) => {
          return <span className={classes.customCellLastName}>{value}</span>
        },
      },
    },
    {
      name: 'firstName',
      label: i18n.doctors.table.headers.firstName,
      options: {
        customBodyRender: (value: any) => {
          return <span className={classes.customCellRender}>{value}</span>
        },
      },
    },
    {
      name: 'email',
      label: i18n.doctors.table.headers.email,
      options: {
        customBodyRender: (value: string) => (
          <span className={classes.nowrap}>{value}</span>
        ),
      },
    },
    {
      name: 'hospitalDepartments',
      label: i18n.doctors.table.headers.hospitalDepartments,
      options: {
        filter: false,
        sort: false,
        empty: true,
      },
    },
    {
      name: '', // Bouton 'modifier'
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <Link
              component="button"
              onClick={() => handleOnEditClick(tableMeta.rowData[0])}
              style={{
                color: settings.theme.palette.primary.light,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
              }}
            >
              {i18n.links.modification} <KeyboardArrowRight />
            </Link>
          )
        },
      },
    },
  ]

  if (doctors.state === 'loading') {
    return <LoadingPane />
  }

  const handleClickOnFloatingButton = () => {
    browserHistory.push('/admin/doctors/create')
  }

  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/admin/doctors/edit/${id}`)
  }

  return (
    <Grid container justify="center" className={classes.root}>
      {/* HEADER */}
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.doctors.doctorList}
      />

      {/* TITLE & ADD BUTTON */}
      <Box className={classes.titleContainer}>
        <HeroCounterElements
          startString={i18n.surveyBanner.surveyNumberStart}
          counter={lines.length}
          counterDesc={i18n.doctors.doctors}
          endString={i18n.patientBanner.patientInside}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddPersonIcon alt={i18n.doctors.create.doctor} />}
          onClick={handleClickOnFloatingButton}
        >
          {i18n.doctors.create.doctor}
        </Button>
      </Box>

      {/* DATA TABLE */}
      <Grid item xs={12}>
        <div className={classes.tableContainer}>
          <MUIDataTable
            title=""
            columns={columns}
            data={lines}
            options={{
              ...DATA_TABLE_OPTIONS,
              downloadOptions: {
                filename: `${i18n.dataTables.doctors.title}-${format(
                  new Date(),
                  'yyyy-MM-dd',
                )}.csv`,
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  )
}
