export const isHospitalDoctor = (doctor: Doctor): doctor is HospitalDoctor => {
  return (
    doctor.role === 'hospitalDoctor' && doctor.hospitalDepartments !== undefined
  )
}

export const isCityDoctor = (doctor: Doctor): doctor is CityDoctor => {
  return doctor.role === 'cityDoctor'
}

export const isCreateHospitalDoctorFormValues = (
  createDoctor: CreateDoctorFormValues,
): createDoctor is CreateHospitalDoctorFormValues => {
  return (
    createDoctor.role === 'hospitalDoctor' &&
    createDoctor.hospitalDepartments !== undefined
  )
}

export const isUpdateHospitalDoctorFormValues = (
  updateDoctor: UpdateDoctorFormValues,
): updateDoctor is UpdateHospitalDoctorFormValues => {
  return (
    updateDoctor.role === 'hospitalDoctor' &&
    updateDoctor.hospitalDepartments !== undefined
  )
}

export const isCreateCityDoctorFormValues = (
  createDoctor: CreateDoctorFormValues,
): createDoctor is CreateCityDoctorFormValues => {
  return (
    createDoctor.role === 'cityDoctor' &&
    createDoctor.hospitalDepartments === undefined
  )
}

export const isUpdateCityDoctorFormValues = (
  updateDoctor: UpdateDoctorFormValues,
): updateDoctor is UpdateCityDoctorFormValues => {
  return (
    updateDoctor.role === 'cityDoctor' &&
    updateDoctor.hospitalDepartments === undefined
  )
}
