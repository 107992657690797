import { Api } from './api'

export async function getUser(id: string) {
  const response = await Api.get<ApiUser>(`/users/${id}`)
  return response.data
}

export async function createUser(user: ApiUserToCreate) {
  const response = await Api.post<ApiUser>(`/users`, user)
  return response.data
}

export async function updateUser(user: ApiUserToUpdate) {
  const response = await Api.put<ApiUser>(`/users/${user._id}`, user)
  return response.data
}

export async function sendPasswordMessage(userId: string) {
  const response = await Api.post<SendPasswordMessageResponse>(
    `/users/${userId}/send-password`,
  )
  return response.data
}
