import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { ChuTheme } from '../../@types/theme'

const dialogStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    '& h2': {
      fontSize: 24,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: '28px',
      marginTop: theme.spacing(6),
    },
  },
  icon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-130px',
  },
  dialogContainer: {
    backgroundColor: theme.palette.white,
    borderRadius: 10,
  },
  dialogInner: {
    margin: 'auto',
  },
}))
export const DialogFormAction = ({
  open,
  onClose,
  onExited,
  icon,
  title,
  children,
  maxWidth,
}: {
  open: boolean
  onClose: () => void
  onExited?: () => void
  icon: JSX.Element
  title: string
  children: React.ReactNode
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}) => {
  const classes = dialogStyles()
  const [fullWidth] = useState(true)

  return (
    <Dialog
      onClose={onClose}
      onExited={onExited}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          position: 'relative',
          overflowY: 'visible',
        },
      }}
      classes={{ root: classes.root }}
    >
      <Box display="flex" justifyContent="center" className={classes.icon}>
        {icon}
      </Box>
      <Box className={classes.dialogContainer}>
        <Box className={classes.dialogInner}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
        </Box>
      </Box>
    </Dialog>
  )
}
