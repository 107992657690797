import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { useAuth, WRONG_ROLE_ERROR } from '../../../context/auth-context'
import { useAuthFormStyles } from '../../../theme/styles/authFormStyles'
import { AuthFormLayout } from '../../layouts/AuthFormLayout'
import { InputField } from '../../ui/Inputfield'
import { PasswordFieldWithVisibility } from '../../ui/PasswordFieldWithVisiblity'
import { SubmitButton } from '../../ui/SubmitButton'

const schema = Yup.object().shape({
  username: Yup.string().required(i18n.auth.signin.username.required),
  password: Yup.string().required(i18n.auth.signin.password.required),
})

/**
 * Ecran de connexion
 */
export const SignIn = () => {
  const auth = useAuth()
  const history = useHistory()
  const classes = useAuthFormStyles()
  const [showCredentialsError, setShowCredentialsError] = useState(false)
  const [showRoleError, setShowRoleError] = useState(false)

  const goToForgottenPassword = () => {
    history.push('/forgotten-password')
  }

  const onSubmit = async (values: LoginFormValues) => {
    try {
      await auth.signIn(values)
      history.push('/otp-validation')
    } catch (error) {
      console.error(error)
      if (error.toString().includes(WRONG_ROLE_ERROR)) {
        setShowRoleError(true)
      } else {
        setShowCredentialsError(true)
      }
    }
  }

  const alertMessages: Array<AlertMessage> = [
    {
      showAlert: showCredentialsError,
      severity: 'error',
      title: i18n.auth.signin.errors.wrongInputs.title,
      message: i18n.auth.signin.errors.wrongInputs.message,
      onClose: () => setShowCredentialsError(false),
    },
    {
      showAlert: showRoleError,
      severity: 'error',
      title: i18n.auth.signin.errors.wrongRole.title,
      message: i18n.auth.signin.errors.wrongRole.message,
      onClose: () => setShowRoleError(false),
    },
    {
      showAlert: auth.resetPassword.status === 'success',
      severity: 'success',
      title: i18n.auth.forgottenPassword.reset.success.title,
      message: i18n.auth.forgottenPassword.reset.success.message,
    },
    {
      showAlert: auth.sessionRevokedError,
      severity: 'error',
      title: i18n.auth.signin.revokedSessionError.title,
      message: i18n.auth.signin.revokedSessionError.message,
      onClose: () => auth.hideRevokedSessionError(),
      persist: true,
    },
  ]

  return (
    <AuthFormLayout
      title={i18n.auth.signin.title}
      alertMessages={alertMessages}
    >
      <Formik<LoginFormValues>
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              name="username"
              label={i18n.auth.signin.username.label}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              component={InputField}
            />
            <Field
              name="password"
              label={i18n.auth.signin.password.label}
              onChange={handleChange}
              onBlur={handleBlur}
              component={PasswordFieldWithVisibility}
            />

            <Typography
              component="p"
              className={classes.textLink}
              onClick={goToForgottenPassword}
            >
              {i18n.auth.signin.forgottenPassword.label}
            </Typography>

            <Box className={classes.buttonsContainer}>
              <SubmitButton
                title={i18n.auth.signin.button}
                disabled={
                  !dirty ||
                  !!errors.username ||
                  !!errors.password ||
                  isSubmitting
                }
              />

              {auth.user.state === 'loading' && (
                <CircularProgress size={24} className={classes.loadingIcon} />
              )}
            </Box>
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
