import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { loadFormsAction } from '../../../context/actions/survey'
import { useAppContext } from '../../../context/app-context'
import { useAuth } from '../../../context/auth-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import { getFractionalScore } from '../../../utils/getFractionalScore'
import { FormImage } from '../../assets/Images'
import { HeroBanner } from '../../ui/HeroBanner'
import LoadingPane from '../../ui/LoadingPane'
import { QuestionMultipleAnswer } from './QuestionMultipleAnswer'
import { QuestionSingleAnswer } from './QuestionSingleAnswer'
import { QuestionSubQuestions } from './QuestionSubQuestions'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(5),
  },
  full: {
    width: '100%',
    paddingBottom: theme.spacing(4),
  },
  listTitle: {
    background: theme.palette.listHeaderBackground,
  },
}))

/**
 * Affiche les résultats d'un form répondu par un patient
 * C'est à dire les réponses aux questions de tous les questionnaires (surveys) dans un form
 */
export const FormResult = () => {
  const classes = useStyles()
  const { dispatch, forms } = useAppContext()
  const { user } = useAuth()
  const { id } = useParams<{ id?: string }>()
  const [selectedFormResult, setSelectedFormResult] = useState<
    Form | undefined
  >(undefined)

  useEffect(() => {
    if (!forms.data) {
      dispatch(loadFormsAction())
    }
  }, [dispatch, forms.data])

  useEffect(() => {
    if (id) {
      const newSelectedFormResult = forms.data?.find((form) => form.id === id)
      if (newSelectedFormResult) {
        setSelectedFormResult(newSelectedFormResult)
      }
    }
  }, [dispatch, forms.data, id])

  if (!selectedFormResult) {
    return <LoadingPane />
  }

  if (!selectedFormResult) {
    return <LoadingPane />
  }

  // récupère l'avant dernier questionnaire rempli par le patient sachant que le tableau renvoyé est trié par ordre descendant
  const lastFormResult = forms.data?.filter(
    (form) => form.formId === selectedFormResult.formId,
  )[1]

  const patientName =
    selectedFormResult.results.length > 0
      ? user.data?.role === 'inspector'
        ? selectedFormResult.results[0].codeId
        : selectedFormResult.results[0].userFullName
      : ''

  return (
    <Box className={classes.root}>
      <HeroBanner
        imgPath={images.surveyList}
        title={
          i18n.surveyBanner.surveyResponse +
          ' ' +
          selectedFormResult.technicalName
        }
        goBack={browserHistory.goBack}
        goBackTitle={i18n.surveyBanner.backToSurvey}
        patientName={patientName}
      />

      {lastFormResult && (
        <Box className={classes.container}>
          <Typography variant="h4" className={classes.title}>
            Résultat du précédent questionnaire
          </Typography>
          {lastFormResult?.results.map((result, i) => (
            <Typography variant="h5" key={i}>
              {result.name} - Score : {getFractionalScore(result.score)}
            </Typography>
          ))}
        </Box>
      )}

      {selectedFormResult.results.map((result, index) => {
        const score = result.score?.value
          ? `(${i18n.formResult.score} ${getFractionalScore(result.score)})`
          : i18n.formResult.noScore
        const headerText = `${i18n.surveyBanner.surveyNamePrefix} ${result.name} ${score}`

        return (
          <Grid
            container
            className={classes.container}
            spacing={3}
            key={`step-${index}`}
          >
            <Paper className={classes.full}>
              <List disablePadding={true}>
                {/* HEADER */}
                <ListItem className={classes.listTitle}>
                  <ListItemAvatar>
                    <FormImage />
                  </ListItemAvatar>
                  <ListItemText primary={headerText} />
                </ListItem>

                {/* ANSWERS */}
                {result.questions.map((question, idx) => {
                  if (question.type === 'top') {
                    return (
                      <QuestionSubQuestions
                        key={`question-${question.type}-${idx}`}
                        question={question}
                      />
                    )
                  } else if (question.type === 'multiple') {
                    return (
                      <QuestionMultipleAnswer
                        key={`question-${question.type}-${idx}`}
                        question={question}
                      />
                    )
                  } else {
                    return (
                      <QuestionSingleAnswer
                        key={`question-${question.type}-${idx}`}
                        counter={idx}
                        question={question}
                      />
                    )
                  }
                })}
              </List>
            </Paper>
          </Grid>
        )
      })}
    </Box>
  )
}
