import {
  Avatar,
  Box,
  createStyles,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import React from 'react'

interface Props {
  question: MultipleAnswer
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    inline: {
      display: 'inline',
      color: theme.palette.info.dark,
      fontWeight: theme.typography.fontWeightRegular,
    },
    result: {
      color: theme.palette.secondary.light,
    },
    item: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.light,
      fontSize: 16,
      width: 32,
      height: 32,
    },
    spaceDivider: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  }),
)

const indexToChar = (i: any) => {
  return String.fromCharCode(i + 97)
}

/**
 * Appelé dans <FormResult />
 * Affiche les réponses à une question de type "multiple"
 */
export const QuestionMultipleAnswer: React.FC<Props> = ({ question }) => {
  const classes = useStyles()

  const results = question.question
    .map((answerId, index) => ({
      answerId,
      answer: question.answers[index],
    }))
    .filter(({ answer }) => answer)
    .sort((a, b) => a.answerId - b.answerId)

  return (
    <>
      {results.map(({ answerId, answer }, index) => {
        return (
          <Box key={`answer-${index}`}>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.item}>
                  {question.number}
                  {indexToChar(answerId)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="h5"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    {answer}
                  </Typography>
                }
              />
            </ListItem>
            <Divider component="li" className={classes.spaceDivider} />
          </Box>
        )
      })}
    </>
  )
}
