import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface Props {
  title: string
  onClick: () => void
  marginRight?: boolean
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: 16,

    marginRight: (props) => (props.marginRight ? theme.spacing(2) : undefined),

    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

/**
 * Bouton retour
 */
export const PreviousButton: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { title, onClick } = props

  return (
    <Button
      size="large"
      variant="contained"
      color="primary"
      className={classes.root}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}
