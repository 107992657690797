import { Box } from '@material-ui/core'
import clsx from 'clsx'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { useAuth } from '../../../context/auth-context'
import { useAuthFormStyles } from '../../../theme/styles/authFormStyles'
import { AuthFormLayout } from '../../layouts/AuthFormLayout'
import { PasswordFieldWithVisibility } from '../../ui/PasswordFieldWithVisiblity'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'

const schema = Yup.object().shape({
  password: Yup.string().required(
    i18n.auth.forgottenPassword.reset.password.required,
  ),
  confirmPassword: Yup.string()
    .required(i18n.auth.forgottenPassword.reset.confirmPassword.required)
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        i18n.auth.forgottenPassword.reset.notTheSamePassword,
      ),
    }),
})

/**
 * Appelé depuis ForgottenPassword lorsqu'on a un token
 * Formulaire de modification de mot de passe (password + confirmPassword)
 */
export const ForgottenPasswordReset = () => {
  const { token } = useParams<{ token?: string }>()
  const history = useHistory()
  const auth = useAuth()
  const classes = useAuthFormStyles()

  const [showError, setShowError] = useState(false)

  const goBack = () => {
    history.push('/')
  }
  const onSubmit = async (values: ForgottenPasswordResetFormValues) => {
    try {
      const sendToApi: ForgottenPasswordResetFApiValues = {
        token: token || '',
        newPassword: values.password,
        confirmNewPassword: values.confirmPassword,
      }
      await auth.resetForgottenPassword(sendToApi)
      history.push('/')
    } catch (error) {
      setShowError(true)
    }
  }

  const alertMessages: Array<AlertMessage> = [
    {
      showAlert: showError,
      severity: 'error',
      title: i18n.auth.forgottenPassword.reset.errors.title,
      message: i18n.auth.forgottenPassword.reset.errors.message,
      onClose: () => setShowError(false),
    },
  ]

  return (
    <AuthFormLayout
      title={i18n.auth.forgottenPassword.title}
      subtitle={i18n.auth.forgottenPassword.subtitle}
      alertMessages={alertMessages}
    >
      <Formik<ForgottenPasswordResetFormValues>
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              name="password"
              label={i18n.auth.forgottenPassword.reset.password.label}
              onChange={handleChange}
              onBlur={handleBlur}
              component={PasswordFieldWithVisibility}
            />
            <Field
              name="confirmPassword"
              label={i18n.auth.forgottenPassword.reset.confirmPassword.label}
              onChange={handleChange}
              onBlur={handleBlur}
              component={PasswordFieldWithVisibility}
            />

            <Box
              className={clsx(
                classes.buttonsContainer,
                classes.buttonsContainerSpacing,
              )}
            >
              <PreviousButton
                title={i18n.auth.forgottenPassword.reset.return}
                onClick={goBack}
                marginRight
              />

              <SubmitButton
                title={i18n.auth.forgottenPassword.reset.button}
                disabled={
                  !dirty ||
                  !!errors.password ||
                  !!errors.confirmPassword ||
                  isSubmitting
                }
              />
            </Box>
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
