import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { loadPatientMoodAction } from '../../../../context/actions/mood'
import { useAppContext } from '../../../../context/app-context'
import { settings } from '../../../../theme/settings'
import { dateToString } from '../../../../utils/dateUtils'
import {
  CloudWhiteIcon,
  MoodUnknownIcon,
  RainWhiteIcon,
  SunWhiteIcon,
} from '../../../assets/Icons'
import { BlocIcons } from './BlocIcons'
import { MoodsIndicatorCard } from './MoodIndicatorCard'

const moodStyles = makeStyles<ChuTheme>((theme) => ({
  roots: {
    position: 'relative',
    '& h1, h4, p': {
      color: theme.palette.white,
    },
  },
  contentBloc: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 222,
    marginTop: 170,
    marginLeft: 30,
    marginRight: 30,
    borderRadius: 8,
    justifyContent: 'center',
    background: theme.palette.linearSuccessBackground,
  },
  boxIcon: {
    '& img': {
      position: 'absolute',
      top: '-130px',
      left: 'calc(50% + -70px)',
      right: 0,
    },
    '& h3': {
      color: '#fff !important',
      textTransform: 'uppercase',
      fontSize: '1vw',
    },
  },
  undefined: {
    background: theme.palette.linearUndefinedMoodBackground,
  },
  good: {
    background: theme.palette.linearSuccessBackground,
  },
  medium: {
    background: theme.palette.linearWarningBackground,
  },
  bad: {
    background: theme.palette.linearDangerBackground,
  },
  date: {
    textAlign: 'center',
  },
  moodIcon: {
    color: theme.palette.white,
    marginLeft: 5,
    '& svg': { fontSize: '1.8vw' },
  },
}))

export const PatientMood = () => {
  const classes = moodStyles()
  const { dispatch, patientMoods } = useAppContext()
  const { id } = useParams<{ id: string }>()
  const moods = patientMoods?.data

  useEffect(() => {
    dispatch(loadPatientMoodAction(id))
  }, [dispatch, id])

  const lastMood = moods?.slice(-1)[0]
  return (
    <>
      <Grid container className={classes.roots}>
        <Box
          className={clsx(classes.contentBloc, {
            [classes.undefined]: !lastMood?.moodValue,
            [classes.good]: lastMood?.moodValue === 'good',
            [classes.medium]: lastMood?.moodValue === 'medium',
            [classes.bad]: lastMood?.moodValue === 'bad',
          })}
        >
          <Grid item xs={12} md={4} lg={5}>
            <Box position="relative" textAlign="center" height="100%">
              <BlocIcons
                imgSrc="/images/common/images/illustration_mood.svg"
                title={i18n.patientDetails.moods.label}
                className={classes.boxIcon}
              />

              {lastMood ? (
                <Box display="flex" justifyContent="center" mt={2}>
                  <Typography
                    style={{
                      lineHeight: 1,
                      fontSize: '1.8vw',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {dateToString(new Date(lastMood.date))}
                  </Typography>

                  <Box component="span" className={classes.moodIcon}>
                    {lastMood?.moodValue === 'good' ? (
                      <SunWhiteIcon alt={lastMood?.moodValue} />
                    ) : lastMood?.moodValue === 'medium' ? (
                      <CloudWhiteIcon alt={lastMood?.moodValue} />
                    ) : (
                      <RainWhiteIcon alt={lastMood?.moodValue} />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  component="span"
                  mt={2}
                  style={{ textTransform: 'uppercase' }}
                >
                  <Typography
                    style={{
                      lineHeight: 1,
                      fontSize: '1.8vw',
                      fontWeight: 700,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: settings.theme.palette.white,
                    }}
                  >
                    {i18n.patientDetails.moods.notMoodValue}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={7}>
            <Box
              display="flex"
              flexDirection="column"
              style={{
                height: 222,
                backgroundColor: settings.theme.palette.backgroundDark,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="h4"
                style={{
                  textTransform: 'uppercase',
                  color: settings.theme.palette.info.main,
                  textAlign: 'center',
                  marginTop: '-15px',
                  marginBottom: 40,
                }}
              >
                {i18n.progression}
              </Typography>

              {patientMoods.data?.length ? (
                <MoodsIndicatorCard id={id} moods={moods} />
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  width="75%"
                  style={{ margin: '0 auto' }}
                >
                  {[...Array(4)].map((_, i) => (
                    <MoodUnknownIcon key={i} />
                  ))}
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      </Grid>
    </>
  )
}
