import { getSymptomsByHospitalDepartmentsId } from '../../services/hospitals.service'
import { fromApiSymptoms } from '../../utils'
import { createAction } from '../app-context'

export const loadSymptomsAction = createAction(
  (hospitalDepartmentId: string) => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.symptoms.state = 'loading'
      })
      const symptoms = await getSymptomsByHospitalDepartmentsId(
        hospitalDepartmentId,
      )

      await produceState((draft) => {
        draft.symptoms.data = symptoms.map((symptom) =>
          fromApiSymptoms(symptom),
        )
        draft.symptoms.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.symptoms.state = 'error'
      })
    }
  },
)
