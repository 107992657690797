import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { TimelineTooltip } from './TimelineTooltip'

interface Props {
  color: string
  header: string
  title: string
  description: string
  author?: DoctorNoteAuthor
  createdAt?: Date
  link?: string
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  entryBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  linkEntryBox: {
    cursor: 'pointer',
  },
  bottomEntryBox: {
    padding: '24px 5px 24px 5px',
  },
  contentContainer: {
    position: 'relative',
    width: 160,
    backgroundColor: theme.palette.white,
    padding: 10,
    borderRadius: 8,
    boxShadow: `0px 3px 6px ${theme.palette.shadow}`,

    // Arrow
    '&:after': {
      content: '" "',
      position: 'absolute',
      bottom: '100%',
      left: '50%',
      transform: 'translate(-50%)',
      borderWidth: 5,
      borderStyle: 'solid',
      borderColor: `transparent transparent ${theme.palette.white} transparent`,
    },

    // Hover
    '&:hover': {
      backgroundColor: theme.palette.backgroundDark,

      '&:after': {
        borderColor: `transparent transparent ${theme.palette.backgroundDark} transparent`,
      },
    },
  },
  author: {
    fontSize: 12,
    textTransform: 'capitalize',
    textAlign: 'center',
    color: (props) => props.color,
  },
  content: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.info.dark,
    lineHeight: '14px',
  },
}))

/**
 * Une des deux parties d'une entrée d'historique <TimelineEntry />
 * Affiche un visuel semblable à une tooltip dans lequel on voit de qui provient l'entrée et le titre de l'entrée
 * Une vraie tooltip <TimelineTooltip /> s'affiche en hover afin de donner des informations complémentaires sur l'entrée d'historique
 */
export const TimelineContent: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { header, title, description, author, createdAt } = props
  const authorFullName =
    author && `${author?.firstName || ''} ${author?.lastName || ''}`

  const TimelineContentBox = (
    <Box
      className={clsx(classes.entryBox, classes.bottomEntryBox, {
        [classes.linkEntryBox]: Boolean(props.link),
      })}
    >
      <TimelineTooltip
        description={description}
        author={authorFullName}
        createdAt={createdAt}
      >
        <Box className={classes.contentContainer}>
          <Typography className={classes.author}>{header}</Typography>
          <Typography className={classes.content}>{title}</Typography>
        </Box>
      </TimelineTooltip>
    </Box>
  )

  return props.link ? (
    <Link to={props.link}>{TimelineContentBox}</Link>
  ) : (
    TimelineContentBox
  )
}
