import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import clsx from 'clsx'
import { ErrorMessage, FieldProps } from 'formik'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    width: '100%',
  },
  textField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  errorMsg: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.secondary.light,
  },
  select: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  textArea: {
    '& textarea': {
      minHeight: 100,
    },
  },
  legend: {
    fontSize: 14,
    color: theme.palette.info.main,
    paddingRight: theme.spacing(2),
  },
}))

export const InputField = ({
  field,
  form: { touched, errors },
  name,
  label,
  ...props
}: FieldProps & InputFieldProps) => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <TextField
        {...field}
        {...props}
        label={label}
        variant="outlined"
        className={classes.textField}
        fullWidth
      />
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage
          name={field.name}
          component="span"
          className={classes.errorMsg}
        />
      )}
    </Box>
  )
}

interface TextAreaFieldProps {
  label?: string
}
export const TextAreaField = ({
  field,
  form: { touched, errors },
  label,
  ...props
}: FieldProps & TextAreaFieldProps) => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <TextField
        {...field}
        {...props}
        label={label}
        multiline
        margin="dense"
        variant="outlined"
        fullWidth
        className={classes.textArea}
      />
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage
          name={field.name}
          component="span"
          className={classes.errorMsg}
        />
      )}
    </Box>
  )
}

interface PickerProps {
  className?: string
  label?: string
  name: string
  value: MaterialUiPickersDate
  onChange: (fieldName: string, newValue: string) => void
}

export const PickerDate = ({
  field,
  form: { setFieldValue },
  label,
  value,
}: FieldProps & PickerProps) => {
  const classes = useStyles()
  return (
    <KeyboardDatePicker
      autoOk
      variant="inline"
      inputVariant="outlined"
      format="dd/MM/yyyy"
      orientation="landscape"
      label={label}
      InputAdornmentProps={{ position: 'start' }}
      className={clsx(classes.textField, classes.root)}
      value={value}
      onChange={(value) => {
        setFieldValue(field.name, value)
      }}
    />
  )
}

interface TimeProps {
  className?: string
  label?: string
  name: string
  value: MaterialUiPickersDate
  onChange: (fieldName: string, newValue: string) => void
}

export const PickerTime = ({
  field,
  form: { setFieldValue },
  label,
  value,
}: FieldProps & TimeProps) => {
  const classes = useStyles()
  return (
    <KeyboardTimePicker
      autoOk
      ampm={false}
      variant="inline"
      inputVariant="outlined"
      orientation="landscape"
      label={label}
      InputAdornmentProps={{ position: 'start' }}
      className={clsx(classes.textField, classes.root)}
      value={value}
      onChange={(value) => {
        setFieldValue(field.name, value)
      }}
    />
  )
}

interface SelectProps {
  className?: string
  label?: string
  name: string
  options: Array<{ id: string; name: string }>
  value: string
  onChange: (fieldName: string, newValue: string) => void
}

export const SelectField = ({
  field,
  form: { setFieldValue },
  label,
  value,
  options,
  ...props
}: FieldProps & SelectProps) => {
  const classes = useStyles()

  return (
    <FormControl className={clsx(classes.textField, classes.root)}>
      {label && <InputLabel style={{ paddingLeft: 16 }}>{label}</InputLabel>}
      <Select
        classes={{ root: classes.select }}
        {...props}
        label={label}
        margin="dense"
        variant="outlined"
        fullWidth
        onChange={(option) => {
          setFieldValue(field.name, option.target.value)
        }}
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

interface RadioFieldProps {
  legend: string
  name: string
  options: any
}

export const FormikRadioGroup = ({
  field,
  form: { touched, errors },
  legend,
  name,
  options,
  ...props
}: FieldProps & RadioFieldProps) => {
  const classes = useStyles()
  const fieldName = name || field.name
  return (
    <>
      <Box display="inline-flex" width={1}>
        <Grid container style={{ display: 'flex', alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.legend}>
              {legend}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              {...field}
              {...props}
              name={fieldName}
              style={{ display: 'flex', flexFlow: 'row' }}
            >
              {options.map((option: any, index: any) => (
                <React.Fragment key={index}>
                  <FormControlLabel
                    value={option}
                    control={<Radio color="primary" />}
                    label={option}
                  />
                </React.Fragment>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      </Box>
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage
          name={field.name}
          component="span"
          className={classes.errorMsg}
        />
      )}
    </>
  )
}
