import {
  Box,
  Button,
  DialogActions,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { sendPasswordMessage } from '../../../../services/users.service'
import { dateToString } from '../../../../utils/dateUtils'
import {
  CheckIcon,
  CrossIcon,
  DialogPatientMonitoring,
} from '../../../assets/Icons'
import { SIDEBAR_WIDTH } from '../../../layouts/Sidebar/Sidebar'
import { DialogFormAction } from '../../../ui/DialogFormAction'
import { MessageAlert } from '../../../ui/MessageAlert'

interface Props {
  patient: Patient
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  patientAccount: {
    '& h4': {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  messageAlertContainer: {
    position: 'fixed',
    left: SIDEBAR_WIDTH,
    right: 0,
    top: 0,
    zIndex: 1101,
  },
  active: {
    '& h4': {
      color: theme.palette.success.main,
      marginLeft: 10,
      '&:first-child': {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    display: 'flex',
    alignItems: 'flex-start',
  },
  inactiveContainer: {
    width: '100%',
  },
  inactiveTextContainer: {
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    '& h4': {
      color: theme.palette.secondary.main,
      marginLeft: 10,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  actionBar: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 25px',
  },
}))

export const ActivatedAtBloc: React.FC<Props> = ({ patient }) => {
  const classes = useStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)

  const handleSendPasswordMessage = async () => {
    setLoading(true)
    try {
      await sendPasswordMessage(patient.id)
      setShowSuccess(true)
    } catch (e) {
      setShowError(true)
    }
    setOpen(false)
    setLoading(false)
  }

  const handleClose = () => setOpen(false)

  return (
    <Box className={classes.patientAccount} mt={2}>
      <Box className={classes.messageAlertContainer}>
        {showError && (
          <MessageAlert
            title={i18n.doctors.patient.sendPasswordMessage.error.title}
            message={i18n.doctors.patient.sendPasswordMessage.error.message}
            severity="error"
            autoClose={true}
            onClose={() => setShowError(false)}
          />
        )}

        {showSuccess && (
          <MessageAlert
            title={i18n.doctors.patient.sendPasswordMessage.success.title}
            message={i18n.doctors.patient.sendPasswordMessage.success.message}
            severity="success"
            autoClose={true}
            onClose={() => setShowSuccess(false)}
          />
        )}
      </Box>

      {patient.activatedAt ? (
        <Box className={classes.active}>
          <CheckIcon alt={i18n.doctors.patient.account} />
          <Box>
            <Typography variant="h4">{i18n.doctors.patient.account}</Typography>
            <Typography variant="h4">
              {i18n.doctors.patient.since}{' '}
              {dateToString(new Date(patient.activatedAt))}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box className={classes.inactiveContainer}>
          <Box display="flex" className={classes.inactiveTextContainer}>
            <CrossIcon alt={i18n.doctors.patient.inactive} />
            <Typography variant="h4">
              {i18n.doctors.patient.inactive}
            </Typography>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={() => setOpen(true)}
          >
            {i18n.doctors.patient.sendPasswordMessage.title}
          </Button>
        </Box>
      )}

      {/* MODALE DE CONFIRMATION */}
      <DialogFormAction
        open={open}
        onClose={handleClose}
        icon={<DialogPatientMonitoring />}
        title={i18n.doctors.patient.sendPasswordMessage.title}
      >
        <Typography>
          {i18n.doctors.patient.sendPasswordMessage.description}
        </Typography>

        <DialogActions className={classes.actionBar}>
          <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            size="large"
          >
            {i18n.doctors.patient.sendPasswordMessage.cancel}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            size="large"
            onClick={handleSendPasswordMessage}
            disabled={loading}
          >
            {i18n.doctors.patient.sendPasswordMessage.confirm}
          </Button>
        </DialogActions>
      </DialogFormAction>
    </Box>
  )
}
