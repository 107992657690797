import { getFullName } from './getFullName'
import { getInitials } from './getInitials'
import { isRoleAmong } from './isRoleAmong'

const fromApiUser = (user: ApiUser): User => {
  const fullName = getFullName(user.firstName, user.lastName)
  return {
    id: user._id,
    email: user.email || '',
    socialSecurityNumber: user.socialSecurityNumber || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    fullName,
    initials: getInitials(fullName),
    role: user.role,
    otpRequired: user.otpRequired,
    otpConfirmed: user.otpConfirmed,
    lastPasswordUpdate: user.lastPasswordUpdate
      ? new Date(user.lastPasswordUpdate)
      : undefined,
    lastConnections: user.lastConnections
      ? user.lastConnections.map((dateString) => new Date(dateString))
      : [],
  }
}

const fromApiUsers = (users: ApiUser[]): User[] => {
  return users.reduce<User[]>((arrayOfUsers, apiUser) => {
    const fullName = getFullName(apiUser.firstName, apiUser.lastName)

    arrayOfUsers.push({
      id: apiUser._id,
      email: apiUser.email || '',
      lastName: apiUser.lastName || '',
      socialSecurityNumber: apiUser.socialSecurityNumber || '',
      firstName: apiUser.firstName || '',
      fullName,
      initials: getInitials(fullName),
      role: apiUser.role,
      lastPasswordUpdate: apiUser.lastPasswordUpdate
        ? new Date(apiUser.lastPasswordUpdate)
        : undefined,
      lastConnections: apiUser.lastConnections
        ? apiUser.lastConnections.map((dateString) => new Date(dateString))
        : [],
    })
    return arrayOfUsers
  }, [])
}

const fromApiAnyUser = (anyUser: ApiAnyUser): AnyUser => {
  const fullName = getFullName(anyUser.firstName, anyUser.lastName)

  return {
    id: anyUser._id,
    codeId: anyUser.codeId,
    email: anyUser.email || '',
    socialSecurityNumber: anyUser.socialSecurityNumber || '',
    activatedAt: anyUser.activatedAt
      ? new Date(anyUser.activatedAt)
      : undefined,
    firstName: anyUser.firstName || '',
    lastName: anyUser.lastName || '',
    fullName,
    initials: getInitials(fullName),
    role: anyUser.role,
    otpRequired: anyUser.otpRequired,
    otpConfirmed: anyUser.otpConfirmed,
    doctors: anyUser.doctors,
    hospital:
      typeof anyUser.hospital === 'string'
        ? anyUser.hospital
        : anyUser.hospital && fromApiHospital(anyUser.hospital),
    phoneNumber: anyUser.phoneNumber,
    inHospitalDate: anyUser.inHospitalDate,
    outHospitalDate: anyUser.outHospitalDate,
    birthDate: anyUser.birthDate,
    hospitalDepartments:
      anyUser.hospitalDepartments &&
      anyUser.hospitalDepartments.map(fromApiHospitalDepartment),
    lastPasswordUpdate: anyUser.lastPasswordUpdate
      ? new Date(anyUser.lastPasswordUpdate)
      : undefined,
    lastConnections: anyUser.lastConnections
      ? anyUser.lastConnections.map((dateString) => new Date(dateString))
      : [],
  }
}

const toApiUserToCreate = (user: UserToCreate): ApiUserToCreate => ({
  password: user.password,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  socialSecurityNumber: user.socialSecurityNumber
    ? user.socialSecurityNumber
    : undefined,
  role: user.role,
})

const toApiUserToUpdate = (user: UserToUpdate): ApiUserToUpdate => ({
  _id: user.id,
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  socialSecurityNumber: user.socialSecurityNumber
    ? user.socialSecurityNumber
    : undefined,
  role: user.role,
})

const toApiPatientToCreate = (
  patient: PatientToCreate,
): ApiPatientToCreate => ({
  codeId: patient.codeId,
  email: patient.email,
  password: patient.password,
  firstName: patient.firstName,
  lastName: patient.lastName,
  socialSecurityNumber: patient.socialSecurityNumber
    ? patient.socialSecurityNumber
    : undefined,
  role: 'patient',
  doctors: patient.doctors,
  cityDoctor: patient.cityDoctor ? patient.cityDoctor : undefined,
  hospitalDepartment: patient.hospitalDepartment,
  inHospitalDate: new Date(patient.inHospitalDate).toISOString(),
  outHospitalDate: new Date(patient.outHospitalDate).toISOString(),
  birthDate: new Date(patient.birthDate).toISOString(),
  phoneNumber: patient.phoneNumber,
  symptoms: patient.symptoms,
})

const toApiPatientToUpdate = (
  patient: PatientToUpdate,
): ApiPatientToUpdate => ({
  _id: patient.id,
  codeId: patient.codeId,
  email: patient.email,
  firstName: patient.firstName,
  lastName: patient.lastName,
  socialSecurityNumber: patient.socialSecurityNumber
    ? patient.socialSecurityNumber
    : undefined,
  doctors: patient.doctors,
  cityDoctor: patient.cityDoctor ? patient.cityDoctor : undefined,
  hospitalDepartment: patient.hospitalDepartment,
  inHospitalDate: new Date(patient.inHospitalDate).toISOString(),
  outHospitalDate: new Date(patient.outHospitalDate).toISOString(),
  birthDate: new Date(patient.birthDate).toISOString(),
  phoneNumber: patient.phoneNumber,
  role: 'patient',
  symptoms: patient.symptoms,
})

export const fromApiHospitalDepartment = (
  hospitalDepartment: ApiHospitalDepartment,
): HospitalDepartment => ({
  id: hospitalDepartment._id,
  name: hospitalDepartment.name,
  hospital:
    typeof hospitalDepartment.hospital === 'string'
      ? hospitalDepartment.hospital
      : fromApiHospital(hospitalDepartment.hospital),
})

const toApiDoctorToUpdate = (doctor: DoctorToUpdate): ApiDoctorToUpdate => ({
  _id: doctor.id,
  firstName: doctor.firstName,
  lastName: doctor.lastName,
  email: doctor.email,
  phoneNumber: doctor.phoneNumber,
  address: doctor.address,
  socialSecurityNumber: doctor.socialSecurityNumber
    ? doctor.socialSecurityNumber
    : undefined,
  hospitalDepartments: doctor.hospitalDepartments,
  role: doctor.role,
})

const toApiDoctorToCreate = (doctor: DoctorToCreate): ApiDoctorToCreate => ({
  password: doctor.password,
  firstName: doctor.firstName,
  lastName: doctor.lastName,
  email: doctor.email,
  phoneNumber: doctor.phoneNumber ? doctor.phoneNumber : undefined,
  address: doctor.address ? doctor.address : undefined,
  socialSecurityNumber: doctor.socialSecurityNumber
    ? doctor.socialSecurityNumber
    : undefined,
  hospitalDepartments: doctor.hospitalDepartments,
  role: doctor.role,
})

const toApiInspectorToCreate = (
  patient: InspectorToCreate,
): ApiInspectorToCreate => ({
  email: patient.email,
  password: patient.password,
  firstName: patient.firstName,
  lastName: patient.lastName,
  role: 'inspector',
})

const toApiInspectorToUpdate = (
  patient: InspectorToUpdate,
): ApiInspectorToUpdate => ({
  _id: patient.id,
  email: patient.email,
  firstName: patient.firstName,
  lastName: patient.lastName,
  role: 'inspector',
})

const fromApiHospitalAdmin = (
  hospitalAdmin: ApiHospitalAdmin,
): HospitalAdmin => {
  const fullName = getFullName(hospitalAdmin.firstName, hospitalAdmin.lastName)
  return {
    id: hospitalAdmin._id,
    email: hospitalAdmin.email || '',
    socialSecurityNumber: hospitalAdmin.socialSecurityNumber || '',
    firstName: hospitalAdmin.firstName || '',
    lastName: hospitalAdmin.lastName || '',
    hospital: fromApiHospital(hospitalAdmin.hospital),
    role: hospitalAdmin.role,
    fullName,
    initials: getInitials(fullName),
  }
}

const fromApiDoctor = (doctor: ApiDoctor): Doctor => {
  const fullName = getFullName(doctor.firstName, doctor.lastName)
  return {
    id: doctor._id,
    email: doctor.email || '',
    socialSecurityNumber: doctor.socialSecurityNumber || '',
    hospitalDepartments: doctor.hospitalDepartments?.map(
      fromApiHospitalDepartment,
    ),
    firstName: doctor.firstName || '',
    lastName: doctor.lastName || '',
    phoneNumber: doctor.phoneNumber || '',
    address: doctor.address || '',
    role: doctor.role,
    fullName,
    initials: getInitials(fullName),
  }
}

// Un ApiPatient peut en réalité ne pas avoir certains champs car il est anonymisé lorsqu'un enquêteur fait une requête (e.g. firstName n'est pas renvoyé)
const fromApiPatient = (
  patient: ApiPatient,
  lastFormPostForm?: ApiForm,
): Patient => ({
  id: patient._id,
  codeId: patient.codeId,
  email: patient.email || '',
  socialSecurityNumber: patient.socialSecurityNumber || '',
  firstName: patient.firstName || '',
  lastName: patient.lastName || '',
  doctors: patient.doctors || [],
  cityDoctor: patient.cityDoctor || '',
  hospitalDepartment: patient.hospitalDepartment
    ? fromApiHospitalDepartment(patient.hospitalDepartment)
    : {
        id: '',
        name: '',
        hospital: '',
      },
  inHospitalDate: patient.inHospitalDate || '2020-05-04T12:08:20.593Z',
  outHospitalDate: patient.outHospitalDate || '2020-05-04T12:08:20.593Z',
  birthDate: patient.birthDate || '2020-05-04T12:08:20.593Z',
  phoneNumber: patient.phoneNumber || '0100000000',
  role: 'patient',
  activatedAt: patient.activatedAt,
  symptoms: patient.symptoms,
  lastFormPost:
    patient.lastFormPost && lastFormPostForm
      ? fromApiFormPost(patient.lastFormPost, patient, lastFormPostForm)
      : undefined,
})

const fromApiInspector = (inspector: ApiInspector): Inspector => ({
  id: inspector._id,
  email: inspector.email || '',
  firstName: inspector.firstName || '',
  lastName: inspector.lastName || '',
  role: 'inspector',
})

const fromApiHospital = (hospital: ApiHospital): Hospital => ({
  id: hospital._id,
  name: hospital.name,
})

const fromApiQuestionToQuestionAnswer = (
  question: ApiQuestion,
): QuestionAnswer => {
  if (question.type === 'top') {
    return {
      type: 'top',
      question: question.label,
      questions:
        question.subQuestions?.map((q) => {
          return {
            type: 'single',
            question: q.label,
            answer: q.answer.label,
          }
        }) || [],
    }
  } else if (question.type === 'multiple-choice') {
    return {
      type: 'multiple',
      question: question.answer.values.map((p) => p),
      answers: question.answer.values.map(
        (_, i) => question.possibleAnswers[i].label,
      ),
      number: question.number,
    }
  } else {
    return {
      type: 'single',
      question: question.label,
      answer: question.answer.label,
    }
  }
}

const fromApiQuestionnaireToFormResult = (
  questionnaire: ApiQuestionnaire,
  fullName: string,
  codeId?: string,
  icon?: string,
): FormResult => ({
  questionnaireId: questionnaire._id,
  userFullName: fullName,
  codeId,
  score: questionnaire.score,
  name: questionnaire.name,
  description: questionnaire.description,
  questions: questionnaire.questions.map<QuestionAnswer>(
    fromApiQuestionToQuestionAnswer,
  ),
  icon,
})

const fromApiFormPost = (
  formPost: ApiFormPost,
  user: ApiAnyUser,
  form: ApiForm,
): Form => {
  const fullName = getFullName(user.firstName, user.lastName)
  return {
    id: formPost._id,
    formId: form._id,
    icon: form.icon,
    name: form.name,
    technicalName: form.technicalName,
    user: fromApiAnyUser(user),
    date: formPost.date,
    state: formPost.state,
    results: formPost.questionnaires.map((questionnaire) =>
      fromApiQuestionnaireToFormResult(
        questionnaire,
        fullName,
        user.codeId,
        form.icon,
      ),
    ),
    processed: formPost.processed,
  }
}

const fromApiSubQuestion = (subQuestion: ApiSubQuestion): SubQuestion => ({
  id: subQuestion._id,
  number: subQuestion.number,
  type: subQuestion.type,
  label: subQuestion.label,
  possibleAnswers: subQuestion.possibleAnswers,
  answer: subQuestion.answer,
})

const fromApiQuestion = (question: ApiQuestion): Question => ({
  id: question._id,
  questionnaireId: question.questionnaireId,
  label: question.label,
  type: question.type,
  number: question.number,
  possibleAnswers: question.possibleAnswers,
  answer: question.answer,
  subQuestions: question.subQuestions?.map(fromApiSubQuestion),
})

const fromApiHistoryQuestionnaire = (
  questionnaire: ApiQuestionnaire,
): Questionnaire => ({
  id: questionnaire._id,
  number: questionnaire.number,
  technicalName: questionnaire.technicalName,
  name: questionnaire.name,
  description: questionnaire.description,
  score: questionnaire.score,
  questions: questionnaire.questions.map(fromApiQuestion),
})

const fromApiHistoryEntryFormPost = (formPost: ApiFormPost): FormPost => ({
  id: formPost._id,
  patientId: formPost.patientId,
  inspectorId: formPost.inspectorId,
  formId: formPost.formId,
  totalQuestions: formPost.totalQuestions,
  totalQuestionsAnswered: formPost.totalQuestionsAnswered,
  questionnaires: formPost.questionnaires.map(fromApiHistoryQuestionnaire),
  date: formPost.date,
  state: formPost.state,
  processed: formPost.processed,
})

const fromUsersToPatients = (users: Array<User>): Array<User> => {
  return users.filter((user) => user.role === 'patient')
}

const fromUsersToDoctors = (users: Array<User>): Array<User> => {
  return users.filter((user) =>
    isRoleAmong(user.role, ['cityDoctor', 'hospitalDoctor']),
  )
}

const fromApiMessage = (message: ApiMessage): Message => {
  return {
    id: message._id,
    status: message.status,
    type: message.type,
    sender: message.sender,
    receiver: message.receiver,
  }
}

const fromApiDoctorNote = (apiDoctorNote: ApiDoctorNote): DoctorNote => ({
  author: apiDoctorNote.author,
  title: apiDoctorNote.title,
  description: apiDoctorNote.description,
  date: new Date(apiDoctorNote.date),
})

const toApiCreateDoctorNote = (
  createNote: ApiCreateDoctorNote,
): CreateDoctorNote => ({
  patient: createNote.patient,
  title: createNote.title,
  description: createNote.description,
  date: createNote.date,
})

const fromApiHistoryEntry = (
  apiHistoryEntry: ApiHistoryEntry,
): HistoryEntry => {
  return {
    id: apiHistoryEntry._id,
    user: apiHistoryEntry.user,
    type: apiHistoryEntry.type,
    date: new Date(apiHistoryEntry.date),
    formPost:
      apiHistoryEntry.formPost &&
      fromApiHistoryEntryFormPost(apiHistoryEntry.formPost),
    message: apiHistoryEntry.message
      ? fromApiMessage(apiHistoryEntry.message)
      : undefined,
    mood: apiHistoryEntry.mood,
    doctorNote: apiHistoryEntry.doctorNote
      ? fromApiDoctorNote(apiHistoryEntry.doctorNote)
      : undefined,
    appointmentType: apiHistoryEntry.appointmentType,
    appointmentDoctor: apiHistoryEntry.appointmentDoctor,
  }
}

const fromApiMood = (apiMood: ApiMood): Mood => {
  return {
    id: apiMood._id,
    date: apiMood.date,
    userId: apiMood.userId,
    moodValue: apiMood.moodValue,
  }
}

const fromApiSymptoms = (apiSymptom: ApiSymptom): Symptom => {
  return {
    id: apiSymptom._id,
    label: apiSymptom.label,
    options: apiSymptom.options,
    input: apiSymptom.input,
  }
}

const fromApiRiskyPatient = (
  apiRiskyPatient: ApiRiskyPatient,
): RiskyPatient => {
  return {
    id: apiRiskyPatient._id,
    lastName: apiRiskyPatient.lastName,
    firstName: apiRiskyPatient.firstName,
    lastMood: apiRiskyPatient.lastMood,
  }
}

const symptomsToInitialSymptomValues = (
  symptoms: Array<Symptom>,
): FormDataSymptom => {
  let initialSymptomsValues: FormDataSymptom = {}
  for (const symptom of symptoms) {
    const defaultValue = symptom.input ? '0' : 'Non'
    initialSymptomsValues[symptom.id] = defaultValue
  }
  return initialSymptomsValues
}

const patientSymptomsToInitialSymptomValues = (
  patientSymptoms: Array<PatientSymptom>,
): FormDataSymptom => {
  let initialSymptomsValues: FormDataSymptom = {}
  for (const symptom of patientSymptoms) {
    initialSymptomsValues[symptom.symptom] = symptom.value
  }
  return initialSymptomsValues
}

const fromApiDisturbingFormPost = (
  formPosts: ApiDisturbingFormPost[],
): DisturbingFormPost[] => {
  const result: DisturbingFormPost[] = []
  formPosts.forEach((formPost) => {
    result.push({
      id: formPost._id,
      userId: formPost.userId,
      firstName: formPost.firstName,
      lastName: formPost.lastName,
      icon: formPost.icon,
      riskLabel: formPost.riskLabel,
      riskLevel: formPost.riskLevel,
    })
  })
  return result
}

const fromApiAppointmentUser = (user: ApiAppointmentUser): AppointmentUser => ({
  id: user._id,
  firstName: user.firstName,
  lastName: user.lastName,
})

const fromApiAppointmentPatient = (
  patient: ApiAppointmentPatient,
): AppointmentPatient => ({
  id: patient._id,
  firstName: patient.firstName,
  lastName: patient.lastName,
  phoneNumber: patient.phoneNumber || '',
  email: patient.email,
  birthDate: new Date(patient.birthDate),
})

const fromApiAppointment = (appointment: ApiAppointment): Appointment => ({
  id: appointment._id,
  patient: fromApiAppointmentPatient(appointment.patient),
  hospitalDoctor: fromApiAppointmentUser(appointment.hospitalDoctor),
  doctor: appointment.doctor
    ? fromApiAppointmentUser(appointment.doctor)
    : undefined,
  requestDate: new Date(appointment.requestDate),
  handledDate: appointment.handledDate
    ? new Date(appointment.handledDate)
    : undefined,
  appointmentDate: appointment.appointmentDate
    ? new Date(appointment.appointmentDate)
    : undefined,
  type: appointment.type,
})

export {
  fromApiHistoryEntry,
  fromApiHistoryEntryFormPost,
  fromApiUser,
  fromApiUsers,
  fromApiAnyUser,
  fromApiPatient,
  fromApiDoctor,
  fromApiInspector,
  fromApiHospitalAdmin,
  fromUsersToDoctors,
  fromUsersToPatients,
  fromApiHospital,
  fromApiFormPost,
  fromApiMessage,
  toApiDoctorToCreate,
  toApiDoctorToUpdate,
  toApiPatientToCreate,
  toApiPatientToUpdate,
  toApiInspectorToCreate,
  toApiInspectorToUpdate,
  toApiUserToCreate,
  toApiUserToUpdate,
  toApiCreateDoctorNote,
  fromApiMood,
  fromApiSymptoms,
  fromApiRiskyPatient,
  symptomsToInitialSymptomValues,
  patientSymptomsToInitialSymptomValues,
  fromApiDisturbingFormPost,
  fromApiAppointmentUser,
  fromApiAppointmentPatient,
  fromApiAppointment,
}
