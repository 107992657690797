import { Link } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { DialogProps } from '@material-ui/core/Dialog/Dialog'
import TableCell from '@material-ui/core/TableCell'
import { KeyboardArrowRight } from '@material-ui/icons'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { DialogDashboard } from '../../../assets/Icons'
import { DialogFormAction } from '../../../ui/DialogFormAction'
import { AppointmentActionForm } from '../AppointmentActionForm'

interface Props {
  data: RowsAppointmentItem
}

const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  tableLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  doctor: {
    color: `${theme.palette.info.dark} !important`,
  },
}))

export const AppointmentTable: React.FC<Props> = ({ data }) => {
  const classes = useTableStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [maxWidth] = useState<DialogProps['maxWidth']>('md')

  const handleClickOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <>
      <TableCell component="th" align="center" className={classes.doctor}>
        {i18n.dashboard.table.appointment.namePrefix} {data.doctor}
      </TableCell>
      <TableCell component="th" align="center">
        {i18n.dashboard.table.appointment.ask} {data.appointment}
      </TableCell>
      <TableCell component="th" align="center">
        <Link
          to={`/patients/${data.patientId}`}
          component={RouterLink}
          className={classes.tableLink}
        >
          <span style={{ marginRight: 10 }}>
            {i18n.patientTable.linkPatientDetails}
          </span>
          <KeyboardArrowRight />
        </Link>
      </TableCell>
      <TableCell component="th" style={{ width: 236 }}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.appointmentButton}
          startIcon={<AccessTimeIcon />}
          onClick={handleClickOpen}
        >
          {i18n.dashboard.table.appointment.over}
        </Button>
      </TableCell>
      <DialogFormAction
        open={isOpen}
        maxWidth={maxWidth}
        onClose={handleClose}
        icon={<DialogDashboard />}
        title={i18n.dashboard.table.appointment.dialog.title.toLocaleUpperCase()}
      >
        <AppointmentActionForm onClose={handleClose} data={data} />
      </DialogFormAction>
    </>
  )
}
