import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { loadHospitalsAction } from '../../../context/actions/hospital'
import { loadSymptomsAction } from '../../../context/actions/symptom'
import {
  addPatientAction,
  loadDoctorsAction,
  loadPatientsAction,
  resetPatientsStateAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import { isHospitalDoctorOfHospitalOfThisHospitalDepartment } from '../../../utils/isHospitalDoctorOfHospitalOfThisHospitalDepartment'
import { symptomsToInitialSymptomValues } from '../../../utils/mappings'
import { regexes } from '../../../utils/regexes'
import { isCityDoctor, isHospitalDoctor } from '../../../utils/typeGuards'
import { HeroBanner } from '../../ui/HeroBanner'
import { HospitalDepartmentsSelector } from '../../ui/HospitalDepartmentsSelector'
import { InputField, PickerDate } from '../../ui/Inputfield'
import { MessageAlert } from '../../ui/MessageAlert'
import {
  PhoneNumberField,
  phoneNumberValidationSchema,
} from '../../ui/PhoneNumberField'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'
import { PatientSymptoms } from './PatientSymptoms'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.patients.create.email.valid)
    .required(i18n.patients.create.email.required),
  firstName: Yup.string().required(i18n.patients.create.firstName.required),
  lastName: Yup.string().required(i18n.patients.create.lastName.required),
  socialSecurityNumber: Yup.string()
    .matches(
      regexes.socialSecurityNumber,
      i18n.patients.create.socialSecurityNumber.mustBeValid,
    )
    .length(15, i18n.patients.create.socialSecurityNumber.length),
  codeId: Yup.string()
    .optional()
    .matches(regexes.codeId, i18n.patients.create.codeId.mustBeAlphanumeric),
  doctors: Yup.array().required(),
  cityDoctor: Yup.string().optional(),
  hospitalDepartment: Yup.string().required(),
  inHospitalDate: Yup.date()
    .required(i18n.patients.create.inHospitalDate.required)
    .typeError(i18n.patients.create.inHospitalDate.mustBeValid),
  outHospitalDate: Yup.date()
    .required(i18n.patients.create.outHospitalDate.required)
    .typeError(i18n.patients.create.outHospitalDate.mustBeValid),
  birthDate: Yup.date()
    .required(i18n.patients.create.birthDate.required)
    .typeError(i18n.patients.create.birthDate.mustBeValid),
  phoneNumber: phoneNumberValidationSchema.required(
    i18n.ui.phoneNumberField.required,
  ),
})

/**
 * Formulaire de création de patient
 */
export const CreatePatient = () => {
  const strings = i18n.patients.create
  const classes = useUserFormStyles()
  const {
    dispatch,
    hospitals,
    hospitalDepartments,
    doctors,
    patients,
    symptoms,
  } = useAppContext()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(resetPatientsStateAction())
    }
  }, [dispatch])

  useEffect(() => {
    if (hospitals.state !== 'loaded' && hospitals.state !== 'error') {
      dispatch(loadHospitalsAction())
    }
  }, [hospitals.state, dispatch])

  useEffect(() => {
    if (symptoms.state !== 'loaded' && symptoms.state !== 'error') {
      // Peut être se baser sur l'hospitalDepartement du réanimateur référent
      dispatch(loadSymptomsAction('jeneconnaispaslid'))
    }
  }, [symptoms.state, dispatch])

  useEffect(() => {
    if (!doctors.data) {
      dispatch(loadDoctorsAction())
    }
  }, [doctors.data, dispatch])

  // Functions
  const onSubmit = async (values: CreatePatientFormValues) => {
    try {
      let symptomData: Array<PatientSymptom> = []
      for (const key of Object.keys(values.symptoms)) {
        symptomData.push({ symptom: key, value: values.symptoms[key] })
      }
      const patientToCreate = {
        ...values,
        codeId: values.codeId === '' ? undefined : values.codeId,
        symptoms: symptomData,
        inHospitalDate: values.inHospitalDate.toISOString(),
        outHospitalDate: values.outHospitalDate.toISOString(),
        birthDate: values.birthDate.toISOString(),
      }

      await dispatch(addPatientAction(patientToCreate))
      await dispatch(loadPatientsAction())
      browserHistory.push('/admin/patients')
    } catch (error) {
      setShowError(true)
    }
  }

  // Data
  const cityDoctors = doctors.data?.filter(isCityDoctor) || []
  const hospitalDoctors = doctors.data?.filter(isHospitalDoctor) || []

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.patientBanner.createNewPatientTitle}
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.patientBanner.goBack}
      />

      {showError && (
        <MessageAlert
          message={strings.errors.message}
          title={strings.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      {symptoms.data && (
        <Formik<CreatePatientFormValues>
          initialValues={{
            email: '',
            codeId: '',
            firstName: '',
            lastName: '',
            socialSecurityNumber: '',
            role: 'patient',
            doctors: [],
            cityDoctor: '',
            hospitalDepartment: '',
            inHospitalDate: new Date(),
            outHospitalDate: new Date(),
            birthDate: new Date(),
            phoneNumber: '',
            symptoms: symptomsToInitialSymptomValues(symptoms.data),
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            dirty,
            touched,
            setFieldValue,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
          }) => {
            const availableHospitalDoctors =
              hospitalDoctors.filter((doctor) =>
                isHospitalDoctorOfHospitalOfThisHospitalDepartment(
                  doctor,
                  values.hospitalDepartment,
                  hospitals.data,
                  hospitalDepartments.data,
                ),
              ) || []

            const showHospitalDoctorSelect =
              doctors.data &&
              hospitals.data &&
              hospitalDepartments.data &&
              values.hospitalDepartment

            const submitDisabled =
              !dirty ||
              values.email === '' || // La condition "dirty" ne suffit pas, le form devient "dirty" alors qu'aucun champ n'est modifié
              !!errors.email ||
              !!errors.firstName ||
              !!errors.lastName ||
              !!errors.socialSecurityNumber ||
              !!errors.birthDate ||
              !!errors.inHospitalDate ||
              !!errors.outHospitalDate ||
              !!errors.doctors ||
              !!errors.cityDoctor ||
              !!errors.hospitalDepartment ||
              !!errors.phoneNumber ||
              isSubmitting

            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Box className={classes.mainContainer}>
                  {/* IDENTITY */}
                  <Box className={classes.formSection}>
                    <UserFormSectionTitle title={strings.patient.identity} />
                    <Grid container spacing={USER_FORM_SPACING}>
                      <Grid item xs={6}>
                        <Field
                          name="lastName"
                          label={strings.lastName.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="firstName"
                          label={strings.firstName.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="socialSecurityNumber"
                          label={strings.socialSecurityNumber.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="birthDate"
                          label={strings.birthDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.birthDate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="codeId"
                          label={strings.codeId.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* INFORMATION */}
                  <Box
                    className={clsx(
                      classes.formSection,
                      classes.formSectionDark,
                    )}
                  >
                    <UserFormSectionTitle title={strings.patient.information} />
                    <Grid container spacing={USER_FORM_SPACING}>
                      <Grid item xs={10}>
                        <Field
                          name="email"
                          label={strings.email.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <PhoneNumberField
                          label={i18n.ui.phoneNumberField.requiredLabel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* HOSPITALIZATION */}
                  <Box className={classes.formSection}>
                    <UserFormSectionTitle
                      title={strings.patient.hospitalization}
                    />
                    <Grid container wrap="wrap" spacing={USER_FORM_SPACING}>
                      <Grid item xs={6}>
                        <Field
                          name="inHospitalDate"
                          label={strings.inHospitalDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.inHospitalDate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="outHospitalDate"
                          label={strings.outHospitalDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.outHospitalDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <HospitalDepartmentsSelector
                          errors={errors}
                          touched={touched}
                          setValue={(value: Array<string>) =>
                            setFieldValue('hospitalDepartment', value[0] || '')
                          }
                          resetHospitalDoctor={() =>
                            setFieldValue('doctors', [])
                          }
                        />
                      </Grid>

                      {showHospitalDoctorSelect && (
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}>
                            <InputLabel style={{ paddingLeft: 16 }}>
                              {strings.reanimators.label}
                            </InputLabel>
                            <Select
                              classes={{ root: classes.select }}
                              value={values.doctors[0] || ''}
                              name="doctors"
                              label={strings.reanimators.label}
                              placeholder={strings.reanimators.label}
                              variant="outlined"
                              onChange={(e) =>
                                setFieldValue('doctors', [e.target.value])
                              }
                            >
                              {availableHospitalDoctors.map((doctor) => (
                                <MenuItem key={doctor.id} value={doctor.id}>
                                  {doctor.lastName.toUpperCase()}&nbsp;
                                  {doctor.firstName}
                                </MenuItem>
                              ))}
                              {availableHospitalDoctors.length === 0 && (
                                <MenuItem value="" disabled>
                                  {strings.doctors.noCityDoctorFound}
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            id="city-doctor-selector"
                            options={cityDoctors}
                            getOptionLabel={(option) => option.fullName}
                            onChange={(e, newValue) => {
                              setFieldValue('cityDoctor', newValue?.id || '')
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={strings.cityDoctor.label}
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* SYMPTOMS */}
                  <Box
                    className={clsx(
                      classes.formSection,
                      classes.formSectionDark,
                    )}
                  >
                    <UserFormSectionTitle title={strings.patient.symptoms} />

                    {symptoms.data && (
                      <PatientSymptoms
                        symptoms={symptoms.data}
                        spacing={USER_FORM_SPACING}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    )}
                  </Box>

                  {/* BUTTONS */}
                  <Box className={classes.buttonsContainer}>
                    <PreviousButton
                      title={i18n.auth.forgottenPassword.reset.return}
                      onClick={() => browserHistory.goBack()}
                      marginRight
                    />

                    <SubmitButton
                      title={strings.button}
                      disabled={submitDisabled}
                    />

                    {patients.state === 'loading' && (
                      <CircularProgress
                        size={24}
                        className={classes.loadingIcon}
                      />
                    )}
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      )}
    </Grid>
  )
}
