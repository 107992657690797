import { Box, CircularProgress, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { Field, Formik, FormikErrors } from 'formik'
import { History } from 'history'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { loadHospitalsAction } from '../../../context/actions/hospital'
import {
  loadDoctorsAction,
  updateDoctorAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import { isEmptyArrayOrUndefined } from '../../../utils/isEmptyArrayOrUndefined'
import {
  isUpdateCityDoctorFormValues,
  isUpdateHospitalDoctorFormValues,
} from '../../../utils/typeGuards'
import { AddressField, addressValidationSchema } from '../../ui/AddressField'
import { HeroBanner } from '../../ui/HeroBanner'
import { HospitalDepartmentsSelector } from '../../ui/HospitalDepartmentsSelector'
import { InputField } from '../../ui/Inputfield'
import { LoadingPane } from '../../ui/LoadingPane'
import { MessageAlert } from '../../ui/MessageAlert'
import {
  PhoneNumberField,
  phoneNumberValidationSchema,
} from '../../ui/PhoneNumberField'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.doctors.create.email.valid)
    .required(i18n.doctors.create.email.required),
  phoneNumber: phoneNumberValidationSchema.optional(),
  address: addressValidationSchema,
  firstName: Yup.string().required(i18n.doctors.create.firstName.required),
  lastName: Yup.string().required(i18n.doctors.create.lastName.required),
  hospitalDepartments: Yup.array(),
  role: Yup.string().required(i18n.doctors.create.role.required),
})

interface UpdateDoctorProps {
  history: History
}

/**
 * Formulaire de modification de médecin (hospitalDoctor ou cityDoctor)
 */
export const UpdateDoctor = ({ history }: UpdateDoctorProps) => {
  const classes = useUserFormStyles()
  const { dispatch, doctors, hospitals } = useAppContext()
  const [showError, setShowError] = useState(false)
  const [doctorToUpdate, setDoctorToUpdate] = useState<Doctor | null>(null)

  const { id } = useParams()

  useEffect(() => {
    if (hospitals.state !== 'loaded') {
      dispatch(loadHospitalsAction())
    }
  }, [hospitals.state, dispatch])

  useEffect(() => {
    const doctor =
      doctors.data && doctors.data.filter((user) => user.id === id)[0]
    if (doctor && !doctorToUpdate) {
      setDoctorToUpdate(doctor)
    } else if (!doctors.data && doctors.state === 'idle') {
      dispatch(loadDoctorsAction())
    }
  }, [doctors.data, doctors.state, dispatch, doctorToUpdate, id])

  if (!doctorToUpdate) {
    return <LoadingPane />
  }

  const onSubmit = async (values: UpdateDoctorFormValues) => {
    if (values.role !== 'hospitalDoctor' && values.role !== 'cityDoctor') {
      return
    }
    let updateDoctor = { ...values }

    // 1. hospitalDoctor
    if (updateDoctor.role === 'hospitalDoctor') {
      if (!isUpdateHospitalDoctorFormValues(updateDoctor)) {
        return
      }
      updateDoctor = {
        ...updateDoctor,
        hospitalDepartments: values.hospitalDepartments?.filter(
          (item, index) => values.hospitalDepartments?.indexOf(item) === index,
        ),
      }
    }

    // 2. cityDoctor
    if (updateDoctor.role === 'cityDoctor') {
      if (!isUpdateCityDoctorFormValues(updateDoctor)) {
        return
      }
    }

    try {
      await dispatch(
        updateDoctorAction({
          ...updateDoctor,
          id: doctorToUpdate.id,
        }),
      )
      await dispatch(loadDoctorsAction())
      browserHistory.push('/admin/doctors')
    } catch (error) {
      setShowError(true)
      await dispatch(loadDoctorsAction())
      browserHistory.push('/admin/doctors')
    }
  }

  const doctorFullName = `${doctorToUpdate.lastName.toUpperCase()}  ${
    doctorToUpdate.firstName
  }`

  const checkFormErrors = (
    errors: FormikErrors<UpdateDoctorFormValues>,
    values: UpdateDoctorFormValues,
  ): boolean => {
    const isEmptyHospitalDepartments = isEmptyArrayOrUndefined(
      values.hospitalDepartments,
    )

    return (
      !!errors.email ||
      !!errors.phoneNumber ||
      !!errors.address ||
      !!errors.firstName ||
      !!errors.lastName ||
      !!errors.role ||
      (values.role === 'hospitalDoctor' && isEmptyHospitalDepartments)
    )
  }

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.doctorBanner.updateDoctorTitle + ' ' + doctorFullName}
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.doctorBanner.goBack}
      />
      {showError && (
        <MessageAlert
          message={i18n.doctors.update.errors.message}
          title={i18n.doctors.update.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      <Formik<UpdateDoctorFormValues>
        initialValues={{
          email: doctorToUpdate.email,
          firstName: doctorToUpdate.firstName,
          lastName: doctorToUpdate.lastName,
          phoneNumber:
            doctorToUpdate.role === 'cityDoctor'
              ? doctorToUpdate.phoneNumber ?? ''
              : undefined,
          address:
            doctorToUpdate.role === 'cityDoctor'
              ? doctorToUpdate.address ?? ''
              : undefined,
          hospitalDepartments: doctorToUpdate.hospitalDepartments?.map(
            (hospitalDepartment) => hospitalDepartment.id,
          ),
          role: doctorToUpdate.role,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box className={classes.mainContainer}>
              {/* IDENTITY */}
              <Box className={classes.formSection}>
                <UserFormSectionTitle title={i18n.doctors.create.identity} />
                <Grid container spacing={USER_FORM_SPACING}>
                  <Grid item xs={6}>
                    <Field
                      name="lastName"
                      label={i18n.doctors.create.lastName.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      label={i18n.doctors.create.firstName.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* INFORMATION */}
              <Box
                className={clsx(classes.formSection, classes.formSectionDark)}
              >
                <UserFormSectionTitle
                  title={i18n.patients.create.patient.information}
                />
                <Grid container spacing={USER_FORM_SPACING}>
                  <Grid item xs={10}>
                    <Field
                      name="email"
                      label={i18n.doctors.create.email.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                  {values.role === 'cityDoctor' && (
                    <>
                      <Grid item xs={10}>
                        <PhoneNumberField
                          label={i18n.ui.phoneNumberField.optionalLabel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <AddressField
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>

              {/* HOSPITAL & DEPARTMENT */}
              {values.role === 'hospitalDoctor' && (
                <Box className={classes.formSection}>
                  <UserFormSectionTitle
                    title={i18n.doctors.create.hospitalAndService}
                  />
                  <Grid container wrap="wrap">
                    <Grid item xs={12}>
                      <HospitalDepartmentsSelector
                        errors={errors}
                        touched={touched}
                        setValue={(value: Array<string>) =>
                          setFieldValue('hospitalDepartments', value)
                        }
                        initialHospitalDepartments={
                          doctorToUpdate.hospitalDepartments
                        }
                        multiple
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {/* BUTTONS */}
              <Box className={classes.buttonsContainer}>
                <PreviousButton
                  title={i18n.auth.forgottenPassword.reset.return}
                  onClick={() => browserHistory.goBack()}
                  marginRight
                />

                <SubmitButton
                  title={i18n.doctors.create.buttonUpdate}
                  disabled={
                    !dirty || isSubmitting || checkFormErrors(errors, values)
                  }
                />

                {doctors.state === 'loading' && (
                  <CircularProgress size={24} className={classes.loadingIcon} />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  )
}
