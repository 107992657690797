import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { settings } from '../../theme/settings'

export interface FormIconProps {
  risk: RiskLevel | null
  iconName: string
  iconType: 'plain' | 'rounded'
  width?: string | number
  height?: string | number
}

interface StyleProps {
  width?: string | number
  height?: string | number
}

const useStyles = makeStyles<ChuTheme, StyleProps>((theme) => ({
  root: {
    display: 'block',
    width: (props) => (props.width ? props.width : 40),
    height: (props) => (props.height ? props.height : 40),
  },
}))

export const FormIcon = ({
  risk,
  iconName,
  iconType,
  width,
  height,
}: FormIconProps) => {
  const classes = useStyles({ width, height })

  return (
    <img
      className={classes.root}
      src={`${settings.images.path}/svg/${iconName}/FORM_${iconType}_${
        risk ? risk : 'default'
      }.svg`}
      alt={`FORM_${iconName}_${risk}`}
    />
  )
}
