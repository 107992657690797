import { buildContext } from 'react-condux'

interface AppContextState {
  patients: LoadableData<Patient[] | undefined>
  doctors: LoadableData<Doctor[] | undefined>
  inspectors: LoadableData<Inspector[] | undefined>
  forms: LoadableData<Form[] | undefined>
  patientHistory: LoadableData<HistoryEntry[] | undefined>
  hospitals: LoadableData<Hospital[] | undefined>
  hospitalDepartments: LoadableData<HospitalDepartment[] | undefined>
  patientMoods: LoadableData<Mood[] | undefined>
  symptoms: LoadableData<Symptom[] | undefined>
  riskyPatients: LoadableData<RiskyPatient[] | undefined>
  disturbingFormPosts: LoadableData<DisturbingFormPost[] | undefined>
  appointments: LoadableData<Appointment[] | undefined>
  lastAppointments: LoadableData<Appointment[] | undefined>
}

export const initialValue: AppContextState = {
  patients: {
    data: undefined,
    state: 'idle',
  },
  doctors: {
    data: undefined,
    state: 'idle',
  },
  inspectors: {
    data: undefined,
    state: 'idle',
  },
  forms: {
    data: undefined,
    state: 'idle',
  },
  patientHistory: {
    data: undefined,
    state: 'idle',
  },
  hospitals: {
    data: undefined,
    state: 'idle',
  },
  hospitalDepartments: {
    data: undefined,
    state: 'idle',
  },
  patientMoods: {
    data: undefined,
    state: 'idle',
  },
  symptoms: {
    data: undefined,
    state: 'idle',
  },
  riskyPatients: {
    data: undefined,
    state: 'idle',
  },
  disturbingFormPosts: {
    data: undefined,
    state: 'idle',
  },
  appointments: {
    data: undefined,
    state: 'idle',
  },
  lastAppointments: {
    data: undefined,
    state: 'idle',
  },
}

const { createAction, useContext, Provider } = buildContext<AppContextState>({
  initialValue: { ...initialValue },
})

export { createAction }
export const useAppContext = useContext
export const AppProvider = Provider
