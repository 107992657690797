import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import clsx from 'clsx'
import { Field, Formik, FormikErrors } from 'formik'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import {
  addDoctorAction,
  loadDoctorsAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import { AuthContext } from '../../../context/auth-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import { isEmptyArrayOrUndefined } from '../../../utils/isEmptyArrayOrUndefined'
import { isRoleAmong } from '../../../utils/isRoleAmong'
import {
  isCreateCityDoctorFormValues,
  isCreateHospitalDoctorFormValues,
} from '../../../utils/typeGuards'
import { AddressField, addressValidationSchema } from '../../ui/AddressField'
import { FieldInfo } from '../../ui/FieldInfo'
import { HeroBanner } from '../../ui/HeroBanner'
import { HospitalDepartmentsSelector } from '../../ui/HospitalDepartmentsSelector'
import { InputField } from '../../ui/Inputfield'
import { MessageAlert } from '../../ui/MessageAlert'
import {
  PhoneNumberField,
  phoneNumberValidationSchema,
} from '../../ui/PhoneNumberField'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.doctors.create.email.valid)
    .required(i18n.doctors.create.email.required),
  address: addressValidationSchema,
  phoneNumber: phoneNumberValidationSchema.optional(),
  password: Yup.string().required(i18n.doctors.create.password.required),
  firstName: Yup.string().required(i18n.doctors.create.firstName.required),
  lastName: Yup.string().required(i18n.doctors.create.lastName.required),
  hospitalDepartments: Yup.array(),
  role: Yup.string().required(i18n.doctors.create.role.required),
})

/**
 * Formulaire de création de médecin (hospitalDoctor ou cityDoctor)
 */
export const CreateDoctor = () => {
  const classes = useUserFormStyles()
  const { dispatch, doctors } = useAppContext()
  const { user } = useContext(AuthContext)
  const [showError, setShowError] = useState(false)

  const checkFormErrors = (
    errors: FormikErrors<CreateDoctorFormValues>,
    values: CreateDoctorFormValues,
  ): boolean => {
    const isEmptyHospitalDepartments = isEmptyArrayOrUndefined(
      values.hospitalDepartments,
    )

    return (
      !!errors.email ||
      !!errors.password ||
      !!errors.firstName ||
      !!errors.lastName ||
      !!errors.phoneNumber ||
      !!errors.address ||
      !!errors.role ||
      (values.role === 'hospitalDoctor' && isEmptyHospitalDepartments)
    )
  }

  const onSubmit = async (values: CreateDoctorFormValues) => {
    if (values.role !== 'hospitalDoctor' && values.role !== 'cityDoctor') {
      return
    }
    let createDoctor = { ...values }

    // 1. hospitalDoctor
    if (createDoctor.role === 'hospitalDoctor') {
      if (!isCreateHospitalDoctorFormValues(createDoctor)) {
        return
      }
      createDoctor = {
        ...createDoctor,
        hospitalDepartments: values.hospitalDepartments?.filter(
          (item, index) => values.hospitalDepartments?.indexOf(item) === index,
        ),
      }
    }

    // 2. cityDoctor
    if (createDoctor.role === 'cityDoctor') {
      delete createDoctor.hospitalDepartments
      if (!isCreateCityDoctorFormValues(createDoctor)) {
        return
      }
    }

    try {
      await dispatch(addDoctorAction(createDoctor))
      await dispatch(loadDoctorsAction())
      browserHistory.push('/admin/doctors')
    } catch (error) {
      setShowError(true)
    }
  }

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.doctorBanner.createNewDoctorTitle}
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.doctorBanner.goBack}
      />

      {showError && (
        <MessageAlert
          message={i18n.doctors.create.errors.message}
          title={i18n.doctors.create.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      <Formik<CreateDoctorFormValues>
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          address: '',
          hospitalDepartments: [],
          role: isRoleAmong(user.data?.role, ['admin', 'hospitalAdmin'])
            ? 'hospitalDoctor'
            : 'cityDoctor',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Box className={classes.mainContainer}>
              {/* IDENTITY */}
              <Box className={classes.formSection}>
                <UserFormSectionTitle title={i18n.doctors.create.identity} />
                <Grid item xs={12}>
                  {/* ROLE */}
                  <FormControl component="fieldset" fullWidth>
                    <Box className={classes.roleContainer}>
                      <Box mr={2}>
                        <FormLabel component="legend">
                          {i18n.doctors.create.role.label}
                        </FormLabel>
                      </Box>
                      <RadioGroup
                        aria-label="role"
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        row
                      >
                        <FormControlLabel
                          value="hospitalDoctor"
                          control={<Radio />}
                          label={i18n.doctors.create.role.hospitalDoctor}
                          disabled={
                            !isRoleAmong(user.data?.role, [
                              'admin',
                              'hospitalAdmin',
                              'hospitalDoctor',
                            ])
                          }
                        />
                        <FormControlLabel
                          value="cityDoctor"
                          control={<Radio />}
                          label={i18n.doctors.create.role.cityDoctor}
                          disabled={
                            !isRoleAmong(user.data?.role, [
                              'admin',
                              'hospitalAdmin',
                              'hospitalDoctor',
                            ])
                          }
                        />
                      </RadioGroup>
                    </Box>
                  </FormControl>
                </Grid>

                <Grid container spacing={USER_FORM_SPACING}>
                  <Grid item xs={6}>
                    <Field
                      name="lastName"
                      label={i18n.doctors.create.lastName.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="firstName"
                      label={i18n.doctors.create.firstName.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* INFORMATION */}
              <Box
                className={clsx(classes.formSection, classes.formSectionDark)}
              >
                <UserFormSectionTitle
                  title={i18n.patients.create.patient.information}
                />
                <FieldInfo title={i18n.auth.forgottenPassword.subtitle} />
                <Grid container wrap="wrap">
                  <Grid item xs={10}>
                    <Field
                      name="email"
                      label={i18n.doctors.create.email.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Field
                      name="password"
                      label={i18n.doctors.create.password.label}
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      component={InputField}
                    />
                  </Grid>
                  {values.role === 'cityDoctor' && (
                    <>
                      <Grid item xs={10}>
                        <PhoneNumberField
                          label={i18n.ui.phoneNumberField.optionalLabel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={10}>
                        <AddressField
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>

              {/* HOSPITAL & DEPARTMENT */}
              {values.role === 'hospitalDoctor' && (
                <Box className={classes.formSection}>
                  <UserFormSectionTitle
                    title={i18n.doctors.create.hospitalAndService}
                  />
                  <Grid container spacing={USER_FORM_SPACING}>
                    <Grid item xs={12}>
                      <HospitalDepartmentsSelector
                        errors={errors}
                        touched={touched}
                        setValue={(value: Array<string>) =>
                          setFieldValue('hospitalDepartments', value)
                        }
                        multiple
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}

              {/* BUTTONS */}
              <Box className={classes.buttonsContainer}>
                <PreviousButton
                  title={i18n.auth.forgottenPassword.reset.return}
                  onClick={() => browserHistory.goBack()}
                  marginRight
                />

                <SubmitButton
                  title={i18n.doctors.create.button}
                  disabled={
                    !dirty || isSubmitting || checkFormErrors(errors, values)
                  }
                />

                {doctors.state === 'loading' && (
                  <CircularProgress size={24} className={classes.loadingIcon} />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  )
}
