import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { format } from 'date-fns'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { TIMELINE_BORDER_HEIGHT } from './Timeline'

interface Props {
  color: string
  date: Date
  isLastEntry?: boolean
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  entryBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  topEntryBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '80px',

    '&:after': {
      content: '" "',
      width: (props) => (props.isLastEntry ? '50%' : '100%'),
      alignSelf: 'flex-start',
      borderBottom: `${TIMELINE_BORDER_HEIGHT}px solid ${theme.palette.tertiaryColorMain}`,
    },
  },
  color: {
    color: (props) => props.color,
  },
  dotOnLine: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.lightGrey,
    border: (props) => `5px solid ${props.color}`,
    borderRadius: '100%',
    transform: `translateY(calc(50% + ${TIMELINE_BORDER_HEIGHT / 2}px))`,
  },
}))

/**
 * Une des deux parties d'une entrée d'historique <TimelineEntry />
 * Affiche la date ainsi qu'un trait horizontal gris sur lequel est posé un point (div ronde avec bordure)
 */
export const TimelineDate: React.FC<Props> = (props) => {
  const classes = useStyles(props)

  return (
    <Box className={clsx(classes.entryBox, classes.topEntryBox)}>
      <Typography className={classes.color}>
        {format(props.date, 'dd/MM/yyyy')}
      </Typography>
      <Box className={classes.dotOnLine} />
    </Box>
  )
}
