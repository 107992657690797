import { differenceInDays, format } from 'date-fns'
import { i18n } from '../assets/i18n'

/**
 * Crée d'un tableau d'objets pour le tableau "DEMANDE DE RDV" du dashboard à partir des Appointments
 */
export const appointmentsToRowsAppointment = (
  appointments: Array<Appointment> | undefined,
): Array<RowsAppointmentItem> =>
  appointments
    ?.filter((appointment) => !Boolean(appointment.handledDate))
    // Les demandes les plus anciennes en premier
    .sort((appointmentA, appointmentB) =>
      differenceInDays(appointmentA.requestDate, appointmentB.requestDate),
    )
    .map(
      (appointment): RowsAppointmentItem => ({
        id: appointment.id,
        patientId: appointment.patient.id,
        lastName: appointment.patient.lastName,
        firstName: appointment.patient.firstName,
        doctor: `${appointment.hospitalDoctor.firstName} ${appointment.hospitalDoctor.lastName}`,
        appointment: format(appointment.requestDate, 'dd/MM'),
        phoneNumber: appointment.patient.phoneNumber,
        email: appointment.patient.email,
        birthDate: appointment.patient.birthDate.toISOString(),
      }),
    ) || []

/**
 * Récupère le nom à afficher selon le type de rendez-vous
 */
export const appointmentTypeToAppointmentTypeString = (
  type: AppointmentType | undefined,
): string => {
  if (type === 'Consultation') {
    return i18n.dashboard.table.appointment.consultation
  }
  if (type === 'Téléconsultation') {
    return i18n.dashboard.table.appointment.teleconsultation
  }
  if (type === 'H. de jour') {
    return i18n.dashboard.table.appointment.dayHospital
  }
  return i18n.dashboard.table.appointment.unknown
}

/**
 * Création d'un tableau d'objets pour le tableau "HISTORIQUE DES DEMANDES" du dashboard
 */
export const appointmentsToRowsHistory = (
  appointments: Array<Appointment> | undefined,
): Array<RowsHistoryItem> =>
  appointments
    ?.filter((appointment) => Boolean(appointment.appointmentDate))
    // Les rendez-vous les plus anciens en dernier
    .sort((appointmentA, appointmentB) =>
      differenceInDays(
        appointmentB.appointmentDate || new Date(),
        appointmentA.appointmentDate || new Date(),
      ),
    )
    .map((appointment) => ({
      id: appointment.id,
      lastName: appointment.patient.lastName,
      firstName: appointment.patient.firstName,
      type: appointment.type,
      appointment: appointment.appointmentDate
        ? format(appointment.appointmentDate, 'dd/MM')
        : '',
      doctor: `${appointment.doctor?.firstName} ${appointment.doctor?.lastName}`,
      status:
        appointment.appointmentDate &&
        differenceInDays(new Date(), appointment.appointmentDate) > 0
          ? i18n.dashboard.table.appointment.passed
          : i18n.dashboard.table.appointment.upcoming,
    })) || []

/**
 * Création d'un tableau d'objets pour le tableau "MON PROCHAIN RDV PATIENT"
 */
export const appointmentsToRowsLastAppointment = (
  appointments: Array<Appointment> | undefined,
): Array<RowsLastAppointmentItem> =>
  appointments
    ?.filter(
      (appointment) =>
        appointment.appointmentDate &&
        differenceInDays(appointment.appointmentDate, new Date()) >= 0,
    )
    // Les rendez-vous les plus proches en premier
    .sort((appointmentA, appointmentB) =>
      differenceInDays(
        appointmentA.appointmentDate || new Date(),
        appointmentB.appointmentDate || new Date(),
      ),
    )
    .map((appointment) => ({
      id: appointment.patient.id,
      type: appointment.type,
      appointmentDateInDateFormat: appointment.appointmentDate,
      appointmentDate: appointment.appointmentDate
        ? format(appointment.appointmentDate, 'dd/MM')
        : '',
      appointmentHour: appointment.appointmentDate
        ? format(appointment.appointmentDate, 'HH:mm')
        : '',
      lastName: appointment.patient.lastName,
      firstName: appointment.patient.firstName,
    })) || []
