import React from 'react'
import { PasswordUpdateWarningModal } from '../components/ui/PasswordUpdateWarningModal'
import browserHistory from '../router/history'
import * as AuthApi from '../services/auth.service'
import { isPasswordTooOld } from '../utils/isPasswordTooOld'
import { isRoleAmong } from '../utils/isRoleAmong'
import * as mappings from '../utils/mappings'

export const WRONG_ROLE_ERROR = 'forbidden - wrong role'

interface AuthContextState {
  user: LoadableData<AnyUser | undefined>
  resetPassword: {
    status: 'idle' | 'success' | 'error'
  }
  passwordUpdateModalOpen: boolean
  sessionRevokedError: boolean
  displayPasswordUpdateModalAction: (open: boolean) => void
  signIn: (values: LoginFormValues) => Promise<void>
  signOut: () => void
  register: () => void
  validateOtpCode: (otp: OtpFormValues) => Promise<void>
  refreshOtpCode: () => Promise<void>
  resetForgottenPassword: (
    values: ForgottenPasswordResetFApiValues,
  ) => Promise<void>
  displayRevokedSessionError: () => void
  hideRevokedSessionError: () => void
}

export const AuthContext = React.createContext<AuthContextState>(
  {} as AuthContextState,
)

export class AuthProvider extends React.PureComponent<{}, AuthContextState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      user: {
        data: undefined,
        state: 'idle',
      },
      resetPassword: {
        status: 'idle',
      },
      passwordUpdateModalOpen: false,
      sessionRevokedError: false,
      displayPasswordUpdateModalAction: this.displayPasswordUpdateModalAction.bind(
        this,
      ),
      signIn: this.signIn.bind(this),
      signOut: this.signOut.bind(this),
      register: this.register.bind(this),
      validateOtpCode: this.validateOtpCode.bind(this),
      refreshOtpCode: this.refreshOtpCode.bind(this),
      resetForgottenPassword: this.resetForgottenPassword.bind(this),
      displayRevokedSessionError: this.displayRevokedSessionError.bind(this),
      hideRevokedSessionError: this.hideRevokedSessionError.bind(this),
    }
  }

  public render() {
    return (
      <AuthContext.Provider value={this.state}>
        <PasswordUpdateWarningModal />
        {this.props.children}
      </AuthContext.Provider>
    )
  }

  public async componentDidMount() {
    try {
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'loading' },
      }))
      const user = await this.getProfile()
      this.setState({
        user: {
          data: user,
          state: 'loaded',
        },
      })
    } catch (e) {
      console.error(e)
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'error' },
      }))
    }
  }

  private async signIn(values: LoginFormValues) {
    try {
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'loading' },
      }))
      await AuthApi.login(values)
      const user = await this.getProfile()
      const passwordUpdateModalOpen = user.lastPasswordUpdate
        ? isPasswordTooOld(user.lastPasswordUpdate)
        : false
      if (user.otpRequired && !user.otpConfirmed) {
        await this.getOtpCode()
      }
      this.setState({
        user: {
          data: user,
          state: 'loaded',
        },
        passwordUpdateModalOpen,
      })
    } catch (e) {
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'error' },
      }))
      throw e
    }
  }

  private async getOtpCode() {
    try {
      await AuthApi.getOtpCode()
    } catch (error) {
      throw error
    }
  }
  private async validateOtpCode(otp: OtpFormValues) {
    try {
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'loading' },
      }))
      await AuthApi.otpValidation(otp)
      const user = await this.getProfile()
      const passwordUpdateModalOpen = user.lastPasswordUpdate
        ? isPasswordTooOld(user.lastPasswordUpdate)
        : false
      this.setState({
        user: {
          data: user,
          state: 'loaded',
        },
        passwordUpdateModalOpen,
      })
    } catch (error) {
      this.setState((prevState) => ({
        user: { ...prevState.user, state: 'error' },
      }))
      throw error
    }
  }

  private async refreshOtpCode() {
    try {
      await AuthApi.refreshOtpCode()
    } catch (error) {
      throw error
    }
  }

  private async resetForgottenPassword(
    values: ForgottenPasswordResetFApiValues,
  ) {
    try {
      await AuthApi.resetForgottenPassword(values)
      this.setState((prevState) => ({
        user: { ...prevState.user },
        resetPassword: {
          status: 'success',
        },
      }))
    } catch (error) {
      this.setState((prevState) => ({
        user: { ...prevState.user },
        resetPassword: {
          status: 'error',
        },
      }))
      throw error
    }
  }

  private signOut() {
    AuthApi.logout()
    this.setState({
      user: { data: undefined, state: 'logout' },
    })
    if (window.location.pathname !== '/sign-in') {
      browserHistory.push('/sign-in')
    }
  }

  private displayRevokedSessionError() {
    this.setState({ sessionRevokedError: true })
  }

  private hideRevokedSessionError() {
    this.setState({ sessionRevokedError: false })
  }

  private register() {}

  private async getProfile() {
    const user: AnyUser = mappings.fromApiAnyUser(await AuthApi.getProfile())
    if (
      !isRoleAmong(user.role, [
        'admin',
        'hospitalAdmin',
        'hospitalDoctor',
        'cityDoctor',
        'inspector',
      ])
    ) {
      this.signOut()
      throw new Error(WRONG_ROLE_ERROR)
    }
    return user
  }

  private displayPasswordUpdateModalAction(open: boolean) {
    this.setState({ passwordUpdateModalOpen: open })
  }
}

export const useAuth = () => React.useContext(AuthContext)
