import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { getTimelineDataFromEntry } from '../../../../utils/timelineUtils'
import { TimelineContent } from './TimelineContent'
import { TimelineDate } from './TimelineDate'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  entryContainer: {
    minWidth: '170px',
    width: '170px',
  },
}))

interface Props {
  entry: HistoryEntry
  isLastEntry?: boolean
}

/**
 * Ensemble des informations d'une entrée d'historique présentée dans <Timeline />
 * C'est ici que les données de l'entrée sont déterminée (e.g. couleur bleu pour patient) et transférées à <TimelineDate /> et <TimelineContent />
 */
export const TimelineEntry: React.FC<Props> = ({ entry, isLastEntry }) => {
  const classes = useStyles()
  const { color, header, title, description } = getTimelineDataFromEntry(entry)
  return (
    <Box className={classes.entryContainer}>
      <TimelineDate color={color} date={entry.date} isLastEntry={isLastEntry} />
      <TimelineContent
        color={color}
        header={header}
        title={title}
        description={description}
        author={entry.doctorNote?.author}
        createdAt={entry.doctorNote?.date}
        link={entry.formPost && `/forms/${entry.formPost.id}`}
      />
    </Box>
  )
}
