import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { i18n } from '../../assets/i18n'
import { settings } from '../../theme/settings'
import { AuthImage } from '../assets/Images'
import { MessageAlert } from '../ui/MessageAlert'

interface Props {
  title: string
  subtitle?: string
  alertMessages?: Array<AlertMessage>
  hideImage?: boolean
}

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  contentContainer: {},
  alertContainer: {
    padding: 0,
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  error: {
    flexBasis: '100%',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: 0,
    borderRadius: 4,
  },
  contentBody: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 700,
  },
  formAndHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: ({ hideImage }) => (hideImage ? '100%' : '50%'),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  formHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  intro: {
    color: theme.palette.primary.main,
    fontSize: 16,
    textAlign: 'center',
  },
  formContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.light,
    fontSize: 24,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.info.dark,
    fontSize: 12,
    textAlign: 'center',
    paddingLeft: '15%',
    paddingRight: '15%',
  },
}))

/**
 * Layout pour les formulaires relatifs à l'authentification (sign in, forgotten password request, forgotten password reset)
 */
export const AuthFormLayout: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { title, subtitle, alertMessages, hideImage = false, children } = props

  return (
    <Container className={classes.root} maxWidth="md">
      <Grid container className={classes.contentContainer}>
        {/* ALERTS */}
        <Container className={classes.alertContainer}>
          <Box className={classes.alert}>
            {alertMessages?.map(
              (alertMessage, index) =>
                alertMessage.showAlert && (
                  <MessageAlert
                    key={`${alertMessage.title}-${index}`}
                    severity={alertMessage.severity}
                    title={alertMessage.title}
                    message={alertMessage.message}
                    onClose={alertMessage.onClose}
                    className={classes.error}
                    autoClose={!alertMessage.persist}
                  />
                ),
            )}
          </Box>
        </Container>

        {/* CONTENT (FORM + IMAGE) */}
        <Container className={classes.content}>
          <Box className={classes.content}>
            <Box className={classes.contentBody}>
              {/* FORM */}
              <Box className={classes.formAndHeaderContainer}>
                <Box className={classes.formHeader}>
                  <Typography gutterBottom className={classes.intro}>
                    {i18n.auth.signin.intro}
                  </Typography>
                  <img
                    src={settings.images.logo}
                    alt="logo chu"
                    width="230px"
                  />
                </Box>

                <Box className={classes.formContainer}>
                  <Typography className={classes.title} variant="h2">
                    {title}
                  </Typography>

                  {subtitle && (
                    <Typography className={classes.subtitle} variant="body1">
                      {subtitle}
                    </Typography>
                  )}

                  {children}
                </Box>
              </Box>

              {/* IMAGE */}
              {!hideImage && <AuthImage />}
            </Box>
          </Box>
        </Container>
      </Grid>
    </Container>
  )
}
