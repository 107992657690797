import {
  createDoctor,
  getDoctors,
  updateDoctor,
} from '../../services/doctors.service'
import { getForms } from '../../services/forms.service'
import {
  createInspector,
  getInspectors,
  updateInspector,
} from '../../services/inspectors.service'
import {
  createPatient,
  getPatients,
  getRiskyPatients,
  updatePatient,
} from '../../services/patients.service'
import {
  fromApiDoctor,
  fromApiInspector,
  fromApiPatient,
  fromApiRiskyPatient,
  toApiDoctorToCreate,
  toApiDoctorToUpdate,
  toApiInspectorToCreate,
  toApiInspectorToUpdate,
  toApiPatientToCreate,
  toApiPatientToUpdate,
} from '../../utils'
import { createAction } from '../app-context'

// PATIENTS
export const loadPatientsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.patients.state = 'loading'
      })

      const apiPatients = await getPatients()
      const apiForms = await getForms()
      const patients = apiPatients.map((patient) => {
        const lastFormPostForm = apiForms.find(
          (apiForm) => apiForm._id === patient.lastFormPost?.formId,
        )
        return fromApiPatient(patient, lastFormPostForm)
      })

      await produceState((draft) => {
        draft.patients.data = patients
        draft.patients.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.patients.state = 'error'
      })
    }
  },
)

export const loadRiskyPatientsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.riskyPatients.state = 'loading'
      })
      const patients = await getRiskyPatients()
      await produceState((draft) => {
        draft.riskyPatients.data = patients.map(fromApiRiskyPatient)
        draft.riskyPatients.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.patients.state = 'error'
      })
    }
  },
)

export const resetPatientsStateAction = createAction(
  () => async ({ produceState }) => {
    await produceState((draft) => {
      draft.patients.state = 'idle'
    })
  },
)

export const addPatientAction = createAction(
  (patient: PatientToCreate) => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.patients.state = 'loading'
      })
      await createPatient(toApiPatientToCreate(patient))
      await produceState((draft) => {
        draft.patients.state = 'created'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.patients.state = 'error'
      })
      throw new Error(e)
    }
  },
)

export const updatePatientAction = createAction(
  (patient: PatientToUpdate) => async ({ getState, produceState }) => {
    const { patients } = getState()
    const patientBeforeUpdate = patients?.data?.find(
      (pat) => pat.id === patient.id,
    )
    const apiPatient = toApiPatientToUpdate(patient)
    if (patientBeforeUpdate?.email === patient.email) {
      delete apiPatient.email
    }
    try {
      await produceState((draft) => {
        draft.patients.state = 'loading'
      })
      await updatePatient(apiPatient)
      await produceState((draft) => {
        draft.patients.state = 'updated'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.patients.state = 'error'
      })
      throw new Error(e)
    }
  },
)

// DOCTORS
export const loadDoctorsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.doctors.state = 'loading'
      })
      const doctors = await getDoctors()
      await produceState((draft) => {
        draft.doctors.data = doctors.map(fromApiDoctor)
        draft.doctors.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.doctors.state = 'error'
      })
    }
  },
)

export const addDoctorAction = createAction(
  (doctor: DoctorToCreate) => async ({ produceState }) => {
    try {
      await createDoctor(toApiDoctorToCreate(doctor))
      await produceState((draft) => {
        draft.doctors.state = 'created'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.doctors.state = 'error'
      })
      throw new Error(e)
    }
  },
)

export const updateDoctorAction = createAction(
  (doctor: DoctorToUpdate) => async ({ getState, produceState }) => {
    const { doctors } = getState()
    const doctorBeforeUpdate = doctors?.data?.find(
      (doc) => doc.id === doctor.id,
    )
    const apiDoctor = toApiDoctorToUpdate(doctor)
    if (doctorBeforeUpdate?.email === doctor.email) {
      delete apiDoctor.email
    }

    try {
      await produceState((draft) => {
        draft.doctors.state = 'loading'
      })
      await updateDoctor(apiDoctor)
      await produceState((draft) => {
        draft.doctors.state = 'updated'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.doctors.state = 'error'
      })
      throw new Error(e)
    }
  },
)

// INSPECTORS
export const loadInspectorsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.inspectors.state = 'loading'
      })
      const inspectors = await getInspectors()
      await produceState((draft) => {
        draft.inspectors.data = inspectors.map(fromApiInspector)
        draft.inspectors.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.inspectors.state = 'error'
      })
    }
  },
)

export const addInspectorAction = createAction(
  (inspector: InspectorToCreate) => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.inspectors.state = 'loading'
      })
      await createInspector(toApiInspectorToCreate(inspector))
      await produceState((draft) => {
        draft.inspectors.state = 'created'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.inspectors.state = 'error'
      })
      throw new Error(e)
    }
  },
)

export const updateInspectorAction = createAction(
  (inspector: InspectorToUpdate) => async ({ getState, produceState }) => {
    const { inspectors } = getState()
    const inspectorBeforeUpdate = inspectors?.data?.find(
      (pat) => pat.id === inspector.id,
    )
    const apiInspector = toApiInspectorToUpdate(inspector)
    if (inspectorBeforeUpdate?.email === inspector.email) {
      delete apiInspector.email
    }
    try {
      await produceState((draft) => {
        draft.inspectors.state = 'loading'
      })
      await updateInspector(apiInspector)
      await produceState((draft) => {
        draft.inspectors.state = 'updated'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.inspectors.state = 'error'
      })
      throw new Error(e)
    }
  },
)
