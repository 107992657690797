import { formatWithOptions } from 'date-fns/fp'
import { fr } from 'date-fns/locale'
import { i18n } from '../assets/i18n'

const formatterWithTime = new Intl.DateTimeFormat('fr-FR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
})
const formatter = new Intl.DateTimeFormat('fr-FR', {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
})

export const formatAllDate = (value: string) =>
  !value
    ? ''
    : /00\/:00\/:00\/.000$/.test(value)
    ? formatter.format(new Date(value.replace(' ', 'T')))
    : formatterWithTime.format(new Date(value.replace(' ', 'T')))

export const formatDate = (value: string) =>
  !value ? '' : formatter.format(new Date(value.replace(' ', 'T')))

export const getAge = (DOB: any) => {
  const today = new Date()
  const birthDate = new Date(DOB)
  let age = today.getFullYear() - birthDate.getFullYear()
  let m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age = age - 1
  }
  if (age === 0) {
    return i18n.patientDetails.ageNotspecified
  } else {
    return `${age}  ${i18n.patientDetails.years}`
  }
}

export const dateToString = formatWithOptions({ locale: fr }, 'dd/MM/yyyy')
