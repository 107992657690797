import { Box, Divider, Drawer, SvgIcon } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import LegalNoticeIcon from '@material-ui/icons/HelpOutline'
import InputIcon from '@material-ui/icons/Input'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { ReactComponent as GestionMedecin } from '../../../assets/icons/MENU_icon_Admin_GestionMedecins.svg'
import { ReactComponent as GestionPatients } from '../../../assets/icons/MENU_icon_Admin_GestionPatients.svg'
import { ReactComponent as Forms } from '../../../assets/icons/MENU_icon_GestionQuestionnaire.svg'
import { ReactComponent as Patients } from '../../../assets/icons/MENU_icon_Patients.svg'
import { useAuth } from '../../../context/auth-context'
import { settings } from '../../../theme/settings'
import { isRoleAmong } from '../../../utils/isRoleAmong'
import { Profile } from './Profile'
import { SidebarNav } from './SidebarNav'

export const SIDEBAR_WIDTH = 240

const commonPages: Array<NavigationPage> = [
  {
    title: i18n.sidebar.common.dashboard,
    href: '/dashboard',
    icon: <DashboardIcon />,
    roles: ['hospitalDoctor', 'cityDoctor'],
  },
  {
    title: i18n.sidebar.common.patients,
    href: '/patients',
    icon: <SvgIcon component={Patients} viewBox="0 0 20 14.286" />,
    roles: ['admin', 'hospitalDoctor', 'cityDoctor', 'inspector'],
  },
  {
    title: i18n.sidebar.common.forms,
    href: '/forms',
    icon: <SvgIcon component={Forms} viewBox="0 0 15 17.497" />,
    roles: ['admin', 'hospitalDoctor', 'cityDoctor', 'inspector'],
  },
]

const adminPages: Array<NavigationPage> = [
  {
    title: i18n.sidebar.admin.patients,
    href: '/admin/patients',
    icon: <SvgIcon component={GestionPatients} viewBox="0 0 20 14.286" />,
  },
  {
    title: i18n.sidebar.admin.doctors,
    href: '/admin/doctors',
    icon: <SvgIcon component={GestionMedecin} viewBox="0 0 15 16.667" />,
  },
  {
    title: i18n.sidebar.admin.inspectors,
    href: '/admin/inspectors',
    icon: <QuestionAnswerIcon width={24} height={24} />,
  },
]

const additionalPages: Array<NavigationPage> = [
  {
    title: i18n.sidebar.legalNotice,
    href: '/legal-notice',
    icon: <LegalNoticeIcon width={24} height={24} />,
  },
  {
    title: i18n.sidebar.logout,
    href: '/sign-in',
    icon: <InputIcon style={{ color: settings.theme.palette.basicGrey }} />,
  },
]

const useStyles = makeStyles<ChuTheme>((theme) => ({
  drawer: {
    width: SIDEBAR_WIDTH,
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
    paddingTop: 24,
  },

  logoutButton: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  logoutIcon: {
    marginRight: 8,
  },
  logoutText: {
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.basicGrey,
  },

  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  svgIcon: {
    color: theme.palette.info.main,
    '&$selected': {
      color: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
  },
  selected: {
    color: theme.palette.primary.light,
    '&$selected': {
      color: theme.palette.primary.light,
      fill: theme.palette.primary.light,
    },
  },
}))

/**
 * Appelé dans MainLayout
 * Sidebar avec des infos de l'utilisateur connecté et des liens de navigation
 */
const Sidebar = () => {
  const { user, signOut } = useAuth()
  const classes = useStyles()

  const showCommonPages = isRoleAmong(user.data?.role, [
    'admin',
    'hospitalDoctor',
    'cityDoctor',
    'inspector',
  ])

  const showAdminPages = isRoleAmong(user.data?.role, [
    'admin',
    'hospitalAdmin',
    'hospitalDoctor',
  ])

  const logoutPage = additionalPages.find(
    (page) => page.title === i18n.sidebar.logout,
  )
  if (logoutPage) {
    logoutPage.onClick = () => signOut()
  }

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      open
      variant="persistent"
    >
      <Box className={classes.root}>
        <Profile />

        {showCommonPages && (
          <>
            <Divider className={classes.divider} />
            <SidebarNav className={classes.nav} pages={commonPages} />
          </>
        )}

        {showAdminPages && (
          <>
            <Divider className={classes.divider} />
            <SidebarNav
              className={classes.nav}
              title={i18n.sidebar.admin.title}
              pages={adminPages}
            />
          </>
        )}

        <Divider className={classes.divider} />
        <Box>
          <SidebarNav className={classes.nav} pages={additionalPages} />
        </Box>
      </Box>
    </Drawer>
  )
}

export default Sidebar
