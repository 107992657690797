import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { QuestionSingleAnswer } from './QuestionSingleAnswer'

interface Props {
  question: TopQuestion
}

/**
 * Appelé dans <FormResult />
 * Affiche les réponses à toutes les sous-questions d'une question de type "top"
 */
export const QuestionSubQuestions = (props: Props) => (
  <Box>
    <Typography variant="h4">{props.question.question}</Typography>
    {props.question.questions.map((question, index) => (
      <QuestionSingleAnswer
        key={`question-${props.question.type}-subquestion-${question.type}-${index}`}
        question={question}
      />
    ))}
  </Box>
)
