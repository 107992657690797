export const orderResultsByRisk = (results: Array<FormResult>) =>
  [...results].sort((resultA, resultB) => {
    if (resultA.score?.risk === resultB.score?.risk) {
      return 0
    }
    if (!resultA.score) {
      return 1
    }
    if (!resultB.score) {
      return -1
    }

    // 1 si le risque B est plus élevé
    if (
      resultB.score.risk === 'high' &&
      (resultA.score.risk === 'medium' || resultA.score.risk === 'low')
    ) {
      return 1
    }
    if (resultB.score.risk === 'medium' && resultA.score.risk === 'low') {
      return 1
    }

    // -1 si le risque A est plus élevé
    return -1
  })
