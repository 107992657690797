const fr = {
  button: {
    validate: 'Valider',
    return: 'retour',
    newAction: "informer d'une nouvelle action",
    validateAppointment: 'valider le rendez-vous',
  },
  sidebar: {
    lastConnection: {
      lastConnection: 'Dernière connexion :',
      dateFormat: 'dd/MM/yyyy à HH:mm',
    },
    common: {
      dashboard: 'Dashboard',
      appointments: 'Mes rendez-vous patients',
      patients: 'Les patients suivis',
      forms: 'Gestion des questionnaires',
      messagingService: 'Messagerie',
    },
    admin: {
      title: 'Accès administrateur',
      patients: 'Gestion des patients',
      doctors: 'Gestion des médecins',
      inspectors: 'Gestion des enquêteurs',
    },
    logout: 'Déconnexion',
    legalNotice: 'Mentions légales',
  },
  auth: {
    signin: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Merci de vous identifier',
      username: {
        label: 'Adresse email',
        required: "L'adresse email est requise",
      },
      password: {
        label: 'Mot de passe',
        required: 'Le mot de passe est requis',
      },
      errors: {
        wrongInputs: {
          title: 'Erreur de connexion',
          message: 'Le couple identifiant/mot de passe ne correspond pas.',
        },
        wrongRole: {
          title: 'Erreur de connexion',
          message:
            "Vous n'avez pas l'autorisation de vous connecter à l'outil de suivi.",
        },
      },
      forgottenPassword: {
        label: 'Mot de passe oublié ?',
        message:
          ' Un mail contenant un lien pour créer un nouveau mot de passe vous sera envoyé',
        connexion: 'Se connecter',
        button: 'Je valide',
      },
      revokedSessionError: {
        title: 'Une seule session est autorisée par utilisateur',
        message:
          "Une autre session a été ouverte avec votre compte, vous avez donc été déconnecté. Si vous n'avez pas ouvert de session récemment, veuillez changer votre mot de passe pour raison de sécurité.",
      },
      button: 'Je me connecte',
    },
    otp: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Merci de vous identifier',
      code: {
        label: "Code d'authentification à 4 chiffres",
        required: "Le code d'authentification est requis",
        format: "Le code d'authentification n'est pas valide",
        length: "Le code d'authentification doit être composé de 4 chiffres",
      },
      errors: {
        wrongInputs: {
          title: 'Erreur de connexion',
          message:
            "Le code d'authentification ne correspond pas ou est expiré.",
        },
      },
      refreshOtpSuccess: {
        title: "Succès de l'opération",
        message: "Un nouveau code d'authentification a été envoyé",
      },
      noOtpCode: {
        label: 'Pas de code ?',
      },
      button: 'Je me connecte',
    },
    forgottenPassword: {
      intro: "Bienvenue sur l'outil de suivi des patients",
      title: 'Mot de passe oublié',
      subtitle:
        'Utilisez de préférence au moins 8 caractères, un chiffre, et un caractère spécial',
      request: {
        errors: {
          title: 'Une erreur est survenue',
          message: 'Aucun utilisateur trouvé avec cette adresse e-mail',
        },
        email: {
          label: 'Adresse e-mail',
          email: "L'adresse e-mail est invalide",
          required: "L'e-mail est requis",
        },
        success: {
          title: "Succès de l'opération",
          message:
            'Un mail vient de vous être envoyé afin de poursuivre le processus',
        },
        button: 'Valider',
        return: 'retour',
      },
      reset: {
        errors: {
          title: 'Une erreur est survenue',
          message: "Le lien de n'est pas ou plus valide",
        },
        success: {
          title: "Succès de l'opération",
          message: 'Le mot de passe a été modifié avec succès',
        },
        password: {
          label: 'Mot de passe',
          required: 'Le mot de passe est requis',
        },
        confirmPassword: {
          label: 'Confirmation du mot de passe',
          required: 'La confirmation du mot de passe est requise',
        },
        notTheSamePassword: 'les deux mots de passe doivent être identiques',
        button: 'Valider',
        return: 'retour',
      },
    },
    passwordUpdateWarningModal: {
      title: 'Mot de passe trop ancien',
      text:
        'Votre mot de passe a été modifié pour la dernière fois il y a plus de 90 jours. Pour des raisons de sécurité, veuilliez le modifier en suivant le processus de réinitialisation de mot de passe.',
      ignore: 'Ignorer',
      toUpdatePassword: 'Mettre à jour',
    },
  },
  patients: {
    table: {
      title: 'Liste des patients',
      headers: {
        actions: 'Actions',
        password: 'Mot de passe',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        codeId: "Numéro d'identification",
        email: 'Email',
        socialSecurityNumber: 'Numéro de sécurité sociale',
        doctors: 'Médecins',
        phoneNumber: 'Téléphone',
        birthDate: 'Date de naissance',
        hospital: 'Hôpital',
        inHospitalDate: "Entrée à l'hôpital",
        outHospitalDate: "Sortie de l'hôpital",
        lastForm: 'Score du dernier questionnaire rempli',
        unknown: 'Inconnu',
      },
      content: {
        noLastForm: 'Aucun questionnaire rempli',
        userNotActivated: 'Compte patient non actif',
      },
    },
    create: {
      title: 'Créer un nouveau patient',
      button: 'Créer la fiche patient',
      buttonUpdate: 'Sauvegarder la fiche patient',
      requiredFieldsIntro: "Les champs marqués d'un * sont obligatoires",
      patient: {
        identity: 'Identité du patient',
        information: 'Informations du contact',
        hospitalization: 'Hospitalisation',
        mechanicalVentilation: 'Ventilation mécanique prolongée :',
        symptoms: "Symptômes d'un patient ",
        duration: 'Nombre de jours',
        score: 'Score',
      },
      errors: {
        title: 'Impossible de créer ce patient',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
      password: {
        label: 'Mot de passe *',
        required: 'Le mot de passe est requis',
      },
      firstName: {
        label: 'Prénom *',
        required: 'Le prénom est requis',
      },
      lastName: {
        label: 'Nom de famille *',
        required: 'Le nom de famille est requis',
      },
      email: {
        label: 'Email *',
        valid: 'Veuillez rentrer un email valide',
        required: "L'email est requis",
      },
      socialSecurityNumber: {
        label: 'Numéro de sécurité sociale',
        length: 'Le numéro de sécurité sociale doit comprendre 15 caractères',
        mustBeValid: "Ce numéro de sécurité sociale n'est pas valide",
      },
      hospitalDepartment: {
        label: 'Service *',
        required: "Veuillez sélectionner un service d'hôpital",
      },
      doctors: {
        label: 'Médecins référents *',
        required: 'Un patient doit avoir un médecin référent',
        noCityDoctorFound:
          "Aucun médecin ne correspond à l'hôpital sélectionné",
      },
      cityDoctor: {
        label: 'Médecin de ville',
      },
      reanimators: {
        label: 'Réanimateur référent *',
        required: 'Un patient doit avoir au moins un réanimateur référent',
      },
      birthDate: {
        label: 'Date de naissance *',
        mustBeValid: "Cette date n'est pas n'est pas valide",
        required: 'Ce champ est requis',
      },
      codeId: {
        label: "Numéro d'identification",
        mustBeAlphanumeric:
          "Le numéro d'identification doit être composé de 5 caractères alphanumériques",
      },
      inHospitalDate: {
        label: "Date d'entrée à l'hôpital *",
        mustBeValid: "Cette date n'est pas n'est pas valide",
        required: 'Ce champ est requis',
      },
      outHospitalDate: {
        label: "Date de sortie de l'hôpital",
        mustBeValid: "Cette date n'est pas n'est pas valide",
        required: 'Ce champ est requis',
      },
    },
    update: {
      title: 'Mettre à jour un profil',
      button: 'Mettre à jour',
      errors: {
        title: 'Impossible de mettre à jour ce patient',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
    },
    actionBar: {
      sendToDoctor: 'Envoyer cette fiche au médecin traitant',
      addAction: 'Ajouter une nouvelle action',
      requiredCityDoctorTooltip:
        'Le médecin traitant de ce patient doit être renseigné.',
    },
    actionDialog: {
      title: "Informer d'une action",
      labelTitle: "Court titre de l'action",
      labelDescription: 'Description (facultatif)',
      labelDate: "Date de l'action",
      titleRequired: 'Le titre est requis',
      titleMaxCharacters: 'Le titre est limité à 50 caractères',
      descriptionMaxCharacters: 'La description est limitée à 250 caractères',
      eventDateRequired: 'La date est requise',
    },
    outOfReanimationPdfDialog: {
      title: 'Envoyer la fiche au médecin traitant',
      mailBodyTab: 'Personnaliser texte',
      downloadPdfTab: 'Pdf de synthèse',
      sendMailTab: 'Envoi mail',
      defaultMailSubject: 'Co-vie-après : Fiche de suivi de',
      defaultMailBodyBeforeChu: `Cher confrère,

Nous suivons ensemble la convalescence de votre patient, suite à son hospitalisation en réanimation au`,
      defaultMailBodyAfterChu: `Nous souhaitons partager avec vous de nouvelles informations le concernant.

Bien à vous
L’équipe du`,
      defaultMailChu: 'CHU',
      downloadPdfDescription:
        'Téléchargez sur votre poste le pdf de synthèse du patient. La création du document peut prendre quelques instants',
      downloadPdfButton: 'Télécharger le PDF',
      sendEmailDescription:
        'N’oubliez pas de joindre le pdf de synthèse à votre mail.',
      sendEmailButton: 'Envoyer le mail',
      previous: 'Retour',
      next: 'Étape suivante',
      finish: 'Terminer',
    },
  },
  patientDetails: {
    backToPatients: 'Retour à la liste des patients',
    monitoringHeaderTitle: 'Fiche de suivi de',
    answersHeaderTitle: 'Réponses de',
    monitoringTab: 'Fiche de suivi du patient',
    answersTab: 'Réponses aux différents questionnaires',
    outOfReanimationPdfName: 'Fiche résumé',
    history: {
      filterActions: 'Filtrer les actions',
      account: 'Compte',
      form: 'Formulaire',
      message: 'Message',
      other: 'Autre',
      all: 'Toutes',
      none: 'Aucune',
      tableTitle: 'Historique',
      date: 'Date',
      action: 'Action',
      details: 'Détails',
      fieldUnknown: 'Inconnu',
      detailsAccountActivatedUser: 'Activation du compte',
      detailsFormCompleted: 'Formulaire complété',
      detailsMessageAskingTeleconsultation: 'Demande de téléconsultation',
    },
    timeline: {
      title: 'Historique des actions médecins / patients',
      ouOfHospital: "Sortie de l'hôpital",
      header: {
        patient: 'Patient',
        CHU: 'CHU',
        cityDoctor: 'Médecin traitant',
        unknown: 'Inconnu',
      },
      titles: {
        userActivated: 'Première connexion',
        formFilled: 'Réponses questionnaires',
        messageSentNewPatient: 'Création du compte',
        messageSentAppointment: 'Demande de téléconsultation',
        moodSent: 'Humeur',
        doctorNote: 'Action médecin',
        appointment: 'Prise en charge consultation',
        unknown: 'Inconnu',
      },
      descriptions: {
        userActivated:
          "Premier jour où le patient s'est connecté à l'outil de suivi.",
        formFilled:
          "Le patient a répondu à un formulaire depuis l'outil de suivi.",
        messageSentNewPatient:
          'Le compte du patient a été créé par un médecin.',
        messageSentAppointment:
          "Le patient a effectué une demande de téléconsultation depuis l'outil de suivi.",
        moodSent: "Le patient a envoyé une humeur depuis l'outil de suivi.",
        appointmentAt: 'Rendez-vous à',
        appointmentWith: 'avec',
        unknown: 'Inconnu',
      },
      tooltip: {
        createdBy: 'Note créée par Dr.',
        createdAt: 'Le',
      },
    },
    years: 'ans',
    ageNotspecified: 'Age non renseigné',
    reanimation: 'en réanimation',
    atHome: 'à la maison',
    inHospitalDate: {
      label: "Date d'entrée ",
    },
    outHospitalDate: {
      label: 'Date de sortie ',
    },
    reanimationDuration: {
      label: 'durée totale en réanimation',
      days: 'jours',
    },
    moods: {
      label: 'dernière humeur déclarée',
      notMoodValue: 'Inconnu',
      lastState: 'dernier état général déclaré',
    },
  },
  doctors: {
    doctors: 'médecins',
    doctorList: 'Gestion des médecins',
    table: {
      title: 'Liste des médecins',
      headers: {
        actions: 'Actions',
        password: 'Mot de passe',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'Email',
        socialSecurityNumber: 'Numéro de sécurité sociale',
        role: 'Type de médecin',
        hospitalDepartments: 'Services',
      },
      notFoundHospital: 'Inconnu',
      unknownDepartments: 'Inconnu',
      cityDoctor: 'Médecin de ville',
    },
    create: {
      title: 'Créer un nouveau médecin',
      doctor: 'Créer une nouvelle fiche médecin',
      button: 'Créer un médecin',
      requiredFieldsIntro: "Les champs marqués d'un * sont obligatoires",
      identity: 'identité du médecin',
      hospitalAndService: 'hôpital et service',
      buttonUpdate: 'Sauvegarder la fiche médecin',
      errors: {
        title: 'Impossible de créer cet utilisateur',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
      password: {
        label: 'Mot de passe *',
        required: 'Le mot de passe est requis',
      },
      firstName: {
        label: 'Prénom *',
        required: 'Le prénom est requis',
      },
      lastName: {
        label: 'Nom de famille *',
        required: 'Le nom de famille est requis',
      },
      email: {
        label: 'Email *',
        valid: 'Veuillez rentrer un email valide',
        required: "L'email est requis",
      },
      phoneNumber: {
        label: 'Numéro de téléphone',
        valid: 'Veuillez rentrer un numéro de téléphone valide',
      },
      hospital: {
        label: 'Hôpital *',
        required: "Veuillez sélectionner au moins un service d'un hôpital",
        loading: 'Chargement de la liste des hôpitaux...',
        add: 'Ajouter un nouvel hôpital',
      },
      hospitalDepartments: {
        label: 'Services',
        required: "Veuillez sélectionner au moins un service d'un hôpital",
      },
      role: {
        label: 'Type de médecin *',
        required: 'Vous devez choisir type de médecin',
        admin: 'Admin',
        patient: 'Patient',
        hospitalDoctor: "Médecin d'hôpital",
        cityDoctor: 'Médecin de ville',
      },
    },
    update: {
      title: 'Mettre à jour un profil',
      button: 'Mettre à jour',
      errors: {
        title: 'Impossible de mettre à jour cet utilisateur',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
    },
    medicated: {
      designation: 'Médecin traitant',
      reanimator: 'Réanimateur référent',
      doctorNotFound: 'Renseigner un médecin traitant',
      reanimatorNotFound: 'Renseigner un réanimateur référent',
    },
    patient: {
      account: 'Compte patient activé',
      since: 'depuis le',
      inactive: 'Compte patient inactif',
      sendPasswordMessage: {
        title: 'Renvoyer le lien au patient',
        description:
          'Cette action va renvoyer le mail envoyé automatiquement au patient lors de la création de son compte. Continuer ?',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        success: {
          title: "Succès de l'opération",
          message:
            'Le mail envoyé automatiquement au patient lors de la création de son compte a été renvoyé avec succès',
        },
        error: {
          title: 'Une erreur est survenue',
          message:
            "Le mail envoyé automatiquement au patient lors de la création de son compte n'a pas pu être renvoyé",
        },
      },
    },
  },
  inspectors: {
    create: {
      title: 'Créer un nouvel enquêteur',
      button: 'Créer la fiche enquêteur',
      buttonUpdate: 'Sauvegarder la fiche enquêteur',
      requiredFieldsIntro: "Les champs marqués d'un * sont obligatoires",
      inspector: {
        identity: "Identité de l'enquêteur",
        information: 'Informations du contact',
      },
      errors: {
        title: 'Impossible de créer cet enquêteur',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
      password: {
        label: 'Mot de passe *',
        required: 'Le mot de passe est requis',
      },
      firstName: {
        label: 'Prénom *',
        required: 'Le prénom est requis',
      },
      lastName: {
        label: 'Nom de famille *',
        required: 'Le nom de famille est requis',
      },
      email: {
        label: 'Email *',
        valid: 'Veuillez rentrer un email valide',
        required: "L'email est requis",
      },
    },
    update: {
      title: 'Mettre à jour un profil',
      button: 'Mettre à jour',
      errors: {
        title: 'Impossible de mettre à jour cet enquêteur',
        message:
          'Une erreur est survenue. Vérifiez que les champs requis sont correctement remplis puis réessayez.',
      },
    },
    table: {
      title: 'Liste des enquêteurs',
      headers: {
        actions: 'Actions',
        password: 'Mot de passe',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        email: 'Email',
        unknown: 'Inconnu',
      },
    },
  },
  notfound: {
    title: "404: La page que vous cherchez n'est pas ici",
    description:
      "Vous avez soit testé une fausse route ou vous êtes arrivé ici par erreur. Dans tous les cas, essayez d'utiliser la navigation.",
  },
  formResult: {
    noResult: "Il n'y a pas de résultat sélectionné",
    score: 'Score:',
    noScore: 'Pas de cotation spécifique à ce stade',
  },
  surveyBanner: {
    surveyManagement: 'Gestion des questionnaires',
    surveyResponse: 'Réponses au questionnaire',
    surveyNumberStart: 'Vous avez',
    surveyNumberCounter: 'nouveaux questionnaires',
    surveyNumberEnd: 'en attente de traitement',
    backToSurvey: 'Retour à la gestion des questionnaires',
    surveyNamePrefix: 'Questionnaire',
  },
  doctorBanner: {
    createNewDoctorTitle: 'Créer une fiche médecin',
    goBack: 'Retour à la gestion des médecins',
    updateDoctorTitle: 'Mise à jour des informations du Dr.',
  },
  patientBanner: {
    patientManagment: 'Les patients suivis',
    patientNumberCounter: 'patients suivis',
    patientList: 'Gestion des patients',
    patient: 'patients',
    patientInside: 'en base',
    createPatient: 'Créer une nouvelle fiche patient',
    goBack: 'Retour à la gestion des patients',
    createNewPatientTitle: 'Créer une fiche patient',
    updatePatientTitle: 'Mise à jour des informations du patient',
  },
  inspectorsBanner: {
    inspectorsList: 'Gestion des enquêteurs',
    inspectors: 'enquêteurs',
    inspectorsInside: 'en base',
    createInspector: 'Créer une nouvelle fiche enquêteur',
    goBack: 'Retour à la gestion des enquêteurs',
    createNewInspectorTitle: 'Créer une fiche enquêteur',
    updateInspectorTitle: "Mise à jour des informations de l'enquêteur",
  },
  patientTable: {
    patientLink: 'Voir la fiche patient',
    linkPatientDetails: 'Voir sa fiche de suivi',
    linkAnswerWithPatient: 'Répondre avec le patient',
  },
  formPostsTable: {
    formLink: 'Voir le questionnaire',
  },
  links: {
    modification: 'modifier',
  },
  notDeclared: 'non déclaré',
  progression: 'Progression',
  questionnaireScoreTooltip: {
    Default: `
      <div>
        <p>Pas de cotation spécifique à ce stade.</p>
      </div>
    `,
    CheckUp: `
      <div>
        <h3>SF-36</h3>
        <p>Pas de cotation spécifique à ce stade.</p>
      </div>
    `,
    AtTheMoment: `
      <div>
        <h3>STAI, anxieté</h3>
        <p>Le STAI (State-Trait Anxiety Inventory) est un questionnaire d'auto-évaluation de l’anxiété état. Le STAI évalue le niveau d'anxiété d'une personne à un moment X donné, dans une situation X. Le test se compose d’une série de 20 questions, selon 4 degrés d’intensité. Chaque item a un score allant de 1 à 4 (4 étant le degré le plus fort d’anxiété).</p>
        <p>Cotation :</p>
        <p>• Presque jamais = 1, parfois = 2, Souvent = 3, Toujours = 4</p>
        <p>• Attention 10 items sont inversés (items 1, 2, 5, 8, 10, 11, 15, 16, 19, 20) et donc avec une cotation inversée Presque jamais = 4, parfois = 3, Souvent = 2, Toujours = 1</p>
        <p><= 35 à Très faible</p>
        <p>Avis du psychiatre si score total >= 56</p>
      </div>
      <div>
        <h3>Scores d’anxiété</h3>
        <p>• > 65 Très élevée</p>
        <p>• 56-65 Elevée</p>
        <p>• 46-55 Moyenne</p>
        <p>• 36-45 Faible</p>
        <p>• ≤ 35 Très faible</p>
        <p>• Avis du psychiatre si score total ≥ 56</p>
      </div>
    `,
    MyFeelings: `
      <div>
        <h3>Beck Depression Inventory (BDI)</h3>
        <p>Chaque item est constitué de 4 phrases correspondant à 4 degrés d'intensité croissante d'un symptôme, de 0 à 3. Dans le dépouillement, il faut tenir compte de la cote la plus forte choisie pour une même série. La note globale est obtenue en additionnant les scores des 13 items. L'étendue de l'échelle va de 0 à 39. Plus la note est élevée, plus le sujet est déprimé.</p>
      </div>
      <div>
        <h3>Scores d’anxiété</h3>
        <p>• 0-4 : Pas de dépression</p>
        <p>• 4-7 : Dépression légère</p>
        <p>• 8-15 : Dépression modérée</p>
        <p>• ≥ 16 : Dépression sévère</p>
        <p>• Avis du psychiatre si score total ≥ 10</p>
      </div>
    `,
    MyMoral: `
      <div>
        <h3>Impact of Event Scale (IES), état de stress post-traumatique</h3>
        <p>• Pas du tout = 0</p>
        <p>• Rarement = 1</p>
        <p>• Parfois = 3</p>
        <p>• Souvent = 5</p>
        <p>• PTSD si score ≥ 42</p>
        <p>• Avis du psychiatre si score total ≥ 42</p>
      </div>
    `,
    EpworthLadder: `
    <div>
      <h3>L'échelle de somnolence d'Epworth</h3>
      <p>Résultat supérieur à 10 est généralement accepté pour indiquer une comnolence diurne excessive</p>
    </div>
    `,
    PichotLadder: `
    <div>
      <h3>L'échelle de fatigue de Pichot</h3>
      <p>Résultat supérieur à 22 est en faveur d'une fatigue excessive, vous souffrez peut être d'un sommeil inefficace</p>
    </div>`,
    MySleep: `
    <div>
      <h3>Questionnaire sur le sommeil</h3>
      <p>Si résultat > 20 : regarder les échelles de Pichot et Epworth</p>
    </div>`,
  },
  doctorBloc: {
    namePrefix: 'Dr.',
  },
  dashboard: {
    table: {
      title: {
        patientsTitle: 'Patients à risques',
        scoreTitle: 'Scores inquiétants',
        appointment: 'Demandes de rdv',
        histories: 'Historique des demandes',
        lastAppointment: 'Mon prochain rdv patient',
      },
      appointment: {
        namePrefix: 'Dr.',
        ask: 'Rdv demandé le ',
        over: 'Prendre en charge',
        consultation: 'Consult.',
        teleconsultation: 'TLC',
        dayHospital: 'H. de jour',
        unknown: 'Inconnu',
        upcoming: 'En cours',
        passed: 'Résolu',
        dialog: {
          title: "Prise en charge d'un patient",
          contact: 'Coordonnées',
          appointment: 'Rendez-vous',
          errorTitle: 'Impossible de prendre en charge le rendez-vous',
          errorMessage:
            'Une erreur est survenue. Vérifiez votre connexion internet.',
        },
      },
      history: {
        namePrefix: 'Dr.',
      },
      lastAppointment: {
        title: 'Mes rendez-vous patient de la semaine',
        noAppointments: 'Aucun rendez-vous prévu',
      },
    },
  },
  legalNotice: {
    intro: "Bienvenue sur l'outil de suivi des patients",
    title: 'Mentions légales',
    presentation: {
      introductionBeforeUrl:
        'En vertu de l’article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site',
      introductionAfterUrl:
        'l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :',
      ownerTitle: 'Propriétaire',
      ownerContent: 'CHRU Hôpitaux de Tours – SIRET 26370018900016 – Tours',
      creatorTitle: 'Créateur',
      creatorContent: 'LA MOBILERY - http://lamobilery.fr/',
      publicationTitle: 'Responsable publication',
      publicationContent: 'LA MOBILERY',
      webmasterTitle: 'Webmaster',
      webmasterContent: 'Fabrice Bellotti – contact@lamobilery.fr',
      hostingTitle: 'Hébergeur',
      hostingContentAdress:
        'ATE – Avenir Télématique, 21 Avenue de la Créativité 59650 Villeneuve d’Ascq FRANCE',
      hostingContentContact: '+33 (0)3 28 800 300, contactweb@ate.info',
    },
  },
  footer: {
    homeLink: 'Accueil',
    legalNoticeLink: 'Mentions légales',
  },
  dataTables: {
    patientsFollowed: {
      title: 'patients-suivis',
    },
    patients: {
      title: 'patients',
    },
    doctors: {
      title: 'médecins',
    },
    inspectors: {
      title: 'enquêteurs',
    },
  },
  ui: {
    addressField: {
      label: 'Adresse',
      maxlength: (addressMaxlength: number) =>
        `L'adresse doit faire maximum ${addressMaxlength} caractères.`,
    },
    phoneNumberField: {
      requiredLabel: 'Téléphone *',
      optionalLabel: 'Téléphone',
      length: 'Le numéro de téléphone doit comprendre X caractères',
      required: 'Le numéro de téléphone est requis',
      mustBeValid: "Ce numéro de téléphone n'est pas valide",
      placehodler: '0699887766',
    },
  },
}

export default fr
