import { Field } from 'formik'
import React from 'react'
import { string } from 'yup'
import { i18n } from '../../assets/i18n'
import { InputField } from './Inputfield'

interface AddressFieldProps {
  onChange: (e: React.ChangeEvent) => void
  onBlur: (e: React.FocusEvent) => void
}

const ADDRESS_MAXLENGTH = 200
export const addressValidationSchema = string()
  .optional()
  .max(ADDRESS_MAXLENGTH, i18n.ui.addressField.maxlength(ADDRESS_MAXLENGTH))

export const AddressField = ({ onChange, onBlur }: AddressFieldProps) => (
  <Field
    name="address"
    label={i18n.ui.addressField.label}
    type="text"
    onChange={onChange}
    onBlur={onBlur}
    component={InputField}
  />
)
