/**
 * A partir d'un user "user" et d'une liste d'hôpitaux "hospitals", renvoie la liste des hôpitaux auxquels "user" peut avoir accès selon son rôle
 * hospitalAdmin : uniquement son hôpital
 * hospitalDoctor : tous les hôpitaux pour lequel le médecin appartient à au moins un service
 * admin : tous les hôpitaux
 * tous les autres rôles : aucun hôpital (pour l'instant c'est juste le cas du cityDoctor)
 */
export const getAvailableHospitals = (
  user: AnyUser | undefined,
  hospitals: Array<Hospital> | undefined,
): Array<Hospital> => {
  // Pas de données pendant le chargement
  if (!user || !hospitals) {
    return []
  }

  // hospitalAdmin
  if (user.role === 'hospitalAdmin') {
    return hospitals.filter((hospital) => {
      const hospitalId =
        typeof user?.hospital === 'string' ? user?.hospital : user?.hospital?.id
      return hospital.id === hospitalId
    })
  }

  // hospitalDoctor
  if (user.role === 'hospitalDoctor') {
    return hospitals.filter((hospital) => {
      return user?.hospitalDepartments?.some((hospitalDepartment) => {
        return (
          typeof hospitalDepartment.hospital !== 'string' &&
          hospitalDepartment.hospital.id === hospital.id
        )
      })
    })
  }

  // admin
  if (user.role === 'admin') {
    return hospitals
  }

  return []
}
