/**
 * Permet de savoir si un role "role" fait partie d'une liste de roles "amongRoles"
 * Utilisé pour facilité les accès ou les restrictions à certains rôles
 */
export const isRoleAmong = (
  role: Role | undefined,
  amongRoles: Array<Role>,
): boolean => {
  if (!role) {
    return false
  }
  return amongRoles.some((amongRole) => role === amongRole)
}
