import { Button, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box/Box'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  containerBar: {
    width: 'calc(100% - 239px)',
    position: 'fixed',
    left: 239,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    background: '#696767CC',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: 0,
    },
  },
  button: {
    textTransform: 'uppercase',
    textdecoration: 'none',
    color: theme.palette.white,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 4,
    boxShadow: '0px 2px 2px #0000004D',
    '&:first-child': {
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export const BottomActionBar = ({
  firstButton,
  secondButton,
}: {
  firstButton: {
    label: string
    disabled: boolean
    tooltip: string | null
    onClick: () => void
  }
  secondButton: {
    label: string
    onClick: () => void
  }
}) => {
  const classes = useStyles()

  const FirstButton = (
    <Button
      onClick={firstButton.onClick}
      className={classes.button}
      disabled={firstButton.disabled}
    >
      {firstButton.label}
    </Button>
  )

  return (
    <Box pt={3} pb={3} className={classes.containerBar}>
      {firstButton.label &&
        (firstButton.tooltip ? (
          <Tooltip title={firstButton.tooltip} placement="top" arrow>
            <span>{FirstButton}</span>
          </Tooltip>
        ) : (
          FirstButton
        ))}
      {secondButton.label && (
        <Button onClick={secondButton.onClick} className={classes.button}>
          {secondButton.label}
        </Button>
      )}
    </Box>
  )
}
