import React, { Fragment, useEffect, useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import { MainLayout } from '../components/layouts/MainLayout'
import { MinimalLayout } from '../components/layouts/MinimalLayout'
import { Dashboard } from '../components/screens/Dashboard/Dashboard'
import { CreateDoctor } from '../components/screens/Doctors/CreateDoctor'
import { Doctors } from '../components/screens/Doctors/Doctors'
import { UpdateDoctor } from '../components/screens/Doctors/UpdateDoctor'
import { ForgottenPasswordRequest } from '../components/screens/ForgottenPassword/ForgottenPasswordRequest'
import { ForgottenPasswordReset } from '../components/screens/ForgottenPassword/ForgottenPasswordReset'
import { FormResult } from '../components/screens/FormResult/FormResult'
import { Forms } from '../components/screens/Forms/Forms'
import { CreateInspector } from '../components/screens/Inspectors/CreateInspector'
import { Inspectors } from '../components/screens/Inspectors/Inspectors'
import { UpdateInspector } from '../components/screens/Inspectors/UpdateInspector'
import { LegalNotice } from '../components/screens/LegalNotice/LegalNotice'
import { NotFound } from '../components/screens/NotFound/NotFound'
import { Otp } from '../components/screens/Otp/Otp'
import { PatientDetails } from '../components/screens/PatientDetails/PatientDetails'
import { CreatePatient } from '../components/screens/Patients/CreatePatient'
import { Patients } from '../components/screens/Patients/Patients'
import { UpdatePatient } from '../components/screens/Patients/UpdatePatient'
import { PatientsFollowed } from '../components/screens/PatientsFollowed/PatientsFollowed'
import { SignIn } from '../components/screens/SignIn/SignIn'
import { resetAppContextAction } from '../context/actions/global'
import { useAppContext } from '../context/app-context'
import { useAuth } from '../context/auth-context'
import { isRoleAmong } from '../utils/isRoleAmong'
import { CustomRoute } from './CustomRoute'

const Routes = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false)
  const [isloading, setIsLoading] = useState<boolean>(true)
  const [userState, setUserState] = useState<DataState | null>(null)
  const [isAuthWithOtp, setIsAuthWithOtp] = useState<boolean>(false)
  let { user } = useAuth()
  const { dispatch } = useAppContext()

  useEffect(() => {
    if (user.state !== userState) {
      setIsLoading(true)
    }
    if (!user.data && (user.state === 'error' || user.state === 'logout')) {
      setIsAuth(false)
      setIsLoading(false)
      setUserState(user.state)
    }
    if (
      user.data &&
      user.data.otpRequired &&
      !user.data.otpConfirmed &&
      (user.state === 'loaded' || user.state === 'error')
    ) {
      setIsAuth(true)
      setIsAuthWithOtp(false)
      setIsLoading(false)
      setUserState(user.state)
    }
    if (
      (user.data &&
        user.data.otpRequired &&
        user.data.otpConfirmed &&
        user.state === 'loaded') ||
      (user.data && !user.data.otpRequired)
    ) {
      setIsAuth(true)
      setIsAuthWithOtp(true)
      setIsLoading(false)
      setUserState(user.state)
    }
    return () => {}
  }, [user.data, user.state, userState, dispatch])

  useEffect(() => {
    if (!isAuth) {
      dispatch(resetAppContextAction())
    }
  }, [dispatch, isAuth])

  return (
    <Fragment>
      {(!isAuth || (isAuth && !isAuthWithOtp)) && (
        <Switch>
          <CustomRoute
            component={SignIn}
            exact
            layout={MinimalLayout}
            path="/sign-in"
          />
          <CustomRoute
            component={ForgottenPasswordRequest}
            exact
            layout={MinimalLayout}
            path="/forgotten-password"
          />
          <CustomRoute
            component={ForgottenPasswordReset}
            exact
            layout={MinimalLayout}
            path="/forgotten-password/:token"
          />
          <CustomRoute
            component={LegalNotice}
            exact
            layout={MinimalLayout}
            path="/legal-notice"
          />
          {!isAuth && <Redirect to="/sign-in" />}
          <CustomRoute
            component={Otp}
            layout={MinimalLayout}
            exact
            path="/otp-validation"
          />
          {!isloading && <Redirect to="/sign-in" />}
        </Switch>
      )}

      {isAuth && isAuthWithOtp && (
        <Switch>
          <Redirect from="/otp-validation" to="/" />
          <Redirect from="/sign-in" to="/" />
          {!isRoleAmong(user.data?.role, [
            'admin',
            'hospitalAdmin',
            'inspector',
          ]) && <Redirect exact from="/" to="/dashboard" />}
          {isRoleAmong(user.data?.role, ['admin', 'inspector']) && (
            <Redirect exact from="/" to="/patients" />
          )}
          {isRoleAmong(user.data?.role, ['hospitalAdmin']) && (
            <Redirect exact from="/" to="/admin/doctors" />
          )}

          <CustomRoute
            component={Dashboard}
            exact
            layout={MainLayout}
            path="/dashboard"
            roles={['hospitalDoctor', 'cityDoctor']}
          />

          {/* ADMIN PATIENTS */}
          <CustomRoute
            component={Patients}
            exact
            layout={MainLayout}
            path="/admin/patients"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={CreatePatient}
            exact
            layout={MainLayout}
            path="/admin/patients/create"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={UpdatePatient}
            exact
            layout={MainLayout}
            path="/admin/patients/edit/:id"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={PatientsFollowed}
            exact
            layout={MainLayout}
            path="/admin/patients/:id/details/:tab"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />

          {/* ADMIN DOCTORS */}
          <CustomRoute
            component={Doctors}
            exact
            layout={MainLayout}
            path="/admin/doctors"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={CreateDoctor}
            exact
            layout={MainLayout}
            path="/admin/doctors/create"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={UpdateDoctor}
            exact
            layout={MainLayout}
            path="/admin/doctors/edit/:id"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />

          {/* ADMIN INSPECTORS */}
          <CustomRoute
            component={Inspectors}
            exact
            layout={MainLayout}
            path="/admin/inspectors"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={CreateInspector}
            exact
            layout={MainLayout}
            path="/admin/inspectors/create"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />
          <CustomRoute
            component={UpdateInspector}
            exact
            layout={MainLayout}
            path="/admin/inspectors/edit/:id"
            roles={['admin', 'hospitalDoctor', 'hospitalAdmin']}
          />

          {/* PATIENTS FOLLOWED */}
          <CustomRoute
            component={PatientsFollowed}
            exact
            layout={MainLayout}
            path="/patients"
            roles={['admin', 'hospitalDoctor', 'cityDoctor', 'inspector']}
          />
          <CustomRoute
            component={PatientDetails}
            layout={MainLayout}
            path="/patients/:id"
            roles={['admin', 'hospitalDoctor', 'cityDoctor', 'inspector']}
          />

          {/* FORMS */}
          <CustomRoute
            component={Forms}
            exact
            layout={MainLayout}
            path="/forms"
            roles={['admin', 'hospitalDoctor', 'cityDoctor', 'inspector']}
          />
          <CustomRoute
            component={FormResult}
            exact
            layout={MainLayout}
            path="/forms/:id"
            roles={['admin', 'hospitalDoctor', 'cityDoctor', 'inspector']}
          />

          {/* OTHERS */}
          <CustomRoute
            component={LegalNotice}
            exact
            layout={MainLayout}
            path="/legal-notice"
          />
          <CustomRoute
            component={NotFound}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          <Redirect to="/not-found" />
        </Switch>
      )}
    </Fragment>
  )
}

export default Routes
