import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ErrorMessage, FieldProps } from 'formik'
import React, { useState } from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  errorMsg: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.secondary.light,
  },
}))

export const PasswordFieldWithVisibility = ({
  field,
  form: { touched, errors },
  name,
  label,
  ...props
}: FieldProps & InputFieldProps) => {
  const classes = useStyles()

  const [displayPwd, setDisplayPwd] = useState(false)

  const handleClickShowPassword = () => {
    setDisplayPwd(!displayPwd)
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <TextField
        {...field}
        {...props}
        type={displayPwd ? 'text' : 'password'}
        label={label}
        variant="outlined"
        className={classes.textField}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                color="secondary"
              >
                {displayPwd ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {touched[field.name] && errors[field.name] && (
        <ErrorMessage
          name={field.name}
          component="span"
          className={classes.errorMsg}
        />
      )}
    </Box>
  )
}
