import { settings } from '../settings'

export default {
  label: {
    '&.Mui-disabled': {
      color: settings.theme.palette.black,
      opacity: 0.26,
    },
  },
}
