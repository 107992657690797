import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ChuTheme } from '../../@types/theme'

export const HtmlTooltip = withStyles((theme: ChuTheme) => ({
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  arrow: {
    color: theme.palette.white,
  },
}))(Tooltip)
