import { createMuiTheme } from '@material-ui/core'
import overrides from './overrides'
import { settings } from './settings'
import typography from './typography'

const palette = settings.theme.palette

const theme = createMuiTheme({
  palette: palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})
export default theme
