import { Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { format } from 'date-fns'
import { formatWithOptions } from 'date-fns/fp'
import { fr } from 'date-fns/locale'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useEffect, useMemo, useState } from 'react'
import { i18n } from '../../../assets/i18n'
import {
  loadDoctorsAction,
  loadPatientsAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import { settings } from '../../../theme/settings'
import { useUserTableStyles } from '../../../theme/styles/userTableStyles'
import { DATA_TABLE_OPTIONS } from '../../../utils/dataTableOptions'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import { AddPersonIcon } from '../../assets/Icons'
import { HeroBanner } from '../../ui/HeroBanner'
import { HeroCounterElements } from '../../ui/HeroCounterElements'
import { LoadingPane } from '../../ui/LoadingPane'

const dateToString = formatWithOptions({ locale: fr }, 'dd/MM/yyyy')

/**
 * Liste des patients avec leurs information
 * Permet d'accéder à la création et la modification de patient
 */
export const Patients = () => {
  const classes = useUserTableStyles()
  const { dispatch, doctors, patients } = useAppContext()

  const [lines, setLines] = useState<Array<Patient>>(patients.data || [])

  useEffect(() => {
    if (!patients.data) {
      dispatch(loadPatientsAction())
    }
    if (!doctors.data) {
      dispatch(loadDoctorsAction())
    }
  }, [dispatch, patients.data, doctors.data])

  useEffect(() => {
    patients.data && setLines(patients.data)
  }, [patients.data])

  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/admin/patients/edit/${id}`)
  }

  const formattedLines = useMemo(
    () =>
      lines.map((line) => ({
        ...line,
        birthDate: new Date(line.birthDate),
        inHospitalDate: new Date(line.inHospitalDate),
        outHospitalDate: new Date(line.outHospitalDate),
      })),
    [lines],
  )

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastName',
      label: i18n.patients.table.headers.lastName,
      options: {
        customBodyRender: (value: string) => {
          return <span className={classes.customCellLastName}>{value}</span>
        },
      },
    },
    {
      name: 'firstName',
      label: i18n.patients.table.headers.firstName,
      options: {
        customBodyRender: (value: string) => {
          return <span className={classes.customCellRender}>{value}</span>
        },
      },
    },
    {
      name: 'socialSecurityNumber',
      label: i18n.patients.table.headers.socialSecurityNumber,
      options: {
        customBodyRender: (value: string) => {
          return <span className={classes.customCellRender}>{value}</span>
        },
      },
    },
    {
      name: 'birthDate',
      label: i18n.patients.table.headers.birthDate,
      options: {
        filter: false,
        customBodyRender: (value: Date) => {
          return dateToString(value)
        },
      },
    },
    {
      name: 'email',
      label: i18n.patients.table.headers.email,
      options: {
        customBodyRender: (value: string) => (
          <span className={classes.nowrap}>{value}</span>
        ),
      },
    },
    {
      name: 'phoneNumber',
      label: i18n.patients.table.headers.phoneNumber,
      options: {
        customBodyRender: (value: string | undefined) =>
          value ? (
            <span className={classes.nowrap}>{formatPhoneNumber(value)}</span>
          ) : (
            ''
          ),
      },
    },
    {
      name: 'inHospitalDate',
      label: i18n.patients.table.headers.inHospitalDate,
      options: {
        filter: false,
        empty: true,
        customBodyRender: (value: Date) => {
          return dateToString(value)
        },
      },
    },
    {
      name: 'outHospitalDate',
      label: i18n.patients.table.headers.outHospitalDate,
      options: {
        filter: false,
        empty: true,
        customBodyRender: (value: Date) => {
          return dateToString(value)
        },
      },
    },

    {
      name: '',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          const patientId: string = tableMeta.rowData[0]
          return (
            <Link
              component="button"
              onClick={() => handleOnEditClick(patientId)}
              style={{
                color: settings.theme.palette.primary.light,
                fontSize: 14,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
              }}
            >
              {i18n.links.modification} <KeyboardArrowRight />
            </Link>
          )
        },
      },
    },
  ]

  if (patients.state === 'loading') {
    return <LoadingPane />
  }

  const handleClickOnFloatingButton = () => {
    browserHistory.push('/admin/patients/create')
  }

  return (
    <Grid container justify="center" className={classes.root}>
      {/* HEADER */}
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.patientBanner.patientList}
      />

      {/* TITLE & ADD BUTTON */}
      <Box className={classes.titleContainer}>
        <HeroCounterElements
          startString={i18n.surveyBanner.surveyNumberStart}
          counter={lines.length}
          counterDesc={i18n.patientBanner.patient}
          endString={i18n.patientBanner.patientInside}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<AddPersonIcon alt={i18n.patientBanner.createPatient} />}
          onClick={handleClickOnFloatingButton}
        >
          {i18n.patientBanner.createPatient}
        </Button>
      </Box>

      {/* DATA TABLE */}
      <Grid item xs={12}>
        <div className={classes.tableContainer}>
          <MUIDataTable
            title=""
            columns={columns}
            data={formattedLines}
            options={{
              ...DATA_TABLE_OPTIONS,
              downloadOptions: {
                filename: `${i18n.dataTables.patients.title}-${format(
                  new Date(),
                  'yyyy-MM-dd',
                )}.csv`,
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  )
}
