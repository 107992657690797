import { Field } from 'formik'
import React from 'react'
import { string } from 'yup'
import { i18n } from '../../assets/i18n'
import { regexes } from '../../utils/regexes'
import { InputField } from './Inputfield'

interface PhoneNumberFieldProps {
  label: string
  onChange: (e: React.ChangeEvent) => void
  onBlur: (e: React.FocusEvent) => void
}

export const phoneNumberValidationSchema = string().matches(
  regexes.phoneNumber,
  i18n.ui.phoneNumberField.mustBeValid,
)

export const PhoneNumberField = ({
  label,
  onChange,
  onBlur,
}: PhoneNumberFieldProps) => (
  <Field
    name="phoneNumber"
    label={label}
    type="text"
    onChange={onChange}
    onBlur={onBlur}
    component={InputField}
  />
)
