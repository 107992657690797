import { Box, Link, Typography } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  rightBox: {
    marginLeft: 40,
    paddingTop: 20,
  },
  linkNav: {
    color: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  svgSize: {
    fontSize: '12px',
  },
  title: {
    color: theme.palette.primary.light,
    marginTop: '15px',
    fontWeight: 400,
  },
}))

export const HeroBanner = ({
  imgPath,
  title,
  goBack,
  goBackTitle,
  patientName,
}: {
  imgPath?: string
  title: string
  goBack?: () => void
  goBackTitle?: string
  patientName?: string
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box>
        <img src={imgPath} alt={title} />
      </Box>
      <Box className={classes.rightBox}>
        {goBack && (
          <Link onClick={goBack} component="button" className={classes.linkNav}>
            <ArrowBackIosIcon className={classes.svgSize} /> {goBackTitle || ''}
          </Link>
        )}
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h3">{patientName}</Typography>
      </Box>
    </Box>
  )
}
