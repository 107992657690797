import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import frLocale from 'date-fns/locale/fr'
import React from 'react'
import { Router } from 'react-router-dom'
import './assets/scss/index.scss'
import { AppProvider } from './context/app-context'
import { AuthProvider } from './context/auth-context'
import browserHistory from './router/history'
import Routes from './router/Routes'
import theme from './theme'

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <AuthProvider>
          <AppProvider>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </AppProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
