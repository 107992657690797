import { Api } from './api'

export async function getUserHistory(id: string) {
  const response = await Api.get<ApiHistoryEntry[]>(`/users/${id}/history`)
  return response.data
}

export async function createDoctorNote(note: ApiCreateDoctorNote) {
  const response = await Api.post<ApiCreateDoctorNote>(
    `/history/doctor-note`,
    note,
  )
  return response.data
}
