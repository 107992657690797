import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber'
import { HospitalIcon, PencilIcon } from '../../../assets/Icons'

const doctorStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  updateLink: {
    fontSize: 14,
    cursor: 'pointer',
  },
  doctorMedicated: {
    '& h5, a': {
      textTransform: 'uppercase',
      color: theme.palette.paperInformation,
    },
  },
  medicatedInformation: {
    '& h5': {
      lineHeight: 1.2,
      fontWeight: theme.typography.fontWeightMedium,
    },
    padding: 'unset',
  },
  fullName: {
    color: theme.palette.primary.light,
    fontSize: 24,
    padding: 'unset',
  },
  hospitalContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
  },
  hospital: {
    fontSize: 18,
    color: theme.palette.info.dark,
    fontWeight: theme.typography.fontWeightRegular,
  },
  email: {
    fontSize: 18,
    textDecoration: 'underline',
  },
  addressOrPhoneNumber: {
    fontSize: 18,
    color: theme.palette.primary.light,
  },
}))

export const DoctorBloc = ({
  reference,
  fullName,
  onClick,
  onClickDisabled,
  address,
  phoneNumber,
  email,
  hospital,
  notFound,
  disabled,
}: {
  reference: string
  fullName?: string | JSX.Element
  onClick: () => void
  onClickDisabled?: () => void
  address?: string
  phoneNumber?: string
  email?: string
  hospital?: string
  notFound?: string
  disabled?: boolean
}) => {
  const classes = doctorStyles()
  return (
    <Paper className={classes.root} elevation={0}>
      <Box
        display="flex"
        justifyContent="space-between"
        className={classes.doctorMedicated}
      >
        <Typography variant="h5">{reference}</Typography>
        {fullName && (
          <Link onClick={onClick} className={classes.updateLink}>
            {i18n.links.modification}
          </Link>
        )}
      </Box>
      <Box mt={2} pb={3} className={classes.medicatedInformation}>
        {!fullName ? (
          <Button
            variant="contained"
            color="secondary"
            startIcon={<PencilIcon alt={notFound} />}
            onClick={onClickDisabled ? onClickDisabled : onClick}
            disabled={disabled}
          >
            {notFound}
          </Button>
        ) : (
          <Box>
            <Typography variant="h5" className={classes.fullName}>
              {i18n.doctorBloc.namePrefix} {fullName}
            </Typography>
            {email && (
              <Link
                href={`mailto: ${email}`}
                className={classes.email}
                target="_blank"
              >
                {email}
              </Link>
            )}
            {phoneNumber && (
              <Typography className={classes.addressOrPhoneNumber}>
                {formatPhoneNumber(phoneNumber)}
              </Typography>
            )}
            {address && (
              <Typography className={classes.addressOrPhoneNumber}>
                {address}
              </Typography>
            )}
            {hospital && (
              <Box className={classes.hospitalContainer}>
                <HospitalIcon alt={hospital} style={{ marginRight: 10 }} />
                <Typography variant="h5" className={classes.hospital}>
                  {hospital}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Paper>
  )
}
