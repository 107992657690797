import { Box, Fade, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { format } from 'date-fns'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { HtmlTooltip } from '../../../ui/HtmlTooltip'

interface Props {
  children: React.ReactElement<any, any>
  description: string
  author?: string
  createdAt?: Date
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(2),
    borderRadius: '8px',
    boxShadow: `0px 3px 6px ${theme.palette.shadow}`,
  },
  description: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.info.dark,
  },
  author: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  date: {
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
}))

/**
 * Tooltip comprenant des informations complémentaires sur une entrée d'historique et appelée dans <TimelineContent />
 */
export const TimelineTooltip: React.FC<Props> = ({
  children,
  description,
  author,
  createdAt,
}) => {
  const classes = useStyles()
  const strings = i18n.patientDetails.timeline.tooltip

  return (
    <HtmlTooltip
      title={
        <Box className={classes.root}>
          <Typography className={classes.description}>{description}</Typography>
          {author && createdAt && (
            <>
              <Typography className={classes.author}>
                {strings.createdBy} {author}
              </Typography>
              <Typography className={classes.date}>
                {strings.createdAt} {format(createdAt, 'dd/MM/yyyy')}
              </Typography>
            </>
          )}
        </Box>
      }
      placement="bottom"
      arrow
      interactive
      enterDelay={100}
      leaveDelay={100}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 0 }}
    >
      {children}
    </HtmlTooltip>
  )
}
