import { colors } from '@material-ui/core'

const white = '#FFFFFF'
const black = '#000000'
const shadow = '#0000001A'
const tertiaryColorMain = '#C9D2DD'
const paperInformation = '#A9B3BF'
const basicGrey = '#A1A1A1'
const sidebarGrey = colors.blueGrey[800]
const linearBackground =
  'transparent linear-gradient(180deg, #021848 0%, #076DDD 100%) 0% 0% no-repeat padding-box'
const listHeaderBackground = '#FFF3F6'
const linearSuccessBackground =
  'transparent linear-gradient(180deg, #70C440 0%, #509827 100%) 0% 0% no-repeat padding-box'
const linearWarningBackground =
  'transparent linear-gradient(180deg, #FDC424 0%, #DB8631 100%) 0% 0% no-repeat padding-box'
const linearDangerBackground =
  'transparent linear-gradient(180deg, #FC195B 0%, #AA0303 100%) 0% 0% no-repeat padding-box'
const accentBackgroundColor = '#F3F7FB'
const linearUndefinedMoodBackground =
  'transparent linear-gradient(180deg, #E4EAF2 0%, #A9B3BF 100%) 0% 0% no-repeat padding-box'
const backgroundDark = '#E4EAF2'
const lightGrey = '#F4F6F8'
const dashboardTableGradient =
  'transparent linear-gradient(180deg, #1142A0 0%, #1864D9 100%) 0% 0% no-repeat padding-box'
const dashboarTableShadow = '#3F3F4426'
const yellow = '#FDC424'
const green = '#65B339'

export default {
  black,
  white,
  basicGrey,
  sidebarGrey,
  lightGrey,
  shadow,
  linearBackground,
  linearSuccessBackground,
  linearWarningBackground,
  linearDangerBackground,
  linearUndefinedMoodBackground,
  tertiaryColorMain,
  listHeaderBackground,
  accentBackgroundColor,
  paperInformation,
  backgroundDark,
  dashboardTableGradient,
  dashboarTableShadow,
  yellow,
  green,
  riskLevel: {
    high: {
      main: '#FC195B',
      light: '#FED1DE',
    },
    medium: {
      main: '#FDC424',
      light: '#FFF3D3',
    },
    low: {
      main: '#65B339',
      light: '#E6FAD7',
    },
  },
  primary: {
    contrastText: white,
    dark: '#243976',
    main: '#0D3182',
    light: '#007AFF',
  },
  secondary: {
    contrastText: white,
    main: '#FC195B',
  },
  success: {
    contrastText: white,
    main: '#65B339',
    light: '#E6FAD7',
  },
  info: {
    contrastText: white,
    main: '#A1A1A1',
    dark: '#696767',
    light: '#F0EFEF',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    disabled: colors.blue[600],
    hint: colors.blue[600],
  },
  background: {
    default: '#ECF1F7',
    paper: white,
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
}
