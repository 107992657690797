import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../context/auth-context'
import { isRoleAmong } from '../utils/isRoleAmong'

interface Props extends RouteProps {
  component: React.ComponentType<any>
  layout?: React.ComponentType<any>
  roles?: Array<Role>
}

/**
 * Route permettant d'avoir :
 *  - Une restriction d'accès selon le rôle
 *  - L'affichage ou non d'un layout autour du composant
 */
export const CustomRoute: React.FC<Props> = ({
  component: Component,
  layout: Layout,
  roles,
  ...rest
}) => {
  const { user, signOut, displayRevokedSessionError } = useAuth()

  // Si le rôle n'est pas autorisé, redirige vers la page d'accueil
  if (roles && user.data?.role && !isRoleAmong(user.data.role, roles)) {
    return <Redirect to="not-found" />
  }

  // Si l'utilisateur est connecté mais sans token, le déconnecte
  const token = localStorage.getItem('token')
  const refreshToken = localStorage.getItem('refresh_token')
  if (user.data && !token && !refreshToken) {
    displayRevokedSessionError()
    signOut()
  }

  // Affiche un composant avec un layout si besoin
  if (Layout) {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    )
  }

  // Affiche le composant
  return (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  )
}
