/**
 * Vérifie si un médecin appartient à l'hôpital auquel correspond un certain service d'hôpital
 * e.g. Walter White, médecin du service réanimation du CHRU de Tours, est-il du même hôpital que le service pédiatrie du CHRU de Tours ?
 */
export const isHospitalDoctorOfHospitalOfThisHospitalDepartment = (
  hospitalDoctor: HospitalDoctor,
  hospitalDepartmentId: string,
  hospitals: Array<Hospital> | undefined,
  hospitalDepartments: Array<HospitalDepartment> | undefined,
): boolean => {
  // Vérifie si les données sont valides
  if (
    !hospitalDoctor.hospitalDepartments ||
    !hospitals ||
    !hospitalDepartments
  ) {
    return false
  }

  // Récupère l'id de l'hôpital auquel correspond l'argument hospitalDepartmentId (hôpital cible)
  const targetHospitalDepartment = hospitalDepartments.find(
    (hospitalDepartment) => hospitalDepartment.id === hospitalDepartmentId,
  )
  if (!targetHospitalDepartment) {
    return false
  }
  const targetHospitalId =
    typeof targetHospitalDepartment?.hospital === 'string'
      ? targetHospitalDepartment?.hospital
      : targetHospitalDepartment?.hospital.id

  // Pour chacun des hospitalDepartment du médecin, récupère l'id de l'hôpital correspondant et vérifie si il correspond à l'id d'hôpital cible
  for (const hospitalDepartment of hospitalDoctor.hospitalDepartments) {
    const hospitalId =
      typeof hospitalDepartment.hospital === 'string'
        ? hospitalDepartment.hospital
        : hospitalDepartment.hospital.id

    if (hospitalId === targetHospitalId) {
      return true
    }
  }

  // Si aucun des hospitalDepartment du médecin ne correspond à l'hôpital cible, le médecin n'appartient pas à l'hôpital de l'hopistalDepartment cible
  return false
}
