import { Box, makeStyles, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { differenceInSeconds } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { getInitialPatientEntries } from '../../../../utils/timelineUtils'
import { HouseBlueIcon } from '../../../assets/Icons'
import { TimelineEntry } from './TimelineEntry'
import {
  TimelineScrollButton,
  TIMELINE_SCROLL_BUTTON_WIDTH,
} from './TimelineScrollButton'

export const TIMELINE_BORDER_HEIGHT = 2

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  timeline: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    backgroundColor: theme.palette.accentBackgroundColor,
    borderRadius: 8,
    overflow: 'auto',
  },
  entryContainer: {
    width: 140,
    minWidth: 140,
  },
  entryBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  topEntryBox: {
    height: 80,
    borderBottom: `${TIMELINE_BORDER_HEIGHT}px solid ${theme.palette.tertiaryColorMain}`,
  },
  bottomEntryBox: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  centerOnLine: {
    transform: `translateY(calc(50% + ${TIMELINE_BORDER_HEIGHT / 2}px))`,
  },
  labelText: {
    color: theme.palette.primary.main,
    fontSize: 12,
    lineHeight: '14px',
    textAlign: 'center',
  },
  entriesContainer: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
  scrollButtonContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  leftScrollButtonContainer: {
    left: 0,
  },
  rightScrollButtonContainer: {
    right: 0,
  },
}))

interface Props {
  history: Array<HistoryEntry>
  patient: Patient
}

/**
 * Affiche la liste des entrées d'historique du patient, y compris sa première connexion.
 * Chaque entrée d'historique est un <TimelineEntry />
 * La timeline dispose de deux <TimelineScrollButon /> pour aider à scroller à gauche / droite
 */
export const Timeline: React.FC<Props> = ({ history, patient }) => {
  const classes = useStyles()
  const strings = i18n.patientDetails.timeline
  const timelineRef = useRef<HTMLDivElement>(null)
  const [showLeftScrollButton, setShowLeftScrollButton] = useState<boolean>(
    false,
  )
  const [showRightScrollButton, setShowRightScrollButton] = useState<boolean>(
    false,
  )

  // Met à jour l'affichage des boutons de scroll uniquement si il doit changer
  const updateScrollButtonsVisibility = (left: boolean, right: boolean) => {
    if (showLeftScrollButton !== left) {
      setShowLeftScrollButton(left)
    }
    if (showRightScrollButton !== right) {
      setShowRightScrollButton(right)
    }
  }

  // Lorsque l'utilisateur scroll dans la timeline, vérifie si les boutons de scroll gauche/droite doivent être affichés
  const handleScroll = () => {
    if (!timelineRef.current) {
      return
    }
    const { scrollLeft, scrollWidth, clientWidth } = timelineRef.current

    // Pas de bouton si pas besoin de scroll
    if (scrollWidth < clientWidth) {
      updateScrollButtonsVisibility(false, false)
      return
    }

    // Pas de bouton à gauche si scroll tout à gauche
    if (scrollLeft <= TIMELINE_SCROLL_BUTTON_WIDTH) {
      updateScrollButtonsVisibility(false, true)
      return
    }

    // Pas de bouton à droite si scroll tout à droite
    if (
      scrollLeft >=
      scrollWidth - clientWidth - TIMELINE_SCROLL_BUTTON_WIDTH
    ) {
      updateScrollButtonsVisibility(true, false)
      return
    }

    // Sinon, affiche les deux boutons
    updateScrollButtonsVisibility(true, true)
  }

  // Scroll à la fin de la timeline au chargement de la page
  useEffect(() => {
    if (timelineRef) {
      timelineRef.current?.scroll({ left: 999999 })
    }
  }, [timelineRef])

  // Scroll de la moitié de la largeur au clic sur un bouton
  const handleScrollButtonClick = (direction: 'left' | 'right') => {
    if (!timelineRef.current) {
      return
    }
    const scrollDistance = Math.min(
      timelineRef.current.clientWidth / 2,
      timelineRef.current.scrollWidth - timelineRef.current.clientWidth,
    )
    timelineRef.current.scrollBy({
      left: direction === 'right' ? scrollDistance : -scrollDistance,
      behavior: 'smooth',
    })
  }
  const entries = history.concat(getInitialPatientEntries(patient))

  return (
    <Box className={classes.root}>
      <div
        ref={timelineRef}
        className={classes.timeline}
        onScroll={handleScroll}
      >
        {/* Entrée initiale (sortie d'hôpital) */}
        <Box className={classes.entryContainer}>
          <Box className={clsx(classes.entryBox, classes.topEntryBox)}>
            <HouseBlueIcon className={classes.centerOnLine} alt="agenda" />
          </Box>
          <Box className={clsx(classes.entryBox, classes.bottomEntryBox)}>
            <Typography className={classes.labelText}>
              {strings.ouOfHospital}
            </Typography>
          </Box>
        </Box>

        {/* Autres entrées */}
        <Box className={classes.entriesContainer}>
          {entries
            .slice()
            .sort((dateLeft, dateRight) =>
              differenceInSeconds(dateLeft.date, dateRight.date),
            )
            .map((entry, index) => (
              <TimelineEntry
                key={entry.id}
                entry={entry}
                isLastEntry={index === entries.length - 1}
              />
            ))}
        </Box>
      </div>

      {/* Boutons de scroll gauche/droite */}
      <Box
        className={clsx(
          classes.scrollButtonContainer,
          classes.leftScrollButtonContainer,
        )}
      >
        <TimelineScrollButton
          direction="left"
          visible={showLeftScrollButton}
          onClick={() => handleScrollButtonClick('left')}
        />
      </Box>
      <Box
        className={clsx(
          classes.scrollButtonContainer,
          classes.rightScrollButtonContainer,
        )}
      >
        <TimelineScrollButton
          direction="right"
          visible={showRightScrollButton}
          onClick={() => handleScrollButtonClick('right')}
        />
      </Box>
    </Box>
  )
}
