import { Box, CircularProgress, Grid } from '@material-ui/core'
import clsx from 'clsx'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import {
  addInspectorAction,
  loadInspectorsAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import { FieldInfo } from '../../ui/FieldInfo'
import { HeroBanner } from '../../ui/HeroBanner'
import { InputField } from '../../ui/Inputfield'
import { MessageAlert } from '../../ui/MessageAlert'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.inspectors.create.email.valid)
    .required(i18n.inspectors.create.email.required),
  password: Yup.string().required(i18n.inspectors.create.password.required),
  firstName: Yup.string().required(i18n.inspectors.create.firstName.required),
  lastName: Yup.string().required(i18n.inspectors.create.lastName.required),
})

/**
 * Formulaire de création d'un enquêteur
 */
export const CreateInspector = () => {
  const strings = i18n.inspectors.create
  const classes = useUserFormStyles()
  const { dispatch, inspectors } = useAppContext()
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!inspectors.data) {
      dispatch(loadInspectorsAction())
    }
  }, [inspectors.data, dispatch])

  const onSubmit = async (values: CreateInspectorFormValues) => {
    try {
      const inspectorToCreate: InspectorToCreate = {
        ...values,
      }

      await dispatch(addInspectorAction(inspectorToCreate))
      await dispatch(loadInspectorsAction())
      browserHistory.push('/admin/inspectors')
    } catch (error) {
      setShowError(true)
    }
  }

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.inspectorsBanner.createNewInspectorTitle}
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.inspectorsBanner.goBack}
      />

      {showError && (
        <MessageAlert
          message={strings.errors.message}
          title={strings.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      <Formik<CreateInspectorFormValues>
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          role: 'inspector' as Role,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => {
          const submitDisabled =
            !dirty ||
            values.email === '' || // La condition "dirty" ne suffit pas, le form devient "dirty" alors qu'aucun champ n'est modifié
            !!errors.email ||
            !!errors.password ||
            !!errors.firstName ||
            !!errors.lastName ||
            isSubmitting

          return (
            <form className={classes.form} onSubmit={handleSubmit}>
              <Box className={classes.mainContainer}>
                {/* IDENTITY */}
                <Box className={classes.formSection}>
                  <UserFormSectionTitle title={strings.inspector.identity} />
                  <Grid container spacing={USER_FORM_SPACING}>
                    <Grid item xs={6}>
                      <Field
                        name="lastName"
                        label={strings.lastName.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="firstName"
                        label={strings.firstName.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* INFORMATION */}
                <Box
                  className={clsx(classes.formSection, classes.formSectionDark)}
                >
                  <UserFormSectionTitle title={strings.inspector.information} />
                  <FieldInfo title={i18n.auth.forgottenPassword.subtitle} />
                  <Grid container wrap="wrap">
                    <Grid item xs={10}>
                      <Field
                        name="email"
                        label={strings.email.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Field
                        name="password"
                        label={strings.password.label}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={InputField}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {/* BUTTONS */}
                <Box className={classes.buttonsContainer}>
                  <PreviousButton
                    title={i18n.auth.forgottenPassword.reset.return}
                    onClick={() => browserHistory.goBack()}
                    marginRight
                  />

                  <SubmitButton
                    title={strings.button}
                    disabled={submitDisabled}
                  />

                  {inspectors.state === 'loading' && (
                    <CircularProgress
                      size={24}
                      className={classes.loadingIcon}
                    />
                  )}
                </Box>
              </Box>
            </form>
          )
        }}
      </Formik>
    </Grid>
  )
}
