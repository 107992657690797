import React from 'react'
import {
  ConsultationIcon,
  DayHospitalIcon,
  TeleconsultationIcon,
} from '../../../assets/Icons'

interface Props {
  type?: AppointmentType
}

export const AppointmentIcon: React.FC<Props> = ({ type }) => {
  switch (type) {
    case 'Consultation':
      return <ConsultationIcon />
    case 'Téléconsultation':
      return <TeleconsultationIcon />
    case 'H. de jour':
      return <DayHospitalIcon />
    default:
      return <ConsultationIcon />
  }
}
