import { Api } from './api'

export async function getHospitals(): Promise<ApiHospital[]> {
  const response = await Api.get<ApiHospital[]>('/hospitals')
  return response.data
}

export async function getHospitalDepartmentsByHospital(
  hospitalId: string,
): Promise<ApiHospitalDepartment[]> {
  const response = await Api.get<ApiHospitalDepartment[]>(
    `/hospital-departments/hospital/${hospitalId}`,
  )
  return response.data
}

export async function getSymptomsByHospitalDepartmentsId(
  HospitalDepartmentsId: string,
): Promise<ApiSymptom[]> {
  const response = await Api.get<ApiSymptom[]>(
    `/hospital-departments/${HospitalDepartmentsId}/symptoms`,
  )
  return response.data
}
