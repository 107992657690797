import {
  Box,
  Button,
  InputBase,
  makeStyles,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React, { useState } from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { DialogSendPdf } from '../../../assets/Icons'
import { DialogFormAction } from '../../../ui/DialogFormAction'
import { OutOfReanimationPdfStepper } from './OutOfReanimationPdfStepper'

enum Step {
  EmailBody,
  DownloadPdf,
  SendEmail,
}

const STEP_LABELS = [
  i18n.patients.outOfReanimationPdfDialog.mailBodyTab,
  i18n.patients.outOfReanimationPdfDialog.downloadPdfTab,
  i18n.patients.outOfReanimationPdfDialog.sendMailTab,
]

interface Props {
  isOpen: boolean
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined
  handleClose: () => void
  handleDownloadOutOfReanimationPdf: () => Promise<void>
  cityDoctorEmail: string
  patientFullName: string
  hospitalName: string
}

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  leftButton: {
    marginRight: theme.spacing(2),
  },
  emailBodyContainer: {
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderRadius: 4,
  },
  stepActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: theme.palette.accentBackgroundColor,
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  setActionDescription: {
    width: '75%',
    color: theme.palette.info.dark,
    textAlign: 'left',
    marginBottom: theme.spacing(2),
  },
  downloadPdfDescription: {
    fontSize: 14,
  },
  sendEmailDescription: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

/**
 * Modale appelée dans <PatientMonitoring />, dans laquelle le médecin peut :
 *  1. Ecrire un corps de mail
 *  2. Télécharger la fiche de suivi du patient au format pdf
 *  3. Ouvrir sa messagerie afin d'envoyer la fiche de suivi par mail
 */
export const OutOfReanimationPdfDialog: React.FC<Props> = ({
  isOpen,
  maxWidth,
  handleClose,
  handleDownloadOutOfReanimationPdf,
  cityDoctorEmail,
  patientFullName,
  hospitalName,
}) => {
  const strings = i18n.patients.outOfReanimationPdfDialog
  const getDefaultMailBody = (hospitalName: string) =>
    `${strings.defaultMailBodyBeforeChu} ${hospitalName}. ${strings.defaultMailBodyAfterChu} ${hospitalName}`

  const classes = useStyles()
  const [activeStep, setActiveStep] = useState<Step>(Step.EmailBody)
  const [emailBody, setEmailBody] = useState<string>(
    getDefaultMailBody(hospitalName),
  )

  // METHODS
  const handleBack = () => {
    if (activeStep === 0) {
      handleClose()
      return
    }

    setActiveStep(activeStep - 1)
  }

  const handleFinish = () => {
    handleClose()
    const defaultMailBody = getDefaultMailBody(hospitalName)
    setEmailBody(defaultMailBody)
  }

  const handleEmailBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEmailBody(e.target.value)

  // DATA
  const subject = encodeURI(`${strings.defaultMailSubject} ${patientFullName}`)
  const mailtoHref = `mailto:${cityDoctorEmail}?subject=${subject}&body=${encodeURI(
    emailBody,
  )}`

  // RENDER
  return (
    <DialogFormAction
      open={isOpen}
      maxWidth={maxWidth}
      onClose={handleClose}
      icon={<DialogSendPdf />}
      title={strings.title}
      onExited={() => setActiveStep(Step.EmailBody)}
    >
      <Box className={classes.root}>
        {/* STEPPER */}
        <OutOfReanimationPdfStepper
          steps={STEP_LABELS}
          activeStep={activeStep}
        />

        {/* 1. EMAIL BODY */}
        {activeStep === Step.EmailBody && (
          <Box className={classes.emailBodyContainer}>
            <InputBase
              id="email-body"
              multiline
              rows={8}
              value={emailBody}
              onChange={handleEmailBodyChange}
              fullWidth
              inputProps={{ 'aria-label': 'naked' }}
            />
          </Box>
        )}

        {/* 2. DOWNLOAD PDF */}
        {activeStep === Step.DownloadPdf && (
          <Box className={classes.stepActionContainer}>
            <Typography
              className={clsx(
                classes.setActionDescription,
                classes.downloadPdfDescription,
              )}
            >
              {strings.downloadPdfDescription}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              onClick={handleDownloadOutOfReanimationPdf}
            >
              {strings.downloadPdfButton}
            </Button>
          </Box>
        )}

        {/* 3. SEND EMAIL */}
        {activeStep === Step.SendEmail && (
          <Box className={classes.stepActionContainer}>
            <Typography
              className={clsx(
                classes.setActionDescription,
                classes.sendEmailDescription,
              )}
            >
              {strings.sendEmailDescription}
            </Typography>
            <Button
              color="secondary"
              variant="contained"
              component="a"
              href={mailtoHref}
            >
              {strings.sendEmailButton}
            </Button>
          </Box>
        )}

        {/* STEPPER BUTTONS */}
        <Box className={classes.buttonsContainer}>
          {/* BACK */}
          <Button
            onClick={handleBack}
            color="primary"
            variant="contained"
            size="large"
            className={classes.leftButton}
          >
            {strings.previous}
          </Button>

          {/* NEXT */}
          {activeStep < Step.SendEmail && (
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={() => setActiveStep(activeStep + 1)}
            >
              {strings.next}
            </Button>
          )}

          {/* FINISH */}
          {activeStep === Step.SendEmail && (
            <Button
              color="secondary"
              variant="contained"
              size="large"
              onClick={handleFinish}
            >
              {strings.finish}
            </Button>
          )}
        </Box>
      </Box>
    </DialogFormAction>
  )
}
