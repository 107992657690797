import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import clsx from 'clsx'
import { Field, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { loadHospitalsAction } from '../../../context/actions/hospital'
import { loadSymptomsAction } from '../../../context/actions/symptom'
import {
  loadDoctorsAction,
  loadPatientsAction,
  resetPatientsStateAction,
  updatePatientAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import {
  USER_FORM_SPACING,
  useUserFormStyles,
} from '../../../theme/styles/userFormStyles'
import {
  patientSymptomsToInitialSymptomValues,
  symptomsToInitialSymptomValues,
} from '../../../utils'
import { isHospitalDoctorOfHospitalOfThisHospitalDepartment } from '../../../utils/isHospitalDoctorOfHospitalOfThisHospitalDepartment'
import { regexes } from '../../../utils/regexes'
import { isHospitalDoctor } from '../../../utils/typeGuards'
import { HeroBanner } from '../../ui/HeroBanner'
import { HospitalDepartmentsSelector } from '../../ui/HospitalDepartmentsSelector'
import { InputField, PickerDate } from '../../ui/Inputfield'
import { LoadingPane } from '../../ui/LoadingPane'
import { MessageAlert } from '../../ui/MessageAlert'
import {
  PhoneNumberField,
  phoneNumberValidationSchema,
} from '../../ui/PhoneNumberField'
import { PreviousButton } from '../../ui/PreviousButton'
import { SubmitButton } from '../../ui/SubmitButton'
import { UserFormSectionTitle } from '../../ui/UserFormSectionTitle'
import { PatientSymptoms } from './PatientSymptoms'

const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.patients.create.email.valid)
    .required(i18n.patients.create.email.required),
  firstName: Yup.string().required(i18n.patients.create.firstName.required),
  lastName: Yup.string().required(i18n.patients.create.lastName.required),
  socialSecurityNumber: Yup.string()
    .matches(
      regexes.socialSecurityNumber,
      i18n.patients.create.socialSecurityNumber.mustBeValid,
    )
    .length(15, i18n.patients.create.socialSecurityNumber.length),
  codeId: Yup.string()
    .optional()
    .matches(regexes.codeId, i18n.patients.create.codeId.mustBeAlphanumeric),
  doctors: Yup.array().required(i18n.patients.create.reanimators.required),
  cityDoctor: Yup.string().optional(),
  hospitalDepartment: Yup.string().required(),
  inHospitalDate: Yup.date()
    .required(i18n.patients.create.inHospitalDate.required)
    .typeError(i18n.patients.create.inHospitalDate.mustBeValid),
  outHospitalDate: Yup.date()
    .required(i18n.patients.create.outHospitalDate.required)
    .typeError(i18n.patients.create.outHospitalDate.mustBeValid),
  birthDate: Yup.date()
    .required(i18n.patients.create.birthDate.required)
    .typeError(i18n.patients.create.birthDate.mustBeValid),
  phoneNumber: phoneNumberValidationSchema.required(
    i18n.ui.phoneNumberField.required,
  ),
})

/**
 * Formulaire de modification de patient
 */
export const UpdatePatient = () => {
  const strings = i18n.patients.create
  const classes = useUserFormStyles()
  const {
    dispatch,
    hospitals,
    hospitalDepartments,
    doctors,
    patients,
    symptoms,
  } = useAppContext()
  const [showError, setShowError] = useState(false)
  const [patientToUpdate, setPatientToUpdate] = useState<Patient | null>(null)

  const { id } = useParams()

  useEffect(() => {
    if (hospitals.state !== 'loaded' && hospitals.state !== 'error') {
      dispatch(loadHospitalsAction())
    }
  }, [hospitals.state, dispatch])

  useEffect(() => {
    if (symptoms.state !== 'loaded' && symptoms.state !== 'error') {
      // Peut être se baser sur l'hospitalDepartement du réanimateur référent
      dispatch(loadSymptomsAction('jeneconnaispaslid'))
    }
  }, [symptoms.state, dispatch])

  useEffect(() => {
    if (!patients.data && doctors.state === 'idle') {
      dispatch(loadPatientsAction())
    }
    if (patients.data && !patientToUpdate) {
      const patient: any = patients?.data?.find((el) => el.id === id)
      setPatientToUpdate(patient)
    }
    if (!doctors.data && doctors.state === 'idle') {
      dispatch(loadDoctorsAction())
    }

    return () => {
      dispatch(resetPatientsStateAction())
    }
  }, [patients.data, dispatch, doctors, patients, patientToUpdate, id])

  useEffect(() => {
    return () => {
      dispatch(resetPatientsStateAction())
    }
  }, [dispatch])

  if (!patientToUpdate) {
    return <LoadingPane />
  }

  const onSubmit = async (values: UpdatePatientFormValues) => {
    try {
      let symptomData: Array<PatientSymptom> = []
      for (const key of Object.keys(values.symptoms)) {
        symptomData.push({ symptom: key, value: values.symptoms[key] })
      }
      const patient = {
        ...values,
        codeId: values.codeId === '' ? undefined : values.codeId,
        symptoms: symptomData,
        inHospitalDate: values.inHospitalDate.toISOString(),
        outHospitalDate: values.outHospitalDate.toISOString(),
        birthDate: values.birthDate.toISOString(),
        id: patientToUpdate.id,
      }
      await dispatch(updatePatientAction(patient))
      await dispatch(loadPatientsAction())
      browserHistory.push('/admin/patients')
    } catch (error) {
      setShowError(true)
    }
  }

  const patientFullName = `${patientToUpdate.lastName.toUpperCase()}  ${
    patientToUpdate.firstName
  }`
  const cityDoctors =
    doctors.data?.filter((doctor) => doctor.role === 'cityDoctor') || []

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.PatientList}
        title={i18n.patientBanner.updatePatientTitle + ' ' + patientFullName}
        goBack={() => browserHistory.goBack()}
        goBackTitle={i18n.patientBanner.goBack}
      />

      {showError && (
        <MessageAlert
          message={i18n.patients.update.errors.message}
          title={i18n.patients.update.errors.title}
          severity="error"
          autoClose={true}
          onClose={() => setShowError(false)}
        />
      )}

      {symptoms.data && (
        <Formik<UpdatePatientFormValues>
          initialValues={{
            email: patientToUpdate.email,
            firstName: patientToUpdate.firstName,
            lastName: patientToUpdate.lastName,
            socialSecurityNumber: patientToUpdate.socialSecurityNumber,
            codeId: patientToUpdate.codeId,
            role: 'patient',
            doctors:
              patientToUpdate.doctors && patientToUpdate.doctors.length > 0
                ? patientToUpdate.doctors
                : [],
            cityDoctor: patientToUpdate.cityDoctor,
            hospitalDepartment: patientToUpdate.hospitalDepartment.id,
            inHospitalDate: new Date(patientToUpdate.inHospitalDate),
            outHospitalDate: new Date(patientToUpdate.outHospitalDate),
            birthDate: new Date(patientToUpdate.birthDate),
            phoneNumber: patientToUpdate.phoneNumber,
            symptoms: patientToUpdate.symptoms
              ? patientSymptomsToInitialSymptomValues(patientToUpdate.symptoms)
              : symptomsToInitialSymptomValues(symptoms.data),
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            dirty,
            touched,
            isSubmitting,
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
          }) => {
            const hospitalDoctors =
              doctors?.data
                ?.filter(isHospitalDoctor)
                .filter((doctor) =>
                  isHospitalDoctorOfHospitalOfThisHospitalDepartment(
                    doctor,
                    values.hospitalDepartment,
                    hospitals.data,
                    hospitalDepartments.data,
                  ),
                ) || []

            const showHospitalDoctorSelect =
              doctors.data &&
              hospitals.data &&
              hospitalDepartments.data &&
              values.hospitalDepartment

            const submitDisabled =
              !dirty ||
              !!errors.email ||
              !!errors.firstName ||
              !!errors.lastName ||
              !!errors.socialSecurityNumber ||
              !!errors.birthDate ||
              !!errors.inHospitalDate ||
              !!errors.outHospitalDate ||
              !!errors.doctors ||
              !!errors.cityDoctor ||
              !!errors.hospitalDepartment ||
              !!errors.phoneNumber ||
              isSubmitting

            return (
              <form className={classes.form} onSubmit={handleSubmit}>
                <Box className={classes.mainContainer}>
                  {/* IDENTITY */}
                  <Box className={classes.formSection}>
                    <UserFormSectionTitle title={strings.patient.identity} />
                    <Grid container spacing={USER_FORM_SPACING}>
                      <Grid item xs={6}>
                        <Field
                          name="lastName"
                          label={strings.lastName.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="firstName"
                          label={strings.firstName.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="socialSecurityNumber"
                          label={strings.socialSecurityNumber.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="birthDate"
                          label={strings.birthDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.birthDate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="codeId"
                          label={strings.codeId.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* INFORMATION */}
                  <Box
                    className={clsx(
                      classes.formSection,
                      classes.formSectionDark,
                    )}
                  >
                    <UserFormSectionTitle title={strings.patient.information} />
                    <Grid container spacing={USER_FORM_SPACING}>
                      <Grid item xs={10}>
                        <Field
                          name="email"
                          label={strings.email.label}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={InputField}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <PhoneNumberField
                          label={i18n.ui.phoneNumberField.requiredLabel}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/* HOSPITALIZATION */}
                  <Box className={classes.formSection}>
                    <UserFormSectionTitle
                      title={strings.patient.hospitalization}
                    />
                    <Grid container spacing={USER_FORM_SPACING}>
                      <Grid item xs={6}>
                        <Field
                          name="inHospitalDate"
                          label={strings.inHospitalDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.inHospitalDate}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="outHospitalDate"
                          label={strings.outHospitalDate.label}
                          type="date"
                          onBlur={handleBlur}
                          component={PickerDate}
                          value={values.outHospitalDate}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <HospitalDepartmentsSelector
                            errors={errors}
                            touched={touched}
                            setValue={(value: Array<string>) =>
                              setFieldValue(
                                'hospitalDepartment',
                                value[0] || '',
                              )
                            }
                            resetHospitalDoctor={() =>
                              setFieldValue('doctors', [])
                            }
                            initialHospitalDepartments={[
                              patientToUpdate.hospitalDepartment,
                            ]}
                          />
                        </FormControl>
                      </Grid>

                      {showHospitalDoctorSelect && (
                        <Grid item xs={12}>
                          <FormControl className={classes.formControl}>
                            <InputLabel style={{ paddingLeft: 16 }}>
                              {strings.reanimators.label}
                            </InputLabel>
                            <Select
                              classes={{ root: classes.select }}
                              value={values.doctors}
                              name="doctors"
                              label={strings.reanimators.label}
                              placeholder={strings.reanimators.label}
                              variant="outlined"
                              onChange={(e) =>
                                setFieldValue('doctors', [e.target.value])
                              }
                            >
                              {hospitalDoctors.map((doctor) => (
                                <MenuItem key={doctor.id} value={doctor.id}>
                                  {doctor.lastName.toUpperCase()}&nbsp;
                                  {doctor.firstName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            id="city-doctor-selector"
                            options={cityDoctors}
                            value={cityDoctors.find(
                              (doctor) => doctor.id === values.cityDoctor,
                            )}
                            getOptionLabel={(option) => option.fullName}
                            onChange={(e, newValue) => {
                              setFieldValue('cityDoctor', newValue?.id || '')
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={strings.cityDoctor.label}
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* SYMPTOMS */}
                  <Box
                    className={clsx(
                      classes.formSection,
                      classes.formSectionDark,
                    )}
                  >
                    <UserFormSectionTitle title={strings.patient.symptoms} />
                    {symptoms.data && (
                      <PatientSymptoms
                        symptoms={symptoms.data}
                        spacing={USER_FORM_SPACING}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    )}
                  </Box>

                  {/* BUTTONS */}
                  <Box className={classes.buttonsContainer}>
                    <PreviousButton
                      title={i18n.auth.forgottenPassword.reset.return}
                      onClick={() => browserHistory.goBack()}
                      marginRight
                    />

                    <SubmitButton
                      title={strings.buttonUpdate}
                      disabled={submitDisabled}
                    />

                    {patients.state === 'loading' && (
                      <CircularProgress
                        size={24}
                        className={classes.loadingIcon}
                      />
                    )}
                  </Box>
                </Box>
              </form>
            )
          }}
        </Formik>
      )}
    </Grid>
  )
}
