import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { i18n } from '../../../../assets/i18n'
import { appointmentTypeToAppointmentTypeString } from '../../../../utils/dashboardMappings'
import { AppointmentIcon } from './AppointmentIcon'

interface Props {
  data: RowsHistoryItem
}

const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  doctor: {
    color: `${theme.palette.info.dark} !important`,
  },
  statusButton: {
    color: `${theme.palette.white} !important`,
    padding: '10px 15px',
  },
  yellow: {
    background: theme.palette.yellow,
  },
  green: {
    background: theme.palette.green,
    padding: '10px 23px',
  },
}))

export const HistoryTable: React.FC<Props> = ({ data }) => {
  const classes = useTableStyles()

  return (
    <>
      <TableCell component="th">
        <Box display="flex" alignItems="center">
          <AppointmentIcon type={data.type} />
          &nbsp;
          {appointmentTypeToAppointmentTypeString(data.type)}
        </Box>
      </TableCell>
      <TableCell component="th">{data.appointment}</TableCell>
      <TableCell component="th" className={classes.doctor}>
        {i18n.dashboard.table.history.namePrefix} {data.doctor}
      </TableCell>
      <TableCell component="th">
        <Box
          component="span"
          width={1}
          className={clsx(classes.statusButton, {
            [classes.yellow]: data.status === 'En cours',
            [classes.green]: data.status === 'Résolu',
          })}
        >
          {data.status}
        </Box>
      </TableCell>
    </>
  )
}
