import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import browserHistory from '../../../router/history'
import { NotFoundScreen } from '../../assets/Images'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  content: {},
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  contentBody: {
    paddingTop: 150,
    textAlign: 'center',
  },
  text: {
    color: theme.palette.info.light,
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}))

export const NotFound = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.content}>
            <Box className={classes.contentHeader}>
              <IconButton onClick={() => browserHistory.goBack()}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
            <Box className={classes.contentBody}>
              <Typography variant="h1" className={classes.text}>
                {i18n.notfound.title}
              </Typography>
              <Typography variant="subtitle2" className={classes.text}>
                {i18n.notfound.description}
              </Typography>
              <NotFoundScreen
                alt="Under development"
                className={classes.image}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
