import Axios from 'axios'
import browserHistory from '../router/history'
import { deleteToken, getNewToken } from './auth.service'

const getHeaders = () => {
  const token = localStorage.getItem('token')
  return (
    (token && {
      Authorization: `Bearer ${token}`,
    }) ||
    {}
  )
}

export const Api = Axios.create({ baseURL: process.env.REACT_APP_API_URL })

Api.interceptors.request.use(async (value) => {
  value.headers = { ...value.headers, ...getHeaders() }
  return value
})

Api.interceptors.response.use(
  (value) => Promise.resolve(value),
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.config.url !== '/auth/refresh'
    ) {
      try {
        await getNewToken()
        // New request with new token
        const config = error.config
        const token = localStorage.getItem('token')
        config.headers['Authorization'] = `Bearer ${token}`

        return new Promise((resolve, reject) => {
          Axios.request(config)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      } catch (error) {
        if (error.response?.status === 401) {
          deleteToken('token')
          deleteToken('refresh_token')
          if (
            window.location.pathname !== '/sign-in' &&
            window.location.pathname !== '/otp-validation' &&
            window.location.pathname !== '/legal-notice' &&
            window.location.pathname.split('/')[1] !== 'forgotten-password'
          ) {
            browserHistory.push('/sign-in?url=' + window.location.pathname)
          }
        }
      }
    }
    return Promise.reject(error)
  },
)
