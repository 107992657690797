import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../@types/theme'

interface FieldInfoProps {
  title: string
}

const useStyles = makeStyles((theme: ChuTheme) => ({
  title: {
    fontSize: 12,
    color: theme.palette.info.dark,
  },
}))

/**
 * Utilisé dans un formulaire pour donner une indication (e.g. le mot de passe doit contenir au moins 8 caractères)
 */
export const FieldInfo: React.FC<FieldInfoProps> = ({ title }) => {
  const classes = useStyles()

  return <Typography className={classes.title}>{title}</Typography>
}
