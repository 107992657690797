import { Api } from './api'

export async function getPatients() {
  const response = await Api.get<ApiPatient[]>(
    `/patients?with-last-form-post=true`,
  )
  return response.data
}

export async function createPatient(patient: ApiPatientToCreate) {
  const response = await Api.post<ApiPatient>(`/users`, patient)
  return response.data
}

export async function updatePatient(patient: ApiPatientToUpdate) {
  const response = await Api.put<ApiUser>(`/users/${patient._id}`, patient)
  return response.data
}

export async function getPatientMood(patientId: string) {
  const response = await Api.get<ApiMood[]>(`/moods/user/${patientId}`)
  return response.data
}

export async function getRiskyPatients() {
  const response = await Api.get<ApiRiskyPatient[]>('/moods/risky-patients')
  return response.data
}
