import { Checkbox, Grid, Link } from '@material-ui/core'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { makeStyles } from '@material-ui/styles'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import React, { Fragment, useEffect } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import {
  loadFormsAction,
  updateFormProcessedStatusAction,
} from '../../../context/actions/survey'
import { useAppContext } from '../../../context/app-context'
import { useAuth } from '../../../context/auth-context'
import browserHistory from '../../../router/history'
import { downloadFormPostsCsv } from '../../../services/forms.service'
import { images } from '../../../theme/images'
import { settings } from '../../../theme/settings'
import { DATA_TABLE_OPTIONS } from '../../../utils/dataTableOptions'
import { formatDate } from '../../../utils/dateUtils'
import { getFractionalScore } from '../../../utils/getFractionalScore'
import { getFullName } from '../../../utils/getFullName'
import { HeroBanner } from '../../ui/HeroBanner'
import { HeroCounterElements } from '../../ui/HeroCounterElements'
import { LoadingPane } from '../../ui/LoadingPane'
import { QuestionnaireScoreTooltip } from '../../ui/QuestionnaireScoreTooltip'
import { FormRisk } from './FormRisk'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
  },
  score: {
    color: theme.palette.primary.light,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkButton: {
    minWidth: 100,
    color: settings.theme.palette.primary.light,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  cellWithoutPadding: {
    padding: 0,
  },
}))

/**
 * Tableau des questionnaires (forms) remplis, avec un data-table afin d'avoir des informations sur les questionnaires
 */
export const Forms = () => {
  const classes = useStyles()
  const { forms, dispatch } = useAppContext()
  const { user } = useAuth()
  const lines = forms.data || []

  useEffect(() => {
    if (!forms.data) {
      dispatch(loadFormsAction())
    }
  }, [dispatch, forms.data])

  const handleDownload = () => {
    downloadFormPostsCsv()
    return false
  }

  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/forms/${id}`)
  }

  const goToPatientDetails = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }

  const updateFormProcessedStatus = async (id: string, processed: boolean) => {
    await dispatch(updateFormProcessedStatusAction(id, processed))
  }

  const numberOfNotProcessedForms = lines.filter(
    (form) => form.processed !== true,
  ).length

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'formId',
      label: 'Identifiant du formulaire',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'user',
      label: 'Patient',
      options: {
        sort: false,
        customBodyRender: (value: AnyUser) =>
          user.data?.role === 'inspector' ? (
            value.codeId
          ) : (
            <Link
              component="button"
              className={classes.linkButton}
              onClick={() => goToPatientDetails(value.id)}
            >
              {getFullName(value.firstName, value.lastName)}
            </Link>
          ),
      },
    },
    {
      name: 'technicalName',
      label: 'TechnicalName',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'results',
      label: 'Questionnaire',
      options: {
        sort: false,
        setCellProps: () => ({
          className: classes.cellWithoutPadding,
        }),
        customBodyRender: (value: FormResult[], tableMeta) => {
          const formName = tableMeta.rowData[3]
          return <FormRisk form={value} formName={formName} />
        },
      },
    },
    {
      name: 'results',
      label: 'Score',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: FormResult[]) => {
          if (!Array.isArray(value)) {
            return 'Erreur'
          }

          return value.map((result, index) => (
            <Fragment key={result.questionnaireId}>
              <QuestionnaireScoreTooltip
                questionnaireId={result.questionnaireId}
              >
                <span className={classes.score}>
                  {getFractionalScore(result.score)}
                </span>
              </QuestionnaireScoreTooltip>
              {index < value.length - 1 && (
                <span className={classes.score}>{' - '}</span>
              )}
            </Fragment>
          ))
        },
      },
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false,
        customBodyRender: (value) => formatDate(value),
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <Link
              component="button"
              onClick={() => handleOnEditClick(tableMeta.rowData[0])}
              className={classes.linkButton}
            >
              {i18n.formPostsTable.formLink} <KeyboardArrowRight />
            </Link>
          )
        },
      },
    },
    {
      name: 'processed',
      label: 'Traité',
      options: {
        sort: true,
        customBodyRender: (value, meta) => {
          return (
            <Checkbox
              checked={value === undefined ? false : value}
              onChange={(_event, value) => {
                updateFormProcessedStatus(meta.rowData[0], value)
              }}
            />
          )
        },
      },
    },
  ]

  if (forms.state === 'loading') {
    return <LoadingPane />
  }

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.surveyList}
        title={i18n.surveyBanner.surveyManagement}
      />
      <HeroCounterElements
        startString={i18n.surveyBanner.surveyNumberStart}
        counter={numberOfNotProcessedForms}
        counterDesc={i18n.surveyBanner.surveyNumberCounter}
        endString={i18n.surveyBanner.surveyNumberEnd}
      />

      <Grid item xs={12}>
        <MUIDataTable
          title=""
          columns={columns}
          data={lines}
          options={{
            ...DATA_TABLE_OPTIONS,
            onDownload: handleDownload,
          }}
        />
      </Grid>
    </Grid>
  )
}
