import { getPatientMood } from '../../services/patients.service'
import { fromApiMood } from '../../utils'
import { createAction } from '../app-context'

export const loadPatientMoodAction = createAction(
  (patientId: string) => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.patientMoods.state = 'loading'
      })
      const moods = await getPatientMood(patientId)

      await produceState((draft) => {
        draft.patientMoods.data = moods.map(fromApiMood)
        draft.patientMoods.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.patientMoods.state = 'error'
      })
    }
  },
)

export const resetPatientMoodsAction = createAction(
  () => async ({ produceState }) => {
    await produceState((draft) => {
      draft.patientMoods.state = 'idle'
      draft.patientMoods.data = undefined
    })
  },
)
