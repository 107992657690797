import {
  getDisturbingFormPosts,
  getFormPosts,
  getForms,
  updateFormPostProcessedStatus,
} from '../../services/forms.service'
import { getUser } from '../../services/users.service'
import { fromApiDisturbingFormPost, fromApiFormPost } from '../../utils'
import { createAction } from '../app-context'

export const loadFormsAction = createAction(() => async ({ produceState }) => {
  try {
    await produceState((draft) => {
      draft.forms.state = 'loading'
    })

    const apiForms = await getForms()
    const apiFormPosts = await getFormPosts('completed')
    const formPosts = (
      await Promise.all(
        apiFormPosts.map(async (formPost) => {
          const form = apiForms.find((item) => item._id === formPost.formId)
          const emptyForm: ApiForm = { _id: '', name: '', technicalName: '' }
          return fromApiFormPost(
            formPost,
            await getUser(formPost.patientId),
            form ? form : emptyForm,
          )
        }),
      )
    ).filter((item) => item.formId !== '')

    await produceState((draft) => {
      draft.forms.data = formPosts
      draft.forms.state = 'loaded'
    })
  } catch (e) {
    console.error(e)
    await produceState((draft) => {
      draft.forms.state = 'error'
    })
  }
})

export const loadDisturbingFormPostsAction = createAction(
  () => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.disturbingFormPosts.state = 'loading'
      })

      const resultApi = await getDisturbingFormPosts()
      const disturbingFormPost = fromApiDisturbingFormPost(resultApi)

      await produceState((draft) => {
        draft.disturbingFormPosts.data = disturbingFormPost
        draft.disturbingFormPosts.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.forms.state = 'error'
      })
    }
  },
)

export const updateFormProcessedStatusAction = createAction(
  (id: string, processed: boolean) => async ({ produceState }) => {
    try {
      await produceState((draft) => {
        draft.forms.state = 'loading'
      })

      await updateFormPostProcessedStatus(id, processed)

      await produceState((draft) => {
        const form = draft.forms.data?.find((form) => form.id === id)

        if (!form) {
          return
        }

        form.processed = processed
        draft.forms.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => {
        draft.forms.state = 'error'
      })
    }
  },
)
