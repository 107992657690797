import { i18n } from '../assets/i18n'

/**
 * Récupère un string avec la liste des services à partir d'un tableau d'objets HospitalDepartments (venant d'un Doctor)
 */
export const getHospitalDepartmentsString = (doctor: Doctor): string => {
  if (!doctor.hospitalDepartments) {
    if (doctor.role !== 'cityDoctor') {
      return i18n.doctors.table.unknownDepartments
    }
    return i18n.doctors.table.cityDoctor
  }

  const hospitalDepartmentsNames = doctor.hospitalDepartments.map(
    (hospitalDepartment) => {
      let rowString = hospitalDepartment.name
      if (typeof hospitalDepartment.hospital !== 'string') {
        rowString = `${hospitalDepartment.hospital.name} - ${rowString}`
      }
      return rowString
    },
  )

  return hospitalDepartmentsNames.join(', ')
}

/**
 * Transforme un objet Doctor en un objet interpreté par le data-table de <Doctors />
 */
export const formatDoctorLines = (
  doctors: Array<Doctor>,
): Array<DoctorLine> => {
  return doctors.map((doctor) => {
    return {
      ...doctor,
      hospitalDepartments: getHospitalDepartmentsString(doctor),
    }
  })
}
