import Box from '@material-ui/core/Box/Box'
import Grid from '@material-ui/core/Grid/Grid'
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import {
  loadAppointmentsAction,
  loadLastAppointmentsAction,
} from '../../../context/actions/appointment'
import { loadDisturbingFormPostsAction } from '../../../context/actions/survey'
import { loadRiskyPatientsAction } from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import { settings } from '../../../theme/settings'
import {
  appointmentsToRowsAppointment,
  appointmentsToRowsHistory,
  appointmentsToRowsLastAppointment,
} from '../../../utils/dashboardMappings'
import {
  AppointmentTableIcon,
  LastAppointmentTableIcon,
  PatientTableIcon,
  ScoreTableIcon,
} from '../../assets/Icons'
import {
  CustomPaginationActionsTable,
  TableHeaderBleuActions,
  TableHeaderHistoryActions,
  TableHeaderLastAppointmentActions,
} from './CustomPaginationActionsTable/CustomPaginationActionsTable'
import { AppointmentTable } from './Tables/AppointmentTable'
import { HistoryTable } from './Tables/HistoryTable'
import { LastAppointmentTable } from './Tables/LastAppointmentTable'
import { PatientTable } from './Tables/PatientTable'
import { ScoreTable } from './Tables/ScoreTable'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  tableFooter: {
    '& tr td div p': {
      color: theme.palette.info.dark,
      fontSize: 12,
    },
    '&  svg': {
      color: theme.palette.info.dark,
    },
  },
}))

/**
 * Affiche des informations générales :
 *   - Patients à risques
 *   - Scores inquiétants
 *   - Demandes de rendez-vous
 *   - Historique des demandes de rendez-vous
 *   - Prochains rendez-vous
 */
export const Dashboard = () => {
  const classes = useStyles()
  const {
    dispatch,
    riskyPatients,
    disturbingFormPosts,
    appointments,
    lastAppointments,
  } = useAppContext()
  const [rowsAppointment, setRowsAppointment] = useState<
    Array<RowsAppointmentItem>
  >([])
  const [rowsHistory, setRowsHistory] = useState<Array<RowsHistoryItem>>([])
  const [rowsLastAppointment, setRowsLastAppointment] = useState<
    Array<RowsLastAppointmentItem>
  >([])

  // Récupère les données de l'API
  useEffect(() => {
    if (!riskyPatients.data) {
      dispatch(loadRiskyPatientsAction())
    }
    if (!disturbingFormPosts.data) {
      dispatch(loadDisturbingFormPostsAction())
    }

    if (!appointments.data) {
      dispatch(loadAppointmentsAction())
    }
    if (!lastAppointments.data) {
      dispatch(loadLastAppointmentsAction())
    }
  }, [
    dispatch,
    riskyPatients.data,
    disturbingFormPosts.data,
    appointments.data,
    lastAppointments.data,
  ])

  // Génère les données utilisées dans les composants graphiques à partir des données de l'API
  useEffect(() => {
    const newRowsAppointment = appointmentsToRowsAppointment(appointments.data)
    const newRowsHistory = appointmentsToRowsHistory(appointments.data)
    setRowsAppointment(newRowsAppointment)
    setRowsHistory(newRowsHistory)
  }, [appointments.data])

  useEffect(() => {
    const newRowsLastAppointment = appointmentsToRowsLastAppointment(
      lastAppointments.data,
    )
    setRowsLastAppointment(newRowsLastAppointment)
  }, [lastAppointments.data])

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        {/* PATIENT */}
        <Grid item xs={6}>
          <CustomPaginationActionsTable
            rowPerPage={5}
            isFullData={true}
            background={`${settings.theme.palette.dashboardTableGradient}`}
            header={
              <TableHeaderBleuActions
                media={<PatientTableIcon />}
                counter={riskyPatients.data}
                title={i18n.dashboard.table.title.patientsTitle}
              />
            }
            data={riskyPatients.data}
            TableComponent={PatientTable}
          />
        </Grid>

        {/* SCORE */}
        <Grid item xs={6}>
          <CustomPaginationActionsTable
            rowPerPage={5}
            isFullData={true}
            background={`${settings.theme.palette.dashboardTableGradient}`}
            header={
              <TableHeaderBleuActions
                media={<ScoreTableIcon />}
                counter={disturbingFormPosts.data}
                title={i18n.dashboard.table.title.scoreTitle}
              />
            }
            data={disturbingFormPosts.data}
            TableComponent={ScoreTable}
          />
        </Grid>

        {/* APPOINTMENT */}
        <Grid item xs={12}>
          <Box mt={6}></Box>
          <CustomPaginationActionsTable
            rowPerPage={5}
            isFullData={true}
            background={`${settings.theme.palette.dashboardTableGradient}`}
            header={
              <TableHeaderBleuActions
                media={<AppointmentTableIcon />}
                counter={rowsAppointment}
                title={i18n.dashboard.table.title.appointment}
              />
            }
            data={rowsAppointment}
            TableComponent={AppointmentTable}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: 50 }}>
        {/* HISTORY */}
        <Grid item xs={8}>
          <CustomPaginationActionsTable
            rowPerPage={10}
            isFullData={true}
            className={classes.tableFooter}
            background={`${settings.theme.palette.white}`}
            header={
              <TableHeaderHistoryActions
                media={<HistoryOutlinedIcon />}
                title={i18n.dashboard.table.title.histories}
              />
            }
            data={rowsHistory}
            TableComponent={HistoryTable}
          />
        </Grid>

        {/* LAST APPOINTMENT */}
        <Grid item xs={4}>
          <CustomPaginationActionsTable
            rowPerPage={5}
            background={`${settings.theme.palette.white}`}
            className={classes.tableFooter}
            header={
              <TableHeaderLastAppointmentActions
                media={<LastAppointmentTableIcon />}
                patient={rowsLastAppointment}
                title={i18n.dashboard.table.title.lastAppointment}
              />
            }
            data={rowsLastAppointment}
            TableComponent={LastAppointmentTable}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
