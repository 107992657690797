import {
  getAppointments,
  getLastAppointments,
} from '../../services/appointments.service'
import { fromApiAppointment } from '../../utils'
import { createAction } from '../app-context'

export const loadAppointmentsAction = createAction(
  () => async ({ produceState }) => {
    await produceState((draft) => (draft.appointments.state = 'loading'))
    try {
      const appointments = await getAppointments()
      await produceState((draft) => {
        draft.appointments.data = appointments.map(fromApiAppointment)
        draft.appointments.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => (draft.appointments.state = 'error'))
      throw new Error('could not load appointments')
    }
  },
)

export const loadLastAppointmentsAction = createAction(
  () => async ({ produceState }) => {
    await produceState((draft) => (draft.lastAppointments.state = 'loading'))
    try {
      const lastAppointments = await getLastAppointments()
      await produceState((draft) => {
        draft.lastAppointments.data = lastAppointments.map(fromApiAppointment)
        draft.lastAppointments.state = 'loaded'
      })
    } catch (e) {
      console.error(e)
      await produceState((draft) => (draft.lastAppointments.state = 'error'))
      throw new Error('could not load last appointments')
    }
  },
)
