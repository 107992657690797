import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { loadPatientsAction } from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import { getFullName } from '../../../utils/getFullName'
import { HeroBanner } from '../../ui/HeroBanner'
import { PatientMonitoring } from './PatientMonitoring'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: 0,
    position: 'relative',
  },
  headerContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  tabContainer: {
    width: '100%',
  },
}))

export const PatientDetails = () => {
  const classes = useStyles()
  const params = useParams<{ id?: string }>()
  const { dispatch, patients } = useAppContext()
  const [patient, setPatient] = useState<Patient | null>(null)

  useEffect(() => {
    const p = patients?.data?.find((patient) => patient.id === params.id)
    if (p) {
      setPatient(p)
    } else if (patients.state !== 'error' && patients.state !== 'loaded') {
      dispatch(loadPatientsAction())
    }
  }, [patients.data, dispatch, patients, patient, params.id])

  const goBack = () => {
    const targetUrl = '/patients'
    browserHistory.push(targetUrl)
  }

  return (
    <Grid container className={classes.root}>
      <Box className={classes.headerContainer}>
        <HeroBanner
          title={`${i18n.patientDetails.monitoringHeaderTitle} ${getFullName(
            patient?.firstName,
            patient?.lastName,
          )}`}
          imgPath={images.surveyList}
          goBack={goBack}
          goBackTitle={i18n.patientDetails.backToPatients}
        />
      </Box>
      <Box className={classes.tabContainer}>
        <PatientMonitoring />
      </Box>
    </Grid>
  )
}
