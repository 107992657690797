import React from 'react'

interface ImgProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {}

export const NotFoundScreen: React.FC<ImgProps> = (props) => (
  <img
    src="/images/common/images/illustration_not_found.svg"
    alt=""
    {...props}
  />
)
export const AuthImage: React.FC<ImgProps> = (props) => (
  <img src="/images/common/images/illustration_auth.svg" alt="" {...props} />
)
export const FormImage: React.FC<ImgProps> = (props) => (
  <img src="/images/common/icons/form_circle.svg" alt="" {...props} />
)
