import { format } from 'date-fns'
import { i18n } from '../assets/i18n'
import { settings } from '../theme/settings'
import { getFractionalScore } from './getFractionalScore'

/**
 * Cette fonction sert a récupérer les données à afficher dans la timeline à partir d'un objet HistoryEntry
 */
export const getTimelineDataFromEntry = (entry: HistoryEntry): TimelineData => {
  const strings = i18n.patientDetails.timeline

  const CHUColor = settings.theme.palette.success.main
  const patientColor = settings.theme.palette.primary.light
  const cityDoctorColor = settings.theme.palette.secondary.main
  const defaultColor = settings.theme.palette.info.dark

  let color = defaultColor
  let header = ''
  let title = ''
  let description = ''

  const getColorFromRole = (role: Role | undefined): string => {
    if (role === 'hospitalDoctor') {
      return CHUColor
    }
    if (role === 'cityDoctor') {
      return cityDoctorColor
    }
    if (role === 'patient') {
      return patientColor
    }
    return defaultColor
  }

  const getHeaderFromRole = (role: Role | undefined): string => {
    if (role === 'hospitalDoctor') {
      return strings.header.CHU
    }
    if (role === 'cityDoctor') {
      return strings.header.cityDoctor
    }
    if (role === 'patient') {
      return strings.header.patient
    }
    return strings.header.unknown
  }

  const getQuestionnaireName = (formPost: FormPost | undefined) => {
    if (formPost === undefined) {
      return strings.titles.formFilled
    }
    return formPost.questionnaires.map((form) => form.technicalName).join(', ')
  }

  const getQuestionnaireScore = (formPost: FormPost | undefined) => {
    if (formPost === undefined) {
      return strings.descriptions.formFilled
    }
    return formPost.questionnaires
      .map((form) => `Score : ` + getFractionalScore(form.score))
      .join(', ')
  }

  // Détermine la couleur et le type (patient / CHU / médecin traitant)
  let role: Role | undefined = 'patient'
  if (entry.type === 'doctor-note') {
    role = entry.doctorNote?.author.role
  }
  if (entry.type === 'appointment') {
    role = entry.appointmentDoctor?.role
  }
  color = getColorFromRole(role)
  header = getHeaderFromRole(role)

  // Détermine le titre et la description
  switch (entry.type) {
    case 'user-activated':
      title = strings.titles.userActivated
      description = strings.descriptions.userActivated
      break
    case 'form-filled':
      title =
        strings.titles.formFilled + ' : ' + getQuestionnaireName(entry.formPost)
      description = getQuestionnaireScore(entry.formPost)
      break
    case 'message-sent':
      if (entry.message?.type === 'newpatient') {
        title = strings.titles.messageSentNewPatient
        description = strings.descriptions.messageSentNewPatient
      } else if (entry.message?.type === 'appointment') {
        title = strings.titles.messageSentAppointment
        description = strings.descriptions.messageSentAppointment
      } else {
        header = strings.header.unknown
        title = strings.titles.unknown
      }
      break
    case 'mood-sent':
      title = strings.titles.moodSent
      description = strings.descriptions.moodSent
      break
    case 'doctor-note':
      title = entry.doctorNote?.title || strings.titles.doctorNote
      description =
        entry.doctorNote?.description || strings.descriptions.unknown
      break
    case 'appointment':
      const appointmentDoctorFullName = `${entry.appointmentDoctor?.firstName} ${entry.appointmentDoctor?.lastName}`
      const hours = format(entry.date, 'HH')
      const minutes = format(entry.date, 'mm')
      const appointmentTime = `${hours}h${minutes}`
      title = entry.appointmentType || strings.descriptions.unknown
      description = `
        ${strings.descriptions.appointmentAt}
        ${appointmentTime}
        ${strings.descriptions.appointmentWith}
        ${appointmentDoctorFullName}
      `
      break
    default:
      color = defaultColor
      header = strings.header.unknown
      title = strings.titles.unknown
      description = strings.descriptions.unknown
  }

  return {
    color,
    header,
    title,
    description,
  }
}

/**
 * La première connexion d'un patient n'est pas stockée en base comme une entrée d'historique
 * Cette fonction sert à construire une entrée d'historique correspondant à la première connexion du patient
 */
export const getInitialPatientEntries = (
  patient: Patient,
): Array<HistoryEntry> => {
  if (!patient.activatedAt) {
    return []
  }

  const userActivatedEntry: HistoryEntry = {
    id: `user-activated-${patient.id}`,
    user: patient.id,
    date: new Date(patient.activatedAt),
    type: 'user-activated',
  }

  return [userActivatedEntry]
}
