import { GridSpacing, makeStyles } from '@material-ui/core'
import { ChuTheme } from '../../@types/theme'

export const USER_FORM_WIDTH = 690

export const USER_FORM_SPACING: GridSpacing = 2

export const useUserFormStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
    position: 'relative',
    '& h3': {
      textTransform: 'uppercase',
      color: theme.palette.basicGrey,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(6),
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: USER_FORM_WIDTH,
    backgroundColor: theme.palette.white,
    margin: '0 auto',
  },
  formSection: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.white,
  },
  formSectionDark: {
    backgroundColor: theme.palette.accentBackgroundColor,
  },
  formControl: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    marginRight: 16,
  },
  select: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  roleContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 5,
    paddingBottom: 0,
  },
  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  loadingIcon: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))
