import { Link } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { format } from 'date-fns'
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import { loadPatientsAction } from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import { useAuth } from '../../../context/auth-context'
import browserHistory from '../../../router/history'
import { images } from '../../../theme/images'
import { settings } from '../../../theme/settings'
import { DATA_TABLE_OPTIONS } from '../../../utils/dataTableOptions'
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber'
import { HeroBanner } from '../../ui/HeroBanner'
import { HeroCounterElements } from '../../ui/HeroCounterElements'
import { LoadingPane } from '../../ui/LoadingPane'
import { LastFormDetails } from './LastFormDetails'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
  },
  customCellRender: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  customCellLastName: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  linkButton: {
    minWidth: 120,
    color: settings.theme.palette.primary.light,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
  },
  cellWithoutPadding: {
    padding: 0,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
}))

export const PatientsFollowed = () => {
  const classes = useStyles()

  const { dispatch, patients } = useAppContext()
  const { user } = useAuth()
  const [lines, setLines] = useState(patients.data || [])

  useEffect(() => {
    if (!patients.data) {
      dispatch(loadPatientsAction())
    }
  }, [dispatch, patients.data])

  useEffect(() => {
    setLines(patients.data || [])
  }, [patients.data])

  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'activatedAt',
      label: 'Activated At',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'lastName',
      label: i18n.patients.table.headers.lastName,
      options: {
        customBodyRender: (value: any) => {
          return <span className={classes.customCellLastName}>{value}</span>
        },
      },
    },
    {
      name: 'firstName',
      label: i18n.patients.table.headers.firstName,
      options: {
        customBodyRender: (value: any) => {
          return <span className={classes.customCellRender}>{value}</span>
        },
      },
    },
    {
      name: 'lastFormPost',
      label: i18n.patients.table.headers.lastForm,
      options: {
        sort: false,
        download: false,
        setCellProps: () => ({
          className: classes.cellWithoutPadding,
        }),
        customBodyRender: (value: Form | undefined, tableMeta) => {
          const activatedAt = tableMeta.rowData[1]
          return <LastFormDetails form={value} activatedAt={activatedAt} />
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        download: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <Link
              component="button"
              onClick={() => handleOnEditClick(tableMeta.rowData[0])}
              className={classes.linkButton}
            >
              {i18n.patientTable.linkPatientDetails} <KeyboardArrowRight />
            </Link>
          )
        },
      },
    },
  ]

  const columnsForInspector: MUIDataTableColumnDef[] = [
    {
      name: 'id',
      label: 'Id',
      options: {
        display: 'excluded',
        filter: false,
      },
    },
    {
      name: 'codeId',
      label: i18n.patients.table.headers.codeId,
      options: {
        customBodyRender: (value: string) => {
          return <span className={classes.customCellLastName}>{value}</span>
        },
      },
    },
    {
      name: 'phoneNumber',
      label: i18n.patients.table.headers.phoneNumber,
      options: {
        customBodyRender: (value: string) => {
          return (
            <span className={clsx(classes.customCellRender, classes.nowrap)}>
              {formatPhoneNumber(value)}
            </span>
          )
        },
      },
    },
    {
      name: 'lastFormPost',
      label: i18n.patients.table.headers.lastForm,
      options: {
        sort: false,
        download: false,
        setCellProps: () => ({
          className: classes.cellWithoutPadding,
        }),
        customBodyRender: (value: Form | undefined, tableMeta) => {
          const activatedAt = tableMeta.rowData[1]
          return <LastFormDetails form={value} activatedAt={activatedAt} />
        },
      },
    },
    {
      name: '',
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        empty: true,
        customBodyRender: (_, tableMeta) => {
          return (
            <a
              href={`${process.env.REACT_APP_PATIENT_URL}/patient/${tableMeta.rowData[0]}`}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkButton}
            >
              {i18n.patientTable.linkAnswerWithPatient} <KeyboardArrowRight />
            </a>
          )
        },
      },
    },
  ]

  if (patients.state === 'loading') {
    return <LoadingPane />
  }

  return (
    <Grid container className={classes.root}>
      <HeroBanner
        imgPath={images.surveyList}
        title={i18n.patientBanner.patientManagment}
      />
      <HeroCounterElements
        startString={i18n.surveyBanner.surveyNumberStart}
        counter={lines.length}
        counterDesc={i18n.patientBanner.patientNumberCounter}
      />
      <Grid item xs={12}>
        <MUIDataTable
          title=""
          columns={
            user.data?.role === 'inspector' ? columnsForInspector : columns
          }
          data={lines}
          options={{
            ...DATA_TABLE_OPTIONS,
            downloadOptions: {
              filename: `${i18n.dataTables.patientsFollowed.title}-${format(
                new Date(),
                'yyyy-MM-dd',
              )}.csv`,
            },
            rowsPerPage: 100,
            rowsPerPageOptions: [100, 200, 500],
          }}
        />
      </Grid>
    </Grid>
  )
}
