import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { FormikErrors, FormikTouched } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { ChuTheme } from '../../@types/theme'
import { i18n } from '../../assets/i18n'
import {
  loadHospitalDepartmentsByHospitalAction,
  loadHospitalsAction,
} from '../../context/actions/hospital'
import { useAppContext } from '../../context/app-context'
import { AuthContext } from '../../context/auth-context'
import { getAvailableHospitals } from '../../utils/getAvailableHospitals'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  select: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  formControl: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  deleteButton: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}))

// Etat des services d'un hôpital : correspond à 1 select d'hôpital + 1 select multiple de services
interface HospitalDepartmentsSelect {
  hospital: string
  hospitalDepartments: Array<string>
}

interface Props {
  errors: FormikErrors<CreateDoctorFormValues>
  touched: FormikTouched<CreateDoctorFormValues>
  setValue: (value: Array<string>) => void
  resetHospitalDoctor?: () => void
  initialHospitalDepartments?: Array<HospitalDepartment>
  multiple?: boolean
}

/**
 * Ce composant permet de modifier la liste des id de services dans Formik (clé hospitalDepartments) en utilisant plusieurs <Select /> plutôt qu’un grand <Select /> qui contiendrait toutes les valeurs.
 * Initialement, un <Select /> d’hôpital est présent et on fetch les hospitalDepartments correspondant à cet hôpital.
 * Un autre <Select multiple /> apparait à sa droite et permet de sélectionner les hospitalDepartments du médecin pour cet hôpital.
 * Si on change la valeur du <Select /> d’hôpital, on va fetcher les hospitalDepartments correspondant au nouvel hôpital sélectionné (sauf si on les a déjà fetchées auparavant)
 * On peut cliquer sur “Ajouter un autre hôpital”, ce qui crée une deuxième ligne avec un autre <Select /> d’hôpital, et à sa droite un autre <Select multiple /> pour les services correspondant.
 * Si on a au moins 2 lignes, i.e. 2 * (select hôpital + select services), une icône moche apparait et permet de supprimer une ligne.
 * Le composant <HospitalDepartmentsSelect /> stocke l'état des <Select /> dans un state hospitalDepartmentsSelects (sur lequel on map pour afficher les <Select />) et se charge de traduire tout ça juste en un tableau d’id de services pour la clé hospitalDepartments du Formik.
 */
export const HospitalDepartmentsSelector: React.FC<Props> = ({
  errors,
  touched,
  setValue,
  resetHospitalDoctor,
  initialHospitalDepartments = [],
  multiple,
}) => {
  const classes = useStyles()
  const { dispatch, hospitals, hospitalDepartments } = useAppContext()
  const { user } = useContext(AuthContext)

  const availableHospitals = getAvailableHospitals(user.data, hospitals.data)

  const getHospitalId = (department: HospitalDepartment): string =>
    typeof department.hospital === 'string'
      ? department.hospital
      : department.hospital.id

  // getInitalHospitalDepartmentsSelects sert à donner les valeurs initiales des services lorsqu'on s'apprête à modifier un médecin
  // Pour chaque service dans initialHospitalDepartments, crée une entrée dans initalHospitalDepartmentsSelects si l'hôpital n'est pas encore référencé
  // Si l'hôpital est déjà référencé dans initialHospitalDepartmentsSelects, ajoute le service dans la liste des services correspondant à cet hôpital
  const getInitalHospitalDepartmentsSelects = () => {
    const initialHospitalDepartmentsSelects: Array<HospitalDepartmentsSelect> = []
    for (const hospitalDepartment of initialHospitalDepartments) {
      const index = initialHospitalDepartmentsSelects.findIndex(
        (initialHospitalDepartmentsSelect) =>
          initialHospitalDepartmentsSelect.hospital ===
          getHospitalId(hospitalDepartment),
      )

      if (index === -1) {
        initialHospitalDepartmentsSelects.push({
          hospital: getHospitalId(hospitalDepartment),
          hospitalDepartments: [hospitalDepartment.id],
        })
        dispatch(
          loadHospitalDepartmentsByHospitalAction(
            getHospitalId(hospitalDepartment),
          ),
        )
      } else {
        initialHospitalDepartmentsSelects[index].hospitalDepartments.push(
          hospitalDepartment.id,
        )
      }
    }
    return initialHospitalDepartmentsSelects
  }

  // hospitalDepartmentsSelects est un objet stockant l'état des selects d'hospital et d'hospitalDepartment
  const [hospitalDepartmentsSelects, setHospitalDepartmentsSelects] = useState<
    Array<HospitalDepartmentsSelect>
  >(getInitalHospitalDepartmentsSelects)

  useEffect(() => {
    if (hospitals.state !== 'loaded' && hospitals.state !== 'error') {
      dispatch(loadHospitalsAction())
    }
  }, [hospitals.state, dispatch])

  // Lorsque les hôpitaux sont chargés, initialise la gestion des hôpitaux / services
  useEffect(() => {
    if (
      hospitalDepartmentsSelects.length === 0 &&
      hospitals?.data &&
      hospitals.data.length > 0
    ) {
      const availableHospitals = getAvailableHospitals(
        user.data,
        hospitals.data,
      )
      if (availableHospitals.length < 1) {
        return
      }
      dispatch(
        loadHospitalDepartmentsByHospitalAction(availableHospitals[0].id),
      )
      setHospitalDepartmentsSelects([
        {
          hospital: availableHospitals[0].id,
          hospitalDepartments: [],
        },
      ])
    }
    // eslint-disable-next-line
  }, [hospitals?.data, hospitalDepartmentsSelects.length, dispatch, hospitals])

  // Lorsqu'un hôpital est sélectionné, met à jour l'état des select
  // Lorsqu'un hôpital est sélectionné alors que ses hospitalDepartments ne sont pas disponibles, fetch ses hospitalDepartments
  const handleSelectHospital = (index: number, hospitalId: any) => {
    if (typeof hospitalId !== 'string') {
      return
    }
    if (hospitalDepartmentsSelects[index]) {
      const newHospitalDepartmentsSelects = hospitalDepartmentsSelects.map(
        (hospitalDepartmentsSelect, i) => {
          if (i === index) {
            return {
              hospital: hospitalId,
              hospitalDepartments: [],
            }
          }
          return hospitalDepartmentsSelect
        },
      )
      setHospitalDepartmentsSelects(newHospitalDepartmentsSelects)
      setValue([])
      resetHospitalDoctor && resetHospitalDoctor()
    }
    if (
      hospitalDepartments?.data?.some(
        (hospitalDepartment) => hospitalDepartment.hospital === hospitalId,
      )
    ) {
      return
    }
    dispatch(loadHospitalDepartmentsByHospitalAction(hospitalId))
  }

  // Lorsqu'un service est sélectionné, met à jour la gestion des selects (hospitalDepartmentsSelects) et la valeur dans Formik grâce à setValue
  const handleSelectHospitalDepartment = (index: number, selected: any) => {
    // hospitalDepartmentSelecter est un string, ou bien un tableau de string dans le cas multiple = true
    // Dans tous les cas on travaillera avec un tableau de string, on crée donc un tableau dans le cas où multiple = false
    const hospitalDepartmentsSelected = multiple ? selected : [selected]

    // On est obligé de typer hospitalDepartmentsSelect en any donc on commence par vérifier si c'est bien un tableau de string
    if (
      !Array.isArray(hospitalDepartmentsSelected) ||
      hospitalDepartmentsSelected.length < 1 ||
      typeof hospitalDepartmentsSelected[0] !== 'string'
    ) {
      return
    }

    if (hospitalDepartmentsSelects[index]) {
      // Mise à jour de l'état des select
      const newHospitalDepartmentsSelects = hospitalDepartmentsSelects.map(
        (hospitalDepartmentsSelect, i) => {
          if (i === index) {
            return {
              ...hospitalDepartmentsSelect,
              hospitalDepartments: hospitalDepartmentsSelected,
            }
          }
          return hospitalDepartmentsSelect
        },
      )
      setHospitalDepartmentsSelects(newHospitalDepartmentsSelects)

      // Mise à jour du tableau d'id de services dans Formik
      let newHospitalDepartments: Array<string> = []
      for (const newHospitalDepartmentsSelect of newHospitalDepartmentsSelects) {
        for (const newHospitalDepartment of newHospitalDepartmentsSelect.hospitalDepartments) {
          newHospitalDepartments.push(newHospitalDepartment)
        }
      }
      setValue(newHospitalDepartments)
    }
  }

  // Ajout d'une nouvelle ligne (select d'hôpital + select multiple de services)
  const addHospitalSelect = () => {
    if (!hospitals?.data) {
      return
    }
    // L'hôpital sélectionné dans la nouvelle ligne est le premier de la liste qui n'est pas déjà sélectionné dans une autre ligne
    const hospital = hospitals.data.find(
      (hospital) =>
        !hospitalDepartmentsSelects.some(
          (hospitalDepartmentsSelect) =>
            hospitalDepartmentsSelect.hospital === hospital.id,
        ),
    )
    if (!hospital) {
      return
    }
    const newHospitalDepartmentsSelect: HospitalDepartmentsSelect = {
      hospital: hospital.id,
      hospitalDepartments: [],
    }
    const newHospitalDepartmentsSelects = [
      ...hospitalDepartmentsSelects,
      newHospitalDepartmentsSelect,
    ]
    setHospitalDepartmentsSelects(newHospitalDepartmentsSelects)

    if (
      hospitalDepartments?.data?.some(
        (hospitalDepartment) => hospitalDepartment.hospital === hospital.id,
      )
    ) {
      return
    }
    dispatch(loadHospitalDepartmentsByHospitalAction(hospital.id))
  }

  // Suppression d'une ligne (select d'hôpital + select multiple de services)
  const removeHospitalSelect = (index: number) => {
    if (index > hospitalDepartmentsSelects.length - 1) {
      return
    }

    // Suppression de la ligne dans l'état des selects
    const newHospitalDepartmentsSelects = hospitalDepartmentsSelects.filter(
      (_, i) => i !== index,
    )
    setHospitalDepartmentsSelects(newHospitalDepartmentsSelects)

    // Suppression des services correspondant dans l'état du Formik
    let newHospitalDepartments: Array<string> = []
    for (const newHospitalDepartmentsSelect of newHospitalDepartmentsSelects) {
      for (const newHospitalDepartment of newHospitalDepartmentsSelect.hospitalDepartments) {
        newHospitalDepartments.push(newHospitalDepartment)
      }
    }
    setValue(newHospitalDepartments)
  }

  return (
    <>
      {hospitalDepartmentsSelects.length > 0 && (
        <>
          {hospitalDepartmentsSelects.map(
            (hospitalDepartmentsSelect, index) => (
              <Box key={index} width="100%" display="flex">
                <Box width="50%" marginRight="16px">
                  {/* HOSPITAL */}
                  <FormControl
                    error={
                      !!errors.hospitalDepartments &&
                      touched.hospitalDepartments
                    }
                    className={classes.formControl}
                  >
                    <InputLabel style={{ paddingLeft: 16 }}>
                      {i18n.doctors.create.hospital.label}
                    </InputLabel>
                    <Select
                      classes={{ root: classes.select }}
                      value={hospitalDepartmentsSelect.hospital}
                      name="hospital"
                      label={i18n.doctors.create.hospital.label}
                      placeholder={i18n.doctors.create.hospital.label}
                      variant="outlined"
                      onChange={(e) =>
                        handleSelectHospital(index, e.target.value)
                      }
                    >
                      {availableHospitals.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                {/* HOSPITAL DEPARTMENT */}
                {hospitalDepartments?.data?.some(
                  (hospitalDepartment) =>
                    hospitalDepartment.hospital ===
                    hospitalDepartmentsSelect.hospital,
                ) && (
                  <Box width="50%">
                    <FormControl
                      error={
                        !!errors.hospitalDepartments &&
                        touched.hospitalDepartments
                      }
                      className={classes.formControl}
                    >
                      <InputLabel style={{ paddingLeft: 16 }}>
                        {multiple
                          ? i18n.doctors.create.hospitalDepartments.label
                          : i18n.patients.create.hospitalDepartment.label}
                      </InputLabel>
                      <Select
                        classes={{ root: classes.select }}
                        value={
                          multiple
                            ? hospitalDepartmentsSelect.hospitalDepartments
                            : hospitalDepartmentsSelect
                                .hospitalDepartments[0] || ''
                        }
                        name="hospitalDepartments"
                        label={i18n.doctors.create.hospital.label}
                        placeholder={i18n.doctors.create.hospital.label}
                        variant="outlined"
                        onChange={(e) =>
                          handleSelectHospitalDepartment(index, e.target.value)
                        }
                        multiple={multiple}
                      >
                        {hospitalDepartments?.data
                          ?.filter(
                            (hospitalDepartment) =>
                              hospitalDepartment.hospital ===
                              hospitalDepartmentsSelect.hospital,
                          )
                          .map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                      </Select>
                      {touched.hospitalDepartments &&
                        hospitalDepartmentsSelect.hospitalDepartments.length ===
                          0 && (
                          <FormHelperText>
                            {multiple
                              ? i18n.doctors.create.hospitalDepartments.required
                              : i18n.patients.create.hospitalDepartment
                                  .required}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </Box>
                )}

                {/* SUPPRESSION DE LIGNE */}
                {hospitalDepartmentsSelects.length > 1 && (
                  <IconButton
                    onClick={() => removeHospitalSelect(index)}
                    className={classes.deleteButton}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            ),
          )}
          {/* AJOUTE DE LIGNE */}
          {multiple &&
            availableHospitals &&
            hospitalDepartmentsSelects.length < availableHospitals.length && (
              <Box mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={addHospitalSelect}
                >
                  {i18n.doctors.create.hospital.add}
                </Button>
              </Box>
            )}
        </>
      )}
      {hospitalDepartmentsSelects.length === 0 && (
        <Box>
          <Typography>{i18n.doctors.create.hospital.loading}</Typography>
        </Box>
      )}
    </>
  )
}
