import { Button, DialogProps, Grid, makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ChuTheme } from '../../../@types/theme'
import { i18n } from '../../../assets/i18n'
import {
  loadPatientHistoryAction,
  resetPatientHistoryAction,
} from '../../../context/actions/history'
import { loadHospitalsAction } from '../../../context/actions/hospital'
import {
  loadPatientMoodAction,
  resetPatientMoodsAction,
} from '../../../context/actions/mood'
import {
  loadDoctorsAction,
  loadPatientsAction,
} from '../../../context/actions/user'
import { useAppContext } from '../../../context/app-context'
import { AuthContext } from '../../../context/auth-context'
import browserHistory from '../../../router/history'
import { getOutOfReanimationPdf } from '../../../services/pdf.service'
import { getFullName } from '../../../utils/getFullName'
import { isRoleAmong } from '../../../utils/isRoleAmong'
import { DialogPatientMonitoring } from '../../assets/Icons'
import { BottomActionBar } from '../../ui/BottomActionBar'
import { DialogFormAction } from '../../ui/DialogFormAction'
import { ActivatedAtBloc } from './Blocs/ActivatedAtBloc'
import { BlocIcons } from './Blocs/BlocIcons'
import { DoctorBloc } from './Blocs/DoctorBloc'
import { IdentityBloc } from './Blocs/IdentityBloc'
import { MoodIconIndicator } from './Blocs/MoodIconIndicator'
import { PatientMood } from './Blocs/PatientMood'
import { ReanimationBloc } from './Blocs/ReanimationBloc'
import { OutOfReanimationPdfDialog } from './Pdf/OutOfReanimationPdfDialog'
import { EventActionForm } from './Timeline/EventActionForm'
import { Timeline } from './Timeline/Timeline'

const useStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},

  labelBox: {
    textTransform: 'uppercase',
    color: theme.palette.paperInformation,
    textAlign: 'right',
    marginTop: 15,
  },
  indicator: {
    marginLeft: 'calc(100% - 250px)',
    marginTop: 20,
    display: 'flex',
  },

  patientContent: {
    paddingTop: theme.spacing(16),
  },
  patientBloc: {
    position: 'relative',
    height: '100%',
    paddingBottom: 90,
    borderRadius: 8,
  },
  boxIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-60px',
    textAlign: 'center',
    '& h3': {
      textTransform: 'uppercase',
      fontSize: 26,
    },
  },

  timelineTitle: {
    color: theme.palette.primary.main,
    fontSize: 26,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
  },
}))

export const PatientMonitoring = () => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()

  const {
    dispatch,
    patients,
    doctors,
    hospitals,
    patientHistory,
  } = useAppContext()
  const { user } = useContext(AuthContext)

  const [patient, setPatient] = useState<Patient | null>(null)
  const [reanimator, setReanimator] = useState<Doctor | null>(null)
  const [cityDoctor, setCityDoctor] = useState<Doctor | null>(null)
  const [hospital, setHospital] = useState<Hospital | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isPdfDialogOpen, setIsPdfDialogOpen] = useState(false)
  const [maxWidth] = useState<DialogProps['maxWidth']>('sm')

  /**
   * Récupère le patient à partir du param d'url, ainsi que son hôpital
   */
  useEffect(() => {
    if (!patients.data) {
      dispatch(loadPatientsAction())
    }
    if (patients && patients.data) {
      // Récupère le patient
      const currentPatient = patients.data.find((el) => el.id === id)
      if (!currentPatient) {
        return
      }
      setPatient(currentPatient)

      // Récupère son hôpital
      const hospital = currentPatient.hospitalDepartment?.hospital
      if (typeof hospital === 'string') {
        return
      }
      setHospital(hospital)
    }
  }, [patients.data, dispatch, hospitals, id, patients, patient])

  useEffect(() => {
    if (!doctors.data) {
      dispatch(loadDoctorsAction())
    }
  }, [dispatch, doctors.data])

  useEffect(() => {
    if (!hospitals.data) {
      dispatch(loadHospitalsAction())
    }
  }, [dispatch, hospitals.data])

  useEffect(() => {
    if (!patient || !doctors.data) {
      return
    }
    const hospitalDoctor =
      doctors.data.find((doctor) => doctor.id === patient.doctors[0]) || null
    setReanimator(hospitalDoctor)
    const cityDoctor =
      doctors.data.find((doctor) => doctor.id === patient.cityDoctor) || null
    setCityDoctor(cityDoctor)
  }, [doctors, patient])

  useEffect(() => {
    dispatch(loadPatientMoodAction(id))

    return () => {
      dispatch(resetPatientMoodsAction())
    }
  }, [dispatch, id])

  useEffect(() => {
    dispatch(loadPatientHistoryAction(id))

    return () => {
      dispatch(resetPatientHistoryAction())
    }
  }, [dispatch, id])

  const updatePatient = (id: string) => {
    const targetUrl = `/admin/patients/edit/${id}`
    browserHistory.push(targetUrl)
  }

  const createDoctor = () => {
    const targetUrl = '/admin/doctors/create'
    browserHistory.push(targetUrl)
  }

  const handleClickOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleDownloadOutOfReanimationPdf = async () => {
    if (!patient) {
      return
    }

    // Récupération d'une url vers un blob du pdf
    const pdf = await getOutOfReanimationPdf(patient.id)
    var blob = new Blob([pdf], { type: 'application/pdf' })
    var objectUrl = URL.createObjectURL(blob)

    // Pour Internet Explorer le téléchargement direct n'est pas compatible (cf. https://caniuse.com/#feat=download)
    // Lorsque l'utilisateur est sur Internet Explorer, le pdf est ouvert dans une nouvelle page
    const isInternetExplorer = window.navigator.userAgent.indexOf('MSIE ') > 0
    if (isInternetExplorer) {
      window.open(objectUrl)
      return
    }

    // Lorsque le navigateur n'est pas Internet Explorer, simule un clic sur un lien avec un attribut "download" afin de télécharger directement le pdf
    const a = document.createElement('a')
    a.setAttribute('href', objectUrl)
    a.setAttribute(
      'download',
      `${
        i18n.patientDetails.outOfReanimationPdfName
      } - ${patient.lastName.toUpperCase()} ${patient.firstName}.pdf`,
    )
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const hospitalName =
    user.data?.hospitalDepartments &&
    typeof user.data?.hospitalDepartments[0]?.hospital !== 'string'
      ? user.data?.hospitalDepartments[0]?.hospital.name
      : i18n.patients.outOfReanimationPdfDialog.defaultMailChu

  return (
    <>
      {patient && (
        <>
          <Grid container spacing={4} className={classes.root}>
            <Grid item sm={12} md={4} lg={4} xl={6}>
              {/* Identité et activé le... */}
              <Box>
                <IdentityBloc patient={patient} userRole={user.data?.role} />
                <ActivatedAtBloc patient={patient} />
              </Box>
            </Grid>

            <Grid item sm={12} md={8} lg={8} xl={6}>
              <Grid container spacing={4}>
                {/* LAST MOOD */}
                <Grid item sm={12} md={12} lg={6} xl={6}>
                  <Typography variant="h5" className={classes.labelBox}>
                    {i18n.patientDetails.moods.lastState}
                  </Typography>
                  <Box className={classes.indicator}>
                    <MoodIconIndicator />
                  </Box>
                </Grid>

                {/* DOCTORS */}
                <Grid item sm={12} md={12} lg={6} xl={6}>
                  {!isRoleAmong(user.data?.role, ['cityDoctor']) && (
                    <>
                      <DoctorBloc
                        reference={i18n.doctors.medicated.designation}
                        onClick={() => updatePatient(patient.id)}
                        onClickDisabled={createDoctor}
                        fullName={
                          cityDoctor
                            ? getFullName(
                                cityDoctor?.firstName,
                                cityDoctor?.lastName,
                              )
                            : undefined
                        }
                        email={cityDoctor?.email}
                        phoneNumber={cityDoctor?.phoneNumber}
                        address={cityDoctor?.address}
                        notFound={i18n.doctors.medicated.doctorNotFound}
                        disabled={
                          !isRoleAmong(user.data?.role, [
                            'admin',
                            'hospitalAdmin',
                            'hospitalDoctor',
                          ])
                        }
                      />
                      <br />
                    </>
                  )}
                  <DoctorBloc
                    reference={i18n.doctors.medicated.reanimator}
                    onClick={() => updatePatient(patient.id)}
                    fullName={getFullName(
                      reanimator?.firstName,
                      reanimator?.lastName,
                    )}
                    hospital={hospital?.name}
                    notFound={i18n.doctors.medicated.reanimatorNotFound}
                    disabled={
                      !isRoleAmong(user.data?.role, [
                        'admin',
                        'hospitalAdmin',
                        'hospitalDoctor',
                      ])
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {patientHistory.data && (
            <Box mt={8}>
              <Typography className={classes.timelineTitle}>
                {i18n.patientDetails.timeline.title}
              </Typography>
              <Timeline history={patientHistory.data} patient={patient} />
            </Box>
          )}

          <Box display="flex" justifyContent="center" pt={4}>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleClickOpen}
            >
              {i18n.button.newAction}
            </Button>
          </Box>

          <Grid container spacing={4} className={classes.patientContent}>
            {/* EN REANIMATION */}
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Paper className={classes.patientBloc} elevation={0}>
                <BlocIcons
                  imgSrc="/images/common/icons/hospital_circle.svg"
                  title={i18n.patientDetails.reanimation}
                  className={classes.boxIcon}
                />
                <Box className={classes.columnContainer}>
                  <ReanimationBloc patient={patient} />
                </Box>
              </Paper>
            </Grid>

            {/* A LA MAISON */}
            <Grid item sm={12} md={12} lg={6} xl={6}>
              <Paper className={classes.patientBloc} elevation={0}>
                <BlocIcons
                  imgSrc="/images/common/icons/house_yellow_large.svg"
                  title={i18n.patientDetails.atHome}
                  className={classes.boxIcon}
                />
                <Box className={classes.columnContainer}>
                  <PatientMood />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {isRoleAmong(user.data?.role, ['admin', 'hospitalDoctor']) && (
        <BottomActionBar
          firstButton={{
            label: i18n.patients.actionBar.sendToDoctor,
            disabled: !Boolean(patient?.cityDoctor),
            tooltip: patient?.cityDoctor
              ? null
              : i18n.patients.actionBar.requiredCityDoctorTooltip,
            onClick: () => setIsPdfDialogOpen(true),
          }}
          secondButton={{
            label: i18n.patients.actionBar.addAction,
            onClick: handleClickOpen,
          }}
        />
      )}

      {patient && cityDoctor && (
        <OutOfReanimationPdfDialog
          isOpen={isPdfDialogOpen}
          maxWidth={maxWidth}
          handleClose={() => setIsPdfDialogOpen(false)}
          handleDownloadOutOfReanimationPdf={handleDownloadOutOfReanimationPdf}
          cityDoctorEmail={cityDoctor.email}
          patientFullName={getFullName(patient.firstName, patient.lastName)}
          hospitalName={hospitalName}
        />
      )}

      {/* AJOUT D'UNE ENTREE D'HISTORIQUE */}
      {patient && (
        <DialogFormAction
          open={isOpen}
          maxWidth={maxWidth}
          onClose={handleClose}
          icon={<DialogPatientMonitoring />}
          title={i18n.patients.actionDialog.title.toLocaleUpperCase()}
        >
          <EventActionForm onClose={handleClose} patient={patient} />
        </DialogFormAction>
      )}
    </>
  )
}
