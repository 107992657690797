import Link from '@material-ui/core/Link'
import TableCell from '@material-ui/core/TableCell'
import { KeyboardArrowRight } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import browserHistory from '../../../../router/history'
import { getFullName } from '../../../../utils/getFullName'
import { AppointmentIcon } from './AppointmentIcon'

interface Props {
  data: RowsLastAppointmentItem
}
const useTableStyles = makeStyles<ChuTheme>((theme) => ({
  root: {},
  tableLink: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  patient: {
    color: `${theme.palette.info.dark} !important`,
  },
}))

export const LastAppointmentTable: React.FC<Props> = ({ data }) => {
  const classes = useTableStyles()
  const handleOnEditClick = (id: string) => {
    browserHistory.push(`/patients/${id}`)
  }

  return (
    <>
      <TableCell component="th" align="center">
        <AppointmentIcon type={data.type} />
      </TableCell>
      <TableCell component="th" align="center">
        {data.appointmentDate}
      </TableCell>
      <TableCell component="th" align="center">
        {data.appointmentHour}
      </TableCell>
      <TableCell component="th" align="center" className={classes.patient}>
        {getFullName(data.firstName, data.lastName)}
      </TableCell>
      <TableCell component="th" align="center" style={{ position: 'relative' }}>
        <Link
          onClick={() => handleOnEditClick(data.id)}
          className={classes.tableLink}
        >
          <KeyboardArrowRight />
        </Link>
      </TableCell>
    </>
  )
}
