import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { ChuTheme } from '../../../../@types/theme'
import { ArrowRightGreyIcon } from '../../../assets/Icons'

interface Props {
  direction: 'left' | 'right'
  visible: boolean
  onClick: () => void
}

export const TIMELINE_SCROLL_BUTTON_WIDTH = 40

const useStyles = makeStyles<ChuTheme, Props>((theme) => ({
  root: {
    opacity: (props) => (props.visible ? 1 : 0),
    height: '100%',
    width: TIMELINE_SCROLL_BUTTON_WIDTH,
    minWidth: '40px',
    backgroundColor: theme.palette.accentBackgroundColor,
    boxShadow: `0px 3px 6px ${theme.palette.shadow}`,
    transition: 'all 0.5s',

    '&:hover': {
      backgroundColor: theme.palette.backgroundDark,
    },
  },
  inverse: {
    transform: 'rotate(180deg)',
  },
}))

/**
 * Bouton utilisé dans <Timeline /> afin de permettre à l'utilisateur de scroller d'une certaine valeur (la moitié de la timeline affichée)
 */
export const TimelineScrollButton: React.FC<Props> = (props) => {
  const classes = useStyles(props)
  const { direction, visible, onClick } = props

  return (
    <Button className={classes.root} onClick={onClick} disabled={!visible}>
      <ArrowRightGreyIcon
        className={clsx({ [classes.inverse]: direction === 'left' })}
        alt="flèche de scroll"
      />
    </Button>
  )
}
