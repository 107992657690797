import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'

export const BlocIcons = ({
  imgSrc,
  title,
  className,
}: {
  imgSrc: string
  title?: string
  className: string
}) => {
  return (
    <Box className={className}>
      <img src={imgSrc} alt={title} />
      {title && (
        <Typography variant="h3" style={{ color: '#A1A1A1' }}>
          {title}
        </Typography>
      )}
    </Box>
  )
}
