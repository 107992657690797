import { makeStyles } from '@material-ui/core'
import { ChuTheme } from '../../@types/theme'

export const useAuthFormStyles = makeStyles<ChuTheme>((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  buttonsContainerSpacing: {
    paddingTop: theme.spacing(3),
  },
  loadingIcon: {
    color: theme.palette.primary.dark,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  helperText: {
    color: theme.palette.info.dark,
    paddingTop: 12,
  },
  textLink: {
    color: theme.palette.info.dark,
    textDecoration: 'underline',
    textAlign: 'left',
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
