import { Box, Dialog, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { ChuTheme } from '../../@types/theme'
import { i18n } from '../../assets/i18n'
import { useAuth } from '../../context/auth-context'
import browserHistory from '../../router/history'
import { PreviousButton } from './PreviousButton'
import { SubmitButton } from './SubmitButton'

const useStyles = makeStyles((theme: ChuTheme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '800px',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: 16,
    color: theme.palette.info.dark,
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

/**
 * Modale s'affichant lorsqu'un utilisateur se connecte avec un mot de passe datant de plus de 90 jours
 * Propose de modifier le mot de passe (par sécurité)
 */
export const PasswordUpdateWarningModal = () => {
  const classes = useStyles()
  const s = i18n.auth.passwordUpdateWarningModal
  const {
    signOut,
    passwordUpdateModalOpen,
    displayPasswordUpdateModalAction,
  } = useAuth()

  const handleClose = async () => {
    displayPasswordUpdateModalAction(false)
  }

  const toResetPassword = async () => {
    displayPasswordUpdateModalAction(false)
    signOut()
    // setTimeout car /forgotten-password n'est pas accessible en mode connecté et signOut() génère une redirection vers l'écran de sign in
    setTimeout(() => browserHistory.push('/forgotten-password'), 50)
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="responsive-dialog-title"
      open={passwordUpdateModalOpen}
      onClose={handleClose}
    >
      <Box className={classes.root}>
        {/* TEXT */}
        <Box className={classes.textContainer}>
          <Typography component="h2" className={classes.title}>
            {s.title}
          </Typography>
          <Typography component="p" className={classes.text}>
            {s.text}
          </Typography>
        </Box>

        {/* BUTTONS */}
        <Box className={classes.buttonsContainer}>
          <PreviousButton title={s.ignore} onClick={handleClose} marginRight />
          <SubmitButton
            title={s.toUpdatePassword}
            type="submit"
            onClick={toResetPassword}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
