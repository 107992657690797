import { Api } from './api'

export function saveToken(name: string, token: string) {
  localStorage.setItem(name, token)
}

export function deleteToken(name: string) {
  localStorage.removeItem(name)
}

export async function getProfile() {
  const response = await Api.get<ApiAnyUser>('/users/me')
  if (response.status !== 200) {
    throw new Error('invalid token')
  }
  return response.data
}

export async function login(values: LoginFormValues) {
  try {
    const response = await Api.post<ApiToken>('/auth/login', values)
    saveToken('token', response.data.accessToken)
    if (response.data.refreshToken) {
      saveToken('refresh_token', response.data.refreshToken)
    }
    return response.data
  } catch (error) {
    if (error?.response) {
      throw new LoginError(
        error?.response.data.code,
        error?.response.data.error,
      )
    }
    throw error
  }
}

export async function getOtpCode() {
  try {
    await Api.get<ApiOtpResponse>('/auth/get-otp-code')
  } catch (error) {
    console.error(error)
  }
}
export async function otpValidation(otp: OtpFormValues) {
  try {
    const response = await Api.post<ApiToken>('/auth/otp-validation', otp)
    saveToken('token', response.data.accessToken)
    if (response.data.refreshToken) {
      saveToken('refresh_token', response.data.refreshToken)
    }
    return response.data
  } catch (error) {
    throw error
  }
}
export async function refreshOtpCode() {
  try {
    await Api.get<ApiOtpResponse>('/auth/refresh-otp-code')
  } catch (error) {
    throw error
  }
}

export async function requestForgottenPassword(
  values: ForgottenPasswordRequestFormValues,
) {
  try {
    await Api.post<ForgottenPasswordResponse>(
      '/auth/forgotten-password-request',
      values,
    )
  } catch (error) {
    throw error
  }
}

export async function resetForgottenPassword(
  values: ForgottenPasswordResetFApiValues,
) {
  try {
    await Api.post<ForgottenPasswordResponse>(
      '/auth/forgotten-password-reset',
      values,
    )
  } catch (error) {
    throw error
  }
}

export function logout() {
  deleteToken('token')
  deleteToken('refresh_token')
}

export async function getNewToken() {
  try {
    const refresh = await Api.post('/auth/refresh', {
      refreshToken: localStorage.getItem('refresh_token'),
    })
    saveToken('token', refresh.data.accessToken)
  } catch (error) {
    throw error
  }
}

export class LoginError extends Error {
  constructor(public code: number, message: string) {
    super(message)
  }
}
