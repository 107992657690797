import { settings } from '../settings'

export default {
  root: {
    color: settings.theme.palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
  colorSecondary: {
    color: `${settings.theme.palette.tertiaryColorMain}`,
  },
}
