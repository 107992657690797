import { Box, CircularProgress, Typography } from '@material-ui/core'
import { Field, Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { i18n } from '../../../assets/i18n'
import { useAuth } from '../../../context/auth-context'
import { useAuthFormStyles } from '../../../theme/styles/authFormStyles'
import { AuthFormLayout } from '../../layouts/AuthFormLayout'
import { InputField } from '../../ui/Inputfield'
import { SubmitButton } from '../../ui/SubmitButton'

const schema = Yup.object().shape({
  code: Yup.string()
    .matches(/^[0-9]+$/, i18n.auth.otp.code.format)
    .min(4, i18n.auth.otp.code.length)
    .max(4, i18n.auth.otp.code.length)
    .required(i18n.auth.otp.code.required),
})

/**
 * Formulaire dans lequel l'utilisateur rentre son code OTP afin de s'identifier
 */
export const Otp = () => {
  const auth = useAuth()
  const history = useHistory()
  const classes = useAuthFormStyles()

  const [showError, setShowError] = useState(
    auth.user.state === 'error' ? true : false,
  )
  const [showSuccess, setShowSuccess] = useState(false)

  const onSubmit = async (values: OtpFormValues) => {
    try {
      await auth.validateOtpCode(values)
      history.push('/')
    } catch (error) {
      setShowError(true)
    }
  }

  const refreshOtpCode = async () => {
    try {
      await auth.refreshOtpCode()
      setShowSuccess(true)
    } catch (error) {
      setShowError(true)
    }
  }

  const alertMessages: Array<AlertMessage> = [
    {
      showAlert: showError,
      severity: 'error',
      title: i18n.auth.otp.errors.wrongInputs.title,
      message: i18n.auth.otp.errors.wrongInputs.message,
      onClose: () => setShowError(false),
    },
    {
      showAlert: showSuccess,
      severity: 'success',
      title: i18n.auth.otp.refreshOtpSuccess.title,
      message: i18n.auth.otp.refreshOtpSuccess.message,
      onClose: () => setShowSuccess(false),
    },
  ]

  return (
    <AuthFormLayout
      title={i18n.auth.signin.title}
      alertMessages={alertMessages}
    >
      <Formik<OtpFormValues>
        initialValues={{
          code: '',
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          dirty,
          isSubmitting,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <form className={classes.form} onSubmit={handleSubmit}>
            <Field
              name="code"
              label={i18n.auth.otp.code.label}
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              component={InputField}
            />

            <Typography
              component="p"
              className={classes.textLink}
              onClick={refreshOtpCode}
            >
              {i18n.auth.otp.noOtpCode.label}
            </Typography>

            <Box className={classes.buttonsContainer}>
              <SubmitButton
                title={i18n.auth.signin.button}
                disabled={!dirty || !!errors.code || isSubmitting}
              />

              {auth.user.state === 'loading' && (
                <CircularProgress size={24} className={classes.loadingIcon} />
              )}
            </Box>
          </form>
        )}
      </Formik>
    </AuthFormLayout>
  )
}
